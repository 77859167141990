var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.componentId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            scrollable: "",
          },
          on: { hidden: _vm.cancelModal },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _vm.exists
                    ? [
                        _c(
                          "div",
                          { staticClass: "history-button lock-container" },
                          [
                            _vm.isLockVisible
                              ? [
                                  _c("div", { staticClass: "ml-1 mr-1" }, [
                                    _vm._v(_vm._s(_vm.$t("lock"))),
                                  ]),
                                  _c("b-form-checkbox", {
                                    attrs: {
                                      disabled: _vm.isLockReadOnly,
                                      switch: "",
                                    },
                                    model: {
                                      value: _vm.task.readOnly,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.task, "readOnly", $$v)
                                      },
                                      expression: "task.readOnly",
                                    },
                                  }),
                                ]
                              : _vm._e(),
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "secondary", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    _vm.state.historyShow = true
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "clock-rotate-left"] },
                                }),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("button.history")) + " "
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _vm.canEdit() && _vm.canAdd()
                    ? _c(
                        "b-button",
                        {
                          staticStyle: { "margin-right": "auto" },
                          attrs: {
                            disabled: _vm.task.readOnly,
                            size: "sm",
                            variant: "secondary",
                          },
                          on: { click: _vm.splitTask },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("button.split_task")) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  !_vm.isAccessDenied && (_vm.canEdit() || !_vm.exists)
                    ? [
                        _vm.submittedBy == "ok"
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  size: "sm",
                                  variant: "success",
                                  disabled: "",
                                },
                              },
                              [
                                _c("b-spinner", {
                                  attrs: { small: "", type: "grow" },
                                }),
                                _vm._v(_vm._s(_vm.$t("button.saving")) + " "),
                              ],
                              1
                            )
                          : _c(
                              "b-button",
                              {
                                attrs: {
                                  size: "sm",
                                  variant: "success",
                                  disabled: _vm.disableOk(),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleOkBtnClicked()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("button.ok")))]
                            ),
                      ]
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                  !_vm.isAccessDenied && (_vm.canEdit() || !_vm.exists)
                    ? [
                        _vm.submittedBy == "apply"
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  size: "sm",
                                  variant: "success",
                                  disabled: "",
                                },
                              },
                              [
                                _c("b-spinner", {
                                  attrs: { small: "", type: "grow" },
                                }),
                                _vm._v(_vm._s(_vm.$t("button.saving")) + " "),
                              ],
                              1
                            )
                          : _c(
                              "b-button",
                              {
                                attrs: {
                                  size: "sm",
                                  variant: "success",
                                  disabled: _vm.disableOk(),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleApplyBtnClicked()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("button.apply")))]
                            ),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _vm.isAccessDenied
            ? [
                _c("div", { staticClass: "modal-message-overlay" }, [
                  _c("span", { staticClass: "grid-overlay" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.exists
                            ? _vm.$t(
                                "entity_selector.error.insufficient_permission_to_show_data"
                              )
                            : _vm.restrictedRequiredField != null
                            ? _vm.$t(
                                "entity_selector.error.insufficient_permission_to_add_entity_with_reason",
                                [
                                  _vm.$t("task.title_singular").toLowerCase(),
                                  _vm.restrictedRequiredField,
                                ]
                              )
                            : _vm.$t(
                                "entity_selector.error.insufficient_permission_to_add_entity",
                                [_vm.$t("task.title_singular").toLowerCase()]
                              )
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]
            : [
                _c(
                  "b-alert",
                  {
                    attrs: {
                      variant: _vm.alertError ? "danger" : "success",
                      dismissible: "",
                      show: _vm.showError,
                    },
                    on: { dismissed: _vm.dismissAlert },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: {
                        icon: _vm.alertError
                          ? ["fas", "triangle-exclamation"]
                          : ["far", "check"],
                      },
                    }),
                    _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
                    _c(
                      "ul",
                      {
                        staticClass: "mb-0",
                        attrs: { show: _vm.showErrorDetail },
                      },
                      [
                        _vm._l(_vm.alertMsgDetails, function (item, index) {
                          return [
                            _c("li", { key: index }, [_vm._v(_vm._s(item))]),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "container pl-0" },
                  [
                    _c(
                      "b-row",
                      [
                        _vm.isNameVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", lg: "8" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("task.field.name"),
                                      "label-for": "name",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: { required: true },
                                              expression: "{ required: true }",
                                            },
                                          ],
                                          attrs: {
                                            id: "name",
                                            type: "text",
                                            "data-vv-as":
                                              _vm.$t("task.field.name"),
                                            "data-vv-name": "task.name",
                                            "data-vv-delay": "500",
                                            readonly: _vm.isNameReadOnly,
                                            state:
                                              _vm.fieldValidateUtil.stateValidate(
                                                _vm.isReadOnly,
                                                _vm.veeFields,
                                                _vm.errors,
                                                "location.name"
                                              ),
                                            autofocus: "",
                                            trim: "",
                                          },
                                          model: {
                                            value: _vm.task.name,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.task, "name", $$v)
                                            },
                                            expression: "task.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: { "d-block": _vm.showNameError },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first("task.name")
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["name"] != null
                          ? _vm._l(
                              _vm.customFieldMap["name"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "name" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isIdentifierVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("field.identifier"),
                                      "label-for": "identifier",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "identifier",
                                            type: "text",
                                            "data-vv-as":
                                              _vm.$t("field.identifier"),
                                            "data-vv-name": "task.identifier",
                                            maxlength: _vm.maxIdentifierLength,
                                            readonly: _vm.isIdentifierReadOnly,
                                            trim: "",
                                          },
                                          model: {
                                            value: _vm.task.identifier,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                "identifier",
                                                $$v
                                              )
                                            },
                                            expression: "task.identifier",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["identifier"] != null
                          ? _vm._l(
                              _vm.customFieldMap["identifier"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "identifier" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.customFieldMap["default"] != null
                          ? _vm._l(
                              _vm.customFieldMap["default"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isParentVisible
                          ? [
                              _c(
                                "b-col",
                                {
                                  staticClass: "pr-0",
                                  attrs: { cols: "12", sm: "6" },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.isTemplate
                                          ? _vm.$t("task.field.taskTemplate")
                                          : _vm.$t("task.field.project"),
                                        "label-for": "task-project",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "task-project",
                                              type: "text",
                                              disabled: "",
                                            },
                                            model: {
                                              value: _vm.project.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.project,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "project.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.customFieldMap["projects"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["projects"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "projects" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.task[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.task,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "task[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _c(
                                "b-col",
                                {
                                  staticClass: "pr-0",
                                  attrs: { cols: "12", sm: "6" },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.$t("task.field.parent"),
                                        "label-for": "task-parent",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("Treeselect", {
                                            attrs: {
                                              "instance-id": "task-parent",
                                              "default-expand-level": Infinity,
                                              multiple: false,
                                              options: _vm.optionTask,
                                              "data-vv-as":
                                                _vm.$t("task.field.parent"),
                                              "data-vv-name": "task.parent",
                                              clearable: false,
                                              "data-vv-delay": "500",
                                              disabled: _vm.isParentReadOnly,
                                            },
                                            model: {
                                              value: _vm.task.parent,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.task,
                                                  "parent",
                                                  $$v
                                                )
                                              },
                                              expression: "task.parent",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-form-invalid-feedback",
                                        {
                                          staticClass:
                                            "alert-danger form-field-alert",
                                          class: {
                                            "d-block": _vm.showParentError,
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: {
                                              icon: [
                                                "far",
                                                "circle-exclamation",
                                              ],
                                            },
                                          }),
                                          _vm._v(
                                            "  " +
                                              _vm._s(
                                                _vm.errors.first("task.parent")
                                              ) +
                                              " "
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.customFieldMap["parentTask"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["parentTask"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "parentTask" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.task[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.task,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "task[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                            ]
                          : [
                              _vm.customFieldMap["projects"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["projects"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "projects" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.task[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.task,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "task[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["parentTask"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["parentTask"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "parentTask" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.task[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.task,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "task[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                            ],
                        _vm.isTaskTypeVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", xl: "3" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "field-type",
                                      label: _vm.$t("task.field.taskType"),
                                      "label-for": "task-type",
                                    },
                                  },
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        id: "task-type",
                                        options: _vm.optionType,
                                        "text-field": "label",
                                        "value-field": "key",
                                        disabled: _vm.isTaskTypeReadOnly,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.typeChanged()
                                        },
                                      },
                                      model: {
                                        value: _vm.task.taskType,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.task, "taskType", $$v)
                                        },
                                        expression: "task.taskType",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["type"] != null
                          ? _vm._l(
                              _vm.customFieldMap["type"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "type" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isPriorityVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", xl: "3" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "field-priority",
                                      label: _vm.$t("task.field.priority"),
                                      "label-for": "task-priority",
                                    },
                                  },
                                  [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.task.priority,
                                            expression: "task.priority",
                                          },
                                        ],
                                        staticClass: "custom-select",
                                        attrs: {
                                          id: "task-priority",
                                          disabled: _vm.isPriorityReadOnly,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.task,
                                              "priority",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._l(
                                          _vm.optionPriority,
                                          function (opt, index) {
                                            return [
                                              _c(
                                                "option",
                                                {
                                                  key: index,
                                                  style: {
                                                    display:
                                                      opt.num < 0
                                                        ? "none"
                                                        : "block",
                                                  },
                                                  domProps: {
                                                    value: opt.value,
                                                  },
                                                },
                                                [_vm._v(_vm._s(opt.text))]
                                              ),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["priority"] != null
                          ? _vm._l(
                              _vm.customFieldMap["priority"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "priority" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isComplexityVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", xl: "3" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("task.field.complexity"),
                                      "label-for": "task-complexity",
                                    },
                                  },
                                  [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.task.complexity,
                                            expression: "task.complexity",
                                          },
                                        ],
                                        staticClass: "custom-select",
                                        attrs: {
                                          id: "task-complexity",
                                          disabled: _vm.isComplexityReadOnly,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.task,
                                              "complexity",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._l(
                                          _vm.optionComplexity,
                                          function (opt, index) {
                                            return [
                                              _c(
                                                "option",
                                                {
                                                  key: index,
                                                  style: {
                                                    display:
                                                      opt.num < 0
                                                        ? "none"
                                                        : "block",
                                                  },
                                                  domProps: {
                                                    value: opt.value,
                                                  },
                                                },
                                                [_vm._v(_vm._s(opt.text))]
                                              ),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["complexity"] != null
                          ? _vm._l(
                              _vm.customFieldMap["complexity"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "complexity" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isColorVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", xl: "3" },
                              },
                              [
                                _c("Color", {
                                  attrs: {
                                    update: _vm.updatedColor,
                                    disabled: _vm.isColorReadOnly,
                                  },
                                  model: {
                                    value: _vm.task.color,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.task, "color", $$v)
                                    },
                                    expression: "task.color",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["color"] != null
                          ? _vm._l(
                              _vm.customFieldMap["color"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "color" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isDescriptionVisible
                          ? _c(
                              "b-col",
                              { attrs: { cols: "12 pr-0" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("task.field.description"),
                                      "label-for": "task-description",
                                    },
                                  },
                                  [
                                    _c("b-form-textarea", {
                                      attrs: {
                                        id: "task-description",
                                        placeholder: _vm.isDescriptionReadOnly
                                          ? ""
                                          : _vm.$t(
                                              "task.placeholder.description"
                                            ),
                                        "data-vv-as": _vm.$t(
                                          "task.field.description"
                                        ),
                                        "data-vv-name": "task.description",
                                        "data-vv-delay": "500",
                                        "max-rows": 6,
                                        readonly: _vm.isDescriptionReadOnly,
                                        trim: "",
                                        rows: 3,
                                      },
                                      model: {
                                        value: _vm.task.description,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.task, "description", $$v)
                                        },
                                        expression: "task.description",
                                      },
                                    }),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: {
                                          "d-block": _vm.showDescriptionError,
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first(
                                                "task.description"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["description"] != null
                          ? _vm._l(
                              _vm.customFieldMap["description"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "description" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isStartTimeVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", xl: "6" },
                              },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "mb-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "task.field.startTime"
                                              ),
                                              "label-for": "task-startDate",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-form-datepicker",
                                              _vm._b(
                                                {
                                                  staticClass: "mb-2",
                                                  attrs: {
                                                    id: "task-startDate",
                                                    "today-button": "",
                                                    "reset-button": "",
                                                    "close-button": "",
                                                    "hide-header": "",
                                                    "label-today-button":
                                                      _vm.$t("date.today"),
                                                    "label-reset-button":
                                                      _vm.$t("date.reset"),
                                                    "label-close-button":
                                                      _vm.$t("date.close"),
                                                    "label-no-date-selected":
                                                      _vm.$t("date.none"),
                                                    "today-button-variant":
                                                      "primary",
                                                    "reset-button-variant":
                                                      "danger",
                                                    "close-button-variant":
                                                      "secondary",
                                                    readonly:
                                                      _vm.isStartTimeReadOnly,
                                                  },
                                                  on: _vm._d({}, [
                                                    _vm.dateTimeCalcInputTrigger,
                                                    function ($event) {
                                                      return _vm.dateTimeInputHandler(
                                                        $event,
                                                        "startDate"
                                                      )
                                                    },
                                                  ]),
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "button-content",
                                                        fn: function (ref) {
                                                          return [
                                                            _c(
                                                              "font-awesome-icon",
                                                              {
                                                                attrs: {
                                                                  icon: [
                                                                    "far",
                                                                    "calendar-days",
                                                                  ],
                                                                },
                                                              }
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2621928167
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.duration.startDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.duration,
                                                        "startDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "duration.startDate",
                                                  },
                                                },
                                                "b-form-datepicker",
                                                Object.assign(
                                                  {},
                                                  _vm.duration.closeDate !=
                                                    null && {
                                                    initialDate:
                                                      _vm.duration.closeDate,
                                                  }
                                                ),
                                                false
                                              )
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("Timepicker", {
                                          attrs: {
                                            disableTime:
                                              _vm.isStartTimeReadOnly,
                                          },
                                          on: _vm._d({}, [
                                            _vm.dateTimeCalcInputTrigger,
                                            function ($event) {
                                              return _vm.dateTimeInputHandler(
                                                $event,
                                                "startTime"
                                              )
                                            },
                                          ]),
                                          model: {
                                            value: _vm.duration.startTime,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.duration,
                                                "startTime",
                                                $$v
                                              )
                                            },
                                            expression: "duration.startTime",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["startTime"] != null
                          ? _vm._l(
                              _vm.customFieldMap["startTime"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "startTime" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isCloseTimeVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", xl: "6" },
                              },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "mb-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "task.field.closeTime"
                                              ),
                                              "label-for": "task-closeDate",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-form-datepicker",
                                              _vm._b(
                                                {
                                                  staticClass: "mb-2",
                                                  attrs: {
                                                    id: "task-closeDate",
                                                    "today-button": "",
                                                    "reset-button": "",
                                                    "close-button": "",
                                                    "hide-header": "",
                                                    "label-today-button":
                                                      _vm.$t("date.today"),
                                                    "label-reset-button":
                                                      _vm.$t("date.reset"),
                                                    "label-close-button":
                                                      _vm.$t("date.close"),
                                                    "label-no-date-selected":
                                                      _vm.$t("date.none"),
                                                    "today-button-variant":
                                                      "primary",
                                                    "reset-button-variant":
                                                      "danger",
                                                    "close-button-variant":
                                                      "secondary",
                                                    readonly:
                                                      _vm.isCloseTimeReadOnly,
                                                  },
                                                  on: _vm._d({}, [
                                                    _vm.dateTimeCalcInputTrigger,
                                                    function ($event) {
                                                      return _vm.dateTimeInputHandler(
                                                        $event,
                                                        "closeDate"
                                                      )
                                                    },
                                                  ]),
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "button-content",
                                                        fn: function (ref) {
                                                          return [
                                                            _c(
                                                              "font-awesome-icon",
                                                              {
                                                                attrs: {
                                                                  icon: [
                                                                    "far",
                                                                    "calendar-days",
                                                                  ],
                                                                },
                                                              }
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2621928167
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.duration.closeDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.duration,
                                                        "closeDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "duration.closeDate",
                                                  },
                                                },
                                                "b-form-datepicker",
                                                Object.assign(
                                                  {},
                                                  _vm.duration.startDate !=
                                                    null && {
                                                    initialDate:
                                                      _vm.duration.startDate,
                                                  }
                                                ),
                                                false
                                              )
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("Timepicker", {
                                          attrs: {
                                            disableTime:
                                              _vm.isCloseTimeReadOnly,
                                          },
                                          on: _vm._d({}, [
                                            _vm.dateTimeCalcInputTrigger,
                                            function ($event) {
                                              return _vm.dateTimeInputHandler(
                                                $event,
                                                "closeTime"
                                              )
                                            },
                                          ]),
                                          model: {
                                            value: _vm.duration.closeTime,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.duration,
                                                "closeTime",
                                                $$v
                                              )
                                            },
                                            expression: "duration.closeTime",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["closeTime"] != null
                          ? _vm._l(
                              _vm.customFieldMap["closeTime"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "closeTime" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isFixedDurationVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("task.field.fixedDuration"),
                                      "label-for": "task-fixed-duration",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        !_vm.isFixedDurationReadOnly
                                          ? _c(
                                              "b-input-group-prepend",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      id: "FIXED_DURATION_SUBTRACT",
                                                      disabled:
                                                        _vm.disableFixedDuration,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.fixedDurationAddMinus(
                                                          -1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: ["far", "minus"],
                                                      },
                                                    }),
                                                    _c("b-popover", {
                                                      attrs: {
                                                        target:
                                                          "FIXED_DURATION_SUBTRACT",
                                                        placement: "top",
                                                        triggers: "hover",
                                                        content: _vm.$t(
                                                          "task.button.duration_subtract"
                                                        ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c("b-form-input", {
                                          class: _vm.fixedDurationClass,
                                          attrs: {
                                            id: "task-fixed-duration",
                                            type: "text",
                                            disabled: _vm.disableFixedDuration,
                                            "data-vv-as": _vm.$t(
                                              "task.field.fixedDuration"
                                            ),
                                            "data-vv-name":
                                              "task.fixedDuration",
                                            "data-vv-delay": "500",
                                            readonly:
                                              _vm.isFixedDurationReadOnly,
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              return _vm.onFixedDurationKeyDown(
                                                $event
                                              )
                                            },
                                            keyup: function ($event) {
                                              return _vm.onFixedDurationKeyUp(
                                                $event
                                              )
                                            },
                                            change: _vm.onFixedDurationChange,
                                          },
                                          model: {
                                            value: _vm.task.fixedDuration,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                "fixedDuration",
                                                $$v
                                              )
                                            },
                                            expression: "task.fixedDuration",
                                          },
                                        }),
                                        !_vm.isFixedDurationReadOnly
                                          ? _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      id: "FIXED_DURATION_ADD",
                                                      disabled:
                                                        _vm.disableFixedDuration,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.fixedDurationAddMinus(
                                                          1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: ["far", "plus"],
                                                      },
                                                    }),
                                                    _c("b-popover", {
                                                      attrs: {
                                                        target:
                                                          "FIXED_DURATION_ADD",
                                                        placement: "top",
                                                        triggers: "hover",
                                                        content: _vm.$t(
                                                          "task.button.duration_add"
                                                        ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.fixedDurationAlert
                                          ? _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "fixed-duration-alert",
                                                    attrs: {
                                                      id: "fixed-duration-alert",
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: [
                                                          "far",
                                                          "circle-exclamation",
                                                        ],
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        "fixed-duration-alert",
                                                      triggers: "hover",
                                                      placement: "topleft",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.fixedDurationAlertMsg
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: {
                                          "d-block": _vm.showFixedDurationError,
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first("fixedDuration")
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["fixedDuration"] != null
                          ? _vm._l(
                              _vm.customFieldMap["fixedDuration"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "fixedDuration" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isDurationVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("task.field.duration"),
                                      "label-for": "task-duration",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        !_vm.isDurationReadOnly
                                          ? _c(
                                              "b-input-group-prepend",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      id: "DURATION_SUBTRACT",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.durationAddMinus(
                                                          -1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: ["far", "minus"],
                                                      },
                                                    }),
                                                    _c("b-popover", {
                                                      attrs: {
                                                        target:
                                                          "DURATION_SUBTRACT",
                                                        placement: "top",
                                                        triggers: "hover",
                                                        content: _vm.$t(
                                                          "task.button.duration_subtract"
                                                        ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "task-duration",
                                            type: "text",
                                            disabled: _vm.disableDuration,
                                            "data-vv-as": _vm.$t(
                                              "task.field.duration"
                                            ),
                                            "data-vv-name": "duration.value",
                                            "data-vv-delay": "500",
                                            readonly: _vm.isDurationReadOnly,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.durationValueChanged(
                                                $event
                                              )
                                            },
                                            keydown: function ($event) {
                                              return _vm.onDurationKeyDown(
                                                $event
                                              )
                                            },
                                            keyup: function ($event) {
                                              return _vm.onDurationKeyUp($event)
                                            },
                                          },
                                          model: {
                                            value: _vm.duration.value,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.duration,
                                                "value",
                                                $$v
                                              )
                                            },
                                            expression: "duration.value",
                                          },
                                        }),
                                        !_vm.isDurationReadOnly
                                          ? _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      id: "DURATION_ADD",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.durationAddMinus(
                                                          1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: ["far", "plus"],
                                                      },
                                                    }),
                                                    _c("b-popover", {
                                                      attrs: {
                                                        target: "DURATION_ADD",
                                                        placement: "top",
                                                        triggers: "hover",
                                                        content: _vm.$t(
                                                          "task.button.duration_add"
                                                        ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.isDurationVisible
                                          ? _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    attrs: {
                                                      id: "lockDurationButton",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            _vm.isDurationReadOnly,
                                                          variant: _vm.task
                                                            .lockDuration
                                                            ? "success"
                                                            : "secondary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.toggleLockDuration()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            attrs: {
                                                              icon: _vm.task
                                                                .lockDuration
                                                                ? [
                                                                    "far",
                                                                    "lock",
                                                                  ]
                                                                : [
                                                                    "far",
                                                                    "unlock",
                                                                  ],
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        "lockDurationButton",
                                                      triggers: "hover",
                                                      placement: "topleft",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "task.popover.lockDuration"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: {
                                          "d-block": _vm.showDurationError,
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first("duration.value")
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["estimatedDuration"] != null
                          ? _vm._l(
                              _vm.customFieldMap["estimatedDuration"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "estimatedDuration" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isActualDurationVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0 mb-3 mb-md-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("label", { staticClass: "mr-1" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("task.field.actualDuration")
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "b-input-group",
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "actual-duration-form-control-fix",
                                            attrs: {
                                              id: "taskActualDurationDiv",
                                              disabled: !_vm.disableWorkEffort,
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              class: _vm.workEffortClass,
                                              attrs: {
                                                id: "task-actual-duration",
                                                type: "text",
                                                disabled:
                                                  _vm.isActualDurationReadOnly ||
                                                  _vm.disableWorkEffort,
                                                readonly: "",
                                              },
                                              on: {
                                                click:
                                                  _vm.staffWorkEffortToggle,
                                              },
                                              model: {
                                                value: _vm.totalWorkEffort,
                                                callback: function ($$v) {
                                                  _vm.totalWorkEffort = $$v
                                                },
                                                expression: "totalWorkEffort",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        !_vm.isActualDurationReadOnly
                                          ? _c(
                                              "b-popover",
                                              {
                                                attrs: {
                                                  target:
                                                    "taskActualDurationDiv",
                                                  triggers: "hover",
                                                  placement: "top",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "task.alert.cannot_edit_work_effort"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.workEffortAlert
                                          ? _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "work-effort-alert",
                                                    attrs: {
                                                      id: "work-effort-alert",
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: [
                                                          "far",
                                                          "circle-exclamation",
                                                        ],
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        "work-effort-alert",
                                                      triggers: "hover",
                                                      placement: "topleft",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.workEffortAlertMsg
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["actualDuration"] != null
                          ? _vm._l(
                              _vm.customFieldMap["actualDuration"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "actualDuration" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isETCVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", sm: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "field-progress",
                                      label: _vm.$t("task.field.etoc"),
                                      "label-for": "task-etoc",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        !_vm.isETCReadOnly
                                          ? _c(
                                              "b-input-group-prepend",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      id: "ETC_SUBTRACT",
                                                      disabled:
                                                        _vm.isETCReadOnly,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.etocAddMinus(
                                                          -1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: ["far", "minus"],
                                                      },
                                                    }),
                                                    _c("b-popover", {
                                                      attrs: {
                                                        target: "ETC_SUBTRACT",
                                                        placement: "top",
                                                        triggers: "hover",
                                                        content: _vm.$t(
                                                          "task.button.etc_subtract"
                                                        ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "task-etoc",
                                            type: "text",
                                            readonly: _vm.isETCReadOnly,
                                          },
                                          on: {
                                            change: _vm.onEtocInput,
                                            keydown: function ($event) {
                                              return _vm.onEtocKeyDown($event)
                                            },
                                            keyup: function ($event) {
                                              return _vm.onEtocKeyUp($event)
                                            },
                                          },
                                          model: {
                                            value: _vm.etocInput,
                                            callback: function ($$v) {
                                              _vm.etocInput = $$v
                                            },
                                            expression: "etocInput",
                                          },
                                        }),
                                        !_vm.isETCReadOnly
                                          ? _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      id: "ETC_ADD",
                                                      disabled:
                                                        _vm.disableEtc ||
                                                        _vm.isReadOnly,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.etocAddMinus(
                                                          1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: ["far", "plus"],
                                                      },
                                                    }),
                                                    _c("b-popover", {
                                                      attrs: {
                                                        target: "ETC_ADD",
                                                        placement: "top",
                                                        triggers: "hover",
                                                        content: _vm.$t(
                                                          "task.button.etc_add"
                                                        ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["etc"] != null
                          ? _vm._l(
                              _vm.customFieldMap["etc"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "etc" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isProgressVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", sm: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "field-progress",
                                      label: _vm.$t("task.field.progress"),
                                      "label-for": "task-progress",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        !_vm.isProgressReadOnly
                                          ? _c(
                                              "b-input-group-prepend",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      id: "PROGRESS_SUBTRACT",
                                                      disabled:
                                                        _vm.isProgressReadOnly,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.progressAddMinus(
                                                          -1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: ["far", "minus"],
                                                      },
                                                    }),
                                                    _c("b-popover", {
                                                      attrs: {
                                                        target:
                                                          "PROGRESS_SUBTRACT",
                                                        placement: "top",
                                                        triggers: "hover",
                                                        content: _vm.$t(
                                                          "task.button.progress_subtract"
                                                        ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "task-progress",
                                            type: "text",
                                            readonly: _vm.isProgressReadOnly,
                                          },
                                          on: { blur: _vm.progressFormat },
                                          model: {
                                            value: _vm.task.progress,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                "progress",
                                                $$v
                                              )
                                            },
                                            expression: "task.progress",
                                          },
                                        }),
                                        !_vm.isProgressReadOnly
                                          ? [
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        id: "PROGRESS_ADD",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.progressAddMinus(
                                                            1
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        attrs: {
                                                          icon: ["far", "plus"],
                                                        },
                                                      }),
                                                      _c("b-popover", {
                                                        attrs: {
                                                          target:
                                                            "PROGRESS_ADD",
                                                          placement: "top",
                                                          triggers: "hover",
                                                          content: _vm.$t(
                                                            "task.button.progress_add"
                                                          ),
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      attrs: {
                                                        id: "BTN_PROGRESS_COMPLETE",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          attrs: {
                                                            variant:
                                                              _vm.task
                                                                .progress == 100
                                                                ? "success"
                                                                : "secondary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.progressComplete()
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "check",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-popover",
                                                    {
                                                      attrs: {
                                                        target:
                                                          "BTN_PROGRESS_COMPLETE",
                                                        triggers: "hover",
                                                        placement: "topleft",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "task.popover.progressComplete"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["progress"] != null
                          ? _vm._l(
                              _vm.customFieldMap["progress"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "progress" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isAutoSchedulingVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", sm: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "task.field.autoScheduling"
                                      ),
                                      "label-for": "task-autoschedule",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-select",
                                      {
                                        attrs: {
                                          id: "task-autoschedule",
                                          readonly:
                                            _vm.isAutoSchedulingReadOnly,
                                          disabled:
                                            _vm.isAutoSchedulingReadOnly,
                                        },
                                        on: _vm._d({}, [
                                          _vm.dateTimeCalcChangeTrigger,
                                          function ($event) {
                                            return _vm.dateTimeInputHandler(
                                              $event,
                                              "taskScheduleMode"
                                            )
                                          },
                                        ]),
                                        model: {
                                          value: _vm.task.autoScheduling,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.task,
                                              "autoScheduling",
                                              $$v
                                            )
                                          },
                                          expression: "task.autoScheduling",
                                        },
                                      },
                                      [
                                        [
                                          _c(
                                            "option",
                                            { domProps: { value: true } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "task.autoschedule.auto"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "option",
                                            { domProps: { value: false } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "task.autoschedule.manual"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["autoScheduling"] != null
                          ? _vm._l(
                              _vm.customFieldMap["autoScheduling"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "autoScheduling" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isStageVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", sm: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "field-stage",
                                      label: _vm.$t("task.field.stage"),
                                      "label-for": "task-stage",
                                    },
                                  },
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        id: "task-stage",
                                        options: _vm.optionStage,
                                        disabled: _vm.isStageReadOnly,
                                        "text-field": "label",
                                        "value-field": "key",
                                      },
                                      model: {
                                        value: _vm.stage,
                                        callback: function ($$v) {
                                          _vm.stage = $$v
                                        },
                                        expression: "stage",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["stage"] != null
                          ? _vm._l(
                              _vm.customFieldMap["stage"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "stage" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isConstraintVisible
                          ? _c(
                              "b-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "task-constraint",
                                      label: _vm.$t("task.field.constraint"),
                                      "label-for": "task-constraint_type",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          {
                                            staticClass: "mb-2 mb-xl-0 pr-0",
                                            attrs: { cols: "12", xl: "6" },
                                          },
                                          [
                                            _c("b-form-select", {
                                              attrs: {
                                                id: "task-constraint_type",
                                                disabled:
                                                  _vm.isConstraintReadOnly,
                                                options: _vm.optionConstraint,
                                                "value-field": "key",
                                                "text-field": "label",
                                              },
                                              on: _vm._d({}, [
                                                _vm.dateTimeCalcChangeTrigger,
                                                function ($event) {
                                                  return _vm.dateTimeInputHandler(
                                                    $event,
                                                    "constraintType"
                                                  )
                                                },
                                              ]),
                                              model: {
                                                value: _vm.constraint.type,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.constraint,
                                                    "type",
                                                    $$v
                                                  )
                                                },
                                                expression: "constraint.type",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          {
                                            staticClass: "pr-0",
                                            attrs: { cols: "12", xl: "6" },
                                          },
                                          [
                                            _c("b-form-datepicker", {
                                              attrs: {
                                                id: "task-constraintDate",
                                                disabled:
                                                  _vm.disableConstraintDate ||
                                                  _vm.isConstraintReadOnly,
                                                "today-button": "",
                                                "reset-button": "",
                                                "close-button": "",
                                                "hide-header": "",
                                                "label-today-button":
                                                  _vm.$t("date.today"),
                                                "label-reset-button":
                                                  _vm.$t("date.reset"),
                                                "label-close-button":
                                                  _vm.$t("date.close"),
                                                "label-no-date-selected":
                                                  _vm.$t("date.none"),
                                                "today-button-variant":
                                                  "primary",
                                                "reset-button-variant":
                                                  "danger",
                                                "close-button-variant":
                                                  "secondary",
                                              },
                                              on: _vm._d({}, [
                                                _vm.dateTimeCalcInputTrigger,
                                                function ($event) {
                                                  return _vm.dateTimeInputHandler(
                                                    $event,
                                                    "constraintDate"
                                                  )
                                                },
                                              ]),
                                              model: {
                                                value: _vm.constraint.date,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.constraint,
                                                    "date",
                                                    $$v
                                                  )
                                                },
                                                expression: "constraint.date",
                                              },
                                            }),
                                            _c(
                                              "b-form-invalid-feedback",
                                              {
                                                staticClass:
                                                  "alert-danger form-field-alert",
                                                class: {
                                                  "d-block":
                                                    _vm.showConstraintDateError,
                                                },
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  attrs: {
                                                    icon: [
                                                      "far",
                                                      "circle-exclamation",
                                                    ],
                                                  },
                                                }),
                                                _vm._v(
                                                  "  " +
                                                    _vm._s(
                                                      _vm.errors.first(
                                                        "constraint.date"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["constraint"] != null
                          ? _vm._l(
                              _vm.customFieldMap["constraint"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "constraint" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isSkillVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("label", { staticClass: "mr-1" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("task.field.skills"))
                                      ),
                                    ]),
                                    !_vm.isSkillReadOnly
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn-action",
                                            attrs: {
                                              id: "SKILL_ADD",
                                              disabled: _vm.isSkillReadOnly,
                                            },
                                            on: {
                                              click: _vm.skillSelectorToggle,
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: ["far", "plus"] },
                                            }),
                                            _c("b-popover", {
                                              attrs: {
                                                target: "SKILL_ADD",
                                                placement: "top",
                                                triggers: "hover",
                                                content: _vm.$t(
                                                  "task.button.skill_add"
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("BadgeGroup", {
                                      attrs: { readOnly: _vm.isSkillReadOnly },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var item = ref.item
                                              var index = ref.index
                                              return [
                                                _c("Badge", {
                                                  key: index,
                                                  attrs: {
                                                    text: item.level
                                                      ? item.name +
                                                        " (" +
                                                        item.level +
                                                        ")"
                                                      : item.name,
                                                    variant: "primary",
                                                    pillable: !!item.pillable,
                                                    readOnly:
                                                      _vm.isSkillReadOnly,
                                                  },
                                                  on: {
                                                    badgeRemove: function (
                                                      $event
                                                    ) {
                                                      return _vm.skillBadgeRemove(
                                                        index
                                                      )
                                                    },
                                                    badgeClick: function (
                                                      $event
                                                    ) {
                                                      return _vm.skillBadgeClick(
                                                        item.uuId
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3152026025
                                      ),
                                      model: {
                                        value: _vm.skills,
                                        callback: function ($$v) {
                                          _vm.skills = $$v
                                        },
                                        expression: "skills",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["skills"] != null
                          ? _vm._l(
                              _vm.customFieldMap["skills"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "skills" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isStaffVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("label", { staticClass: "mr-1" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("task.field.staffs"))
                                      ),
                                    ]),
                                    !_vm.isStaffReadOnly
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn-action",
                                            attrs: {
                                              id: "STAFF_ADD",
                                              disabled: _vm.isStaffReadOnly,
                                            },
                                            on: {
                                              click: _vm.preStaffSelectorToggle,
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: ["far", "plus"] },
                                            }),
                                            _c("b-popover", {
                                              attrs: {
                                                target: "STAFF_ADD",
                                                placement: "top",
                                                triggers: "hover",
                                                content: _vm.$t(
                                                  "task.button.staff_add"
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("BadgeGroup", {
                                      attrs: { readOnly: _vm.isStaffReadOnly },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var item = ref.item
                                              var index = ref.index
                                              return [
                                                _c("Badge", {
                                                  key: index,
                                                  attrs: {
                                                    text:
                                                      item.name +
                                                      " (" +
                                                      (item.quantity > 1
                                                        ? item.quantity + " x "
                                                        : "") +
                                                      (item.utilization
                                                        ? Math.trunc(
                                                            item.utilization *
                                                              100
                                                          )
                                                        : 100) +
                                                      "%)",
                                                    variant: item.genericStaff
                                                      ? "info"
                                                      : _vm.staffUsage[
                                                          item.name
                                                        ]
                                                      ? "warning"
                                                      : "primary",
                                                    errorMessage:
                                                      _vm.staffUsage[
                                                        item.name
                                                      ] || null,
                                                    pillable: !!item.pillable,
                                                    readOnly:
                                                      _vm.isStaffReadOnly,
                                                  },
                                                  on: {
                                                    badgeRemove: function (
                                                      $event
                                                    ) {
                                                      return _vm.staffBadgeRemove(
                                                        index
                                                      )
                                                    },
                                                    badgeClick: function (
                                                      $event
                                                    ) {
                                                      return _vm.staffBadgeClick(
                                                        item.uuId
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        819429473
                                      ),
                                      model: {
                                        value: _vm.staffs,
                                        callback: function ($$v) {
                                          _vm.staffs = $$v
                                        },
                                        expression: "staffs",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["staffs"] != null
                          ? _vm._l(
                              _vm.customFieldMap["staffs"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "staffs" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isResourceVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("label", { staticClass: "mr-1" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("task.field.resources"))
                                      ),
                                    ]),
                                    !_vm.isResourceReadOnly
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn-action",
                                            attrs: {
                                              id: "RESOURCE_ADD",
                                              disabled: _vm.isResourceReadOnly,
                                            },
                                            on: {
                                              click: _vm.resourceSelectorToggle,
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: ["far", "plus"] },
                                            }),
                                            _c("b-popover", {
                                              attrs: {
                                                target: "RESOURCE_ADD",
                                                placement: "top",
                                                triggers: "hover",
                                                content: _vm.$t(
                                                  "task.button.resource_add"
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("BadgeGroup", {
                                      attrs: {
                                        readOnly: _vm.isResourceReadOnly,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var item = ref.item
                                              var index = ref.index
                                              return [
                                                _c("Badge", {
                                                  key: index,
                                                  attrs: {
                                                    text:
                                                      item.name +
                                                      " (" +
                                                      (item.quantity > 1
                                                        ? item.quantity + " x "
                                                        : "") +
                                                      (item.utilization
                                                        ? item.utilization * 100
                                                        : 100) +
                                                      "%)",
                                                    variant: "primary",
                                                    pillable: !!item.pillable,
                                                    readOnly:
                                                      _vm.isResourceReadOnly,
                                                  },
                                                  on: {
                                                    badgeRemove: function (
                                                      $event
                                                    ) {
                                                      return _vm.resourceBadgeRemove(
                                                        index
                                                      )
                                                    },
                                                    badgeClick: function (
                                                      $event
                                                    ) {
                                                      return _vm.resourceBadgeClick(
                                                        item.uuId
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1272292794
                                      ),
                                      model: {
                                        value: _vm.resources,
                                        callback: function ($$v) {
                                          _vm.resources = $$v
                                        },
                                        expression: "resources",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["resources"] != null
                          ? _vm._l(
                              _vm.customFieldMap["resources"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "resources" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isFixedCostNetVisible || _vm.isFixedCostVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c(
                                  "b-form-select",
                                  {
                                    staticClass: "title-dropdown mb-1",
                                    model: {
                                      value: _vm.fixedCostMode,
                                      callback: function ($$v) {
                                        _vm.fixedCostMode = $$v
                                      },
                                      expression: "fixedCostMode",
                                    },
                                  },
                                  [
                                    _vm.isFixedCostVisible
                                      ? _c(
                                          "option",
                                          { attrs: { value: "gross" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("task.field.fixedCost")
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.isFixedCostNetVisible
                                      ? _c(
                                          "option",
                                          { attrs: { value: "net" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "task.field.fixedCostNet"
                                                )
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _vm.fixedCostMode === "gross"
                                          ? _c("b-form-input", {
                                              attrs: {
                                                id: "cost",
                                                type: "number",
                                                min: 0.0,
                                                step: 1,
                                                "lazy-formatter": "",
                                                formatter: _vm.floatFormatter(
                                                  null,
                                                  0.0
                                                ),
                                                "data-vv-as": _vm.$t(
                                                  "task.field.fixedCost"
                                                ),
                                                "data-vv-name":
                                                  "task.fixedCost",
                                                "data-vv-delay": "500",
                                                readonly:
                                                  _vm.isFixedCostReadOnly,
                                              },
                                              model: {
                                                value: _vm.task.fixedCost,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.task,
                                                    "fixedCost",
                                                    $$v
                                                  )
                                                },
                                                expression: "task.fixedCost",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.fixedCostMode === "net"
                                          ? _c("b-form-input", {
                                              attrs: {
                                                id: "costNet",
                                                type: "number",
                                                min: 0.0,
                                                step: 1,
                                                "lazy-formatter": "",
                                                formatter: _vm.floatFormatter(
                                                  null,
                                                  0.0
                                                ),
                                                "data-vv-as": _vm.$t(
                                                  "task.field.fixedCost"
                                                ),
                                                "data-vv-name":
                                                  "task.fixedCost",
                                                "data-vv-delay": "500",
                                                readonly: "",
                                              },
                                              model: {
                                                value: _vm.task.fixedCostNet,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.task,
                                                    "fixedCostNet",
                                                    $$v
                                                  )
                                                },
                                                expression: "task.fixedCostNet",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["fixedCost"] != null
                          ? _vm._l(
                              _vm.customFieldMap["fixedCost"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "fixedCost" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isCurrencyCodeVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("task.field.currencyCode"),
                                    },
                                  },
                                  [
                                    _c("b-input-group", [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.task.currencyCode,
                                              expression: "task.currencyCode",
                                            },
                                          ],
                                          staticClass: "custom-select",
                                          attrs: {
                                            disabled:
                                              _vm.isCurrencyCodeReadOnly,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.task,
                                                "currencyCode",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._l(
                                            _vm.optionCurrency,
                                            function (opt, index) {
                                              return [
                                                _c(
                                                  "option",
                                                  {
                                                    key: index,
                                                    style: {
                                                      display:
                                                        opt.num < 0
                                                          ? "none"
                                                          : "block",
                                                    },
                                                    domProps: {
                                                      value: opt.value,
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(opt.text))]
                                                ),
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ]),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: {
                                          "d-block": _vm.showCurrencyError,
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first(
                                                "task.currencyCode"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["currencyCode"] != null
                          ? _vm._l(
                              _vm.customFieldMap["currencyCode"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "currencyCode" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isEstimatedCostVisible ||
                        _vm.isEstimatedCostNetVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c(
                                  "b-form-select",
                                  {
                                    staticClass: "title-dropdown mb-1",
                                    model: {
                                      value: _vm.estimatedCostMode,
                                      callback: function ($$v) {
                                        _vm.estimatedCostMode = $$v
                                      },
                                      expression: "estimatedCostMode",
                                    },
                                  },
                                  [
                                    _vm.isEstimatedCostVisible
                                      ? _c(
                                          "option",
                                          { attrs: { value: "gross" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "task.field.estimatedCost"
                                                )
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.isEstimatedCostNetVisible
                                      ? _c(
                                          "option",
                                          { attrs: { value: "net" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "task.field.estimatedCostNet"
                                                )
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _vm.estimatedCostMode === "gross"
                                          ? _c("b-form-input", {
                                              attrs: {
                                                id: "estimatedCost",
                                                value: _vm.estimatedCost,
                                                readonly: "",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.estimatedCostMode === "net"
                                          ? _c("b-form-input", {
                                              attrs: {
                                                id: "estimatedCostNet",
                                                value: _vm.estimatedCostNet,
                                                readonly: "",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["estimatedCost"] != null
                          ? _vm._l(
                              _vm.customFieldMap["estimatedCost"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "estimatedCost" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isActualCostVisible || _vm.isActualCostNetVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c(
                                  "b-form-select",
                                  {
                                    staticClass: "title-dropdown mb-1",
                                    model: {
                                      value: _vm.actualCostMode,
                                      callback: function ($$v) {
                                        _vm.actualCostMode = $$v
                                      },
                                      expression: "actualCostMode",
                                    },
                                  },
                                  [
                                    _vm.isActualCostVisible
                                      ? _c(
                                          "option",
                                          { attrs: { value: "gross" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("task.field.actualCost")
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.isActualCostNetVisible
                                      ? _c(
                                          "option",
                                          { attrs: { value: "net" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "task.field.actualCostNet"
                                                )
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _vm.actualCostMode === "gross"
                                          ? _c("b-form-input", {
                                              class: _vm.actualCostClass,
                                              attrs: {
                                                id: "actualCost",
                                                value: _vm.actualCost,
                                                readonly: "",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.actualCostMode === "net"
                                          ? _c("b-form-input", {
                                              class: _vm.actualCostClass,
                                              attrs: {
                                                id: "actualCostNet",
                                                value: _vm.actualCostNet,
                                                readonly: "",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.actualCostAlert
                                          ? _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "work-effort-alert",
                                                    attrs: {
                                                      id: "actualCostAlert",
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: [
                                                          "far",
                                                          "circle-exclamation",
                                                        ],
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target: "actualCostAlert",
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.actualCostAlertMsg
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["actualCost"] != null
                          ? _vm._l(
                              _vm.customFieldMap["actualCost"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "actualCost" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isRebateVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("label", { staticClass: "mr-1" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("task.field.rebates"))
                                      ),
                                    ]),
                                    !_vm.isRebateReadOnly
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn-action",
                                            attrs: {
                                              id: "REBATE_ADD",
                                              disabled: _vm.isRebateReadOnly,
                                            },
                                            on: {
                                              click: _vm.rebateSelectorToggle,
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: ["far", "plus"] },
                                            }),
                                            _c("b-popover", {
                                              attrs: {
                                                target: "REBATE_ADD",
                                                placement: "top",
                                                triggers: "hover",
                                                content: _vm.$t(
                                                  "task.button.rebate_add"
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-form-text",
                                      { staticClass: "rebate-total mr-1" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "task.field.total_rebate",
                                                [_vm.totalRebate]
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("BadgeGroup", {
                                      attrs: { readOnly: _vm.isRebateReadOnly },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var item = ref.item
                                              var index = ref.index
                                              return [
                                                _c("Badge", {
                                                  key: index,
                                                  attrs: {
                                                    text:
                                                      item.name +
                                                      " (" +
                                                      _vm.formatRebate(
                                                        item.rebate
                                                      ) +
                                                      "%)",
                                                    variant: "primary",
                                                    pillable: !!item.pillable,
                                                    readOnly:
                                                      _vm.isRebateReadOnly,
                                                  },
                                                  on: {
                                                    badgeRemove: function (
                                                      $event
                                                    ) {
                                                      return _vm.rebateBadgeRemove(
                                                        index
                                                      )
                                                    },
                                                    badgeClick: function (
                                                      $event
                                                    ) {
                                                      return _vm.rebateBadgeClick(
                                                        item.uuId
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        486676024
                                      ),
                                      model: {
                                        value: _vm.rebates,
                                        callback: function ($$v) {
                                          _vm.rebates = $$v
                                        },
                                        expression: "rebates",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["rebates"] != null
                          ? _vm._l(
                              _vm.customFieldMap["rebates"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "rebates" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isStorageFileVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("label", { staticClass: "mr-1" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("task.field.files"))
                                      ),
                                    ]),
                                    !_vm.isStorageFileReadOnly
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn-action",
                                            attrs: {
                                              id: "FILE_ADD",
                                              disabled:
                                                _vm.isStorageFileReadOnly,
                                            },
                                            on: {
                                              click: _vm.fileSelectorToggle,
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: ["far", "plus"] },
                                            }),
                                            _c("b-popover", {
                                              attrs: {
                                                target: "FILE_ADD",
                                                placement: "top",
                                                triggers: "hover",
                                                content: _vm.$t(
                                                  "task.button.file_add"
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("BadgeGroup", {
                                      attrs: {
                                        readOnly: _vm.isStorageFileReadOnly,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var item = ref.item
                                              var index = ref.index
                                              return [
                                                _c("Badge", {
                                                  key: index,
                                                  attrs: {
                                                    text: _vm.labelFilename(
                                                      item.name,
                                                      item.type
                                                    ),
                                                    variant: "primary",
                                                    pillable: !!item.pillable,
                                                    readOnly:
                                                      _vm.isStorageFileReadOnly,
                                                    enableClickWhenReadOnly: "",
                                                  },
                                                  on: {
                                                    badgeRemove: function (
                                                      $event
                                                    ) {
                                                      return _vm.fileBadgeRemove(
                                                        index
                                                      )
                                                    },
                                                    badgeClick: function (
                                                      $event
                                                    ) {
                                                      return _vm.fileBadgeClick(
                                                        item
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1579555038
                                      ),
                                      model: {
                                        value: _vm.files,
                                        callback: function ($$v) {
                                          _vm.files = $$v
                                        },
                                        expression: "files",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["files"] != null
                          ? _vm._l(
                              _vm.customFieldMap["files"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "files" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isPredecessorVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("PredecessorList", {
                                      attrs: {
                                        predecessors: _vm.predecessorData,
                                        readOnly: _vm.isPredecessorReadOnly,
                                        taskId: _vm.id,
                                        projectId: _vm.projectId,
                                        taskName: _vm.task.name,
                                        isTemplate: _vm.isTemplate,
                                      },
                                      on: {
                                        change: _vm.predecessorChange,
                                        delete: _vm.predecessorDelete,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["predecessors"] != null
                          ? _vm._l(
                              _vm.customFieldMap["predecessors"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "predecessors" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isStorageFileVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  { staticClass: "mb-0" },
                                  [
                                    _c("label", { staticClass: "mr-1" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("task.field.image"))
                                      ),
                                    ]),
                                    !_vm.isStorageFileReadOnly
                                      ? [
                                          _vm.avatarUrl === null
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass: "btn-action",
                                                  attrs: {
                                                    id: "IMAGE_ADD",
                                                    disabled:
                                                      _vm.isStorageFileReadOnly,
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.imageSelectorToggle,
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: ["far", "plus"],
                                                    },
                                                  }),
                                                  _c("b-popover", {
                                                    attrs: {
                                                      target: "IMAGE_ADD",
                                                      placement: "top",
                                                      triggers: "hover",
                                                      content: _vm.$t(
                                                        "task.button.image_add"
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.avatarUrl !== null
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass: "btn-action",
                                                  attrs: {
                                                    disabled:
                                                      _vm.isStorageFileReadOnly,
                                                  },
                                                  on: {
                                                    click: _vm.imageRemove,
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: [
                                                        "far",
                                                        "trash-can",
                                                      ],
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "image-preview-container mb-3",
                                      },
                                      [
                                        _vm.avatarUrl != null
                                          ? _c("b-img-lazy", {
                                              staticClass: "image-preview",
                                              staticStyle: {
                                                "object-fit": "cover",
                                              },
                                              attrs: {
                                                id: "image-preview",
                                                src: _vm.avatarUrl,
                                              },
                                              nativeOn: {
                                                error: function ($event) {
                                                  return _vm.handleImgError.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["image"] != null
                          ? _vm._l(
                              _vm.customFieldMap["image"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "image" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isTagVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("TagList", {
                                      attrs: {
                                        holderId: _vm.id,
                                        readOnly: _vm.isTagReadOnly,
                                        tags: _vm.tags,
                                      },
                                      on: { modified: _vm.tagsModified },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["tags"] != null
                          ? _vm._l(
                              _vm.customFieldMap["tags"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "tags" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isNoteVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("NoteList", {
                                      attrs: {
                                        readOnly: _vm.isNoteReadOnly,
                                        notes: _vm.notes,
                                      },
                                      on: {
                                        add: _vm.addNote,
                                        edit: _vm.editNote,
                                        toRemove: _vm.removeNote,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["notes"] != null
                          ? _vm._l(
                              _vm.customFieldMap["notes"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "notes" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.task[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.task,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "task[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _vm.exists
        ? [
            _vm.state.historyShow
              ? _c("GenericHistoryModal", {
                  attrs: {
                    show: _vm.state.historyShow,
                    id: _vm.id,
                    entityType: _vm.historyEntityType,
                    customFields: _vm.customFields,
                    links:
                      "STAGE,REBATE,NOTE,STAFF,PROJECT,RESOURCE,TAG,SKILL,STORAGE_FILE",
                  },
                  on: {
                    "update:show": function ($event) {
                      return _vm.$set(_vm.state, "historyShow", $event)
                    },
                  },
                })
              : _vm._e(),
            _vm.state.noteShow
              ? _c("NoteModal", {
                  attrs: { show: _vm.state.noteShow, note: _vm.note },
                  on: {
                    "update:show": function ($event) {
                      return _vm.$set(_vm.state, "noteShow", $event)
                    },
                    toAdd: _vm.toAddNote,
                    toUpdate: _vm.toUpdateNote,
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
      _vm.state.skillSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.state.skillSelectorShow,
              entityService: _vm.skillUtil,
              entity: "SKILL",
              nonAdmin: "",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "skillSelectorShow", $event)
              },
              ok: _vm.skillSelectorOk,
            },
          })
        : _vm._e(),
      _vm.state.skillLevelEditShow
        ? _c("SkillLevelModal", {
            attrs: {
              show: _vm.state.skillLevelEditShow,
              uuId: _vm.skillLevelEdit.uuId,
              name: _vm.skillLevelEdit.name,
              level: _vm.skillLevelEdit.level,
              cData: _vm.skillLevelEdit.data,
              edgeName: _vm.isTemplate ? "TASK_TEMPLATE-SKILL" : "TASK-SKILL",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "skillLevelEditShow", $event)
              },
              ok: _vm.skillLevelOk,
            },
          })
        : _vm._e(),
      _vm._l(_vm.toConfirmSkills, function (item, index) {
        return [
          _c("SkillLevelModal", {
            key: "skill-" + index,
            attrs: {
              show: item.show,
              canApplyAll: _vm.toConfirmSkills.length > 1,
              uuId: item.uuId,
              name: item.name,
              level: item.level,
              cData: item,
              edgeName: _vm.isTemplate ? "TASK_TEMPLATE-SKILL" : "TASK-SKILL",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(item, "show", $event)
              },
              ok: _vm.toConfirmSkillOk,
              cancel: _vm.toConfirmSkillCancel,
            },
          }),
        ]
      }),
      _vm.state.staffSelectorShow
        ? _c("StaffSelectorModalForAdmin", {
            attrs: {
              show: _vm.state.staffSelectorShow,
              hideBookings: _vm.isTemplate,
              projectIds: [_vm.projectId],
              companies: _vm.project ? _vm.project.company : null,
              taskUuId: _vm.exists ? _vm.id : null,
              nonAdmin: "",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "staffSelectorShow", $event)
              },
              ok: _vm.staffSelectorOk,
            },
          })
        : _vm._e(),
      _vm.state.staffWorkEffortShow
        ? _c("StaffWorkEffortModal", {
            attrs: {
              readOnly: _vm.isReadOnly,
              childTaskEfforts: _vm.childTasksActualDuration,
              workEfforts: _vm.staffs,
              show: _vm.state.staffWorkEffortShow,
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "staffWorkEffortShow", $event)
              },
              ok: _vm.staffWorkEffortOk,
            },
          })
        : _vm._e(),
      _vm.state.staffUtilizationEditShow
        ? _c("StaffUtilizationModal", {
            attrs: {
              show: _vm.state.staffUtilizationEditShow,
              uuId: _vm.staffUtilizationEdit.uuId,
              name: _vm.staffUtilizationEdit.name,
              utilization: _vm.staffUtilizationEdit.utilization,
              unit: _vm.staffUtilizationEdit.unit,
              genericStaff: _vm.staffUtilizationEdit.genericStaff,
              cData: _vm.staffUtilizationEdit.data,
              edgeName: "TASK-STAFF",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "staffUtilizationEditShow", $event)
              },
              ok: _vm.staffUtilizationOk,
            },
          })
        : _vm._e(),
      _vm._l(_vm.toConfirmStaffs, function (item, index) {
        return [
          item.show
            ? _c("StaffUtilizationModal", {
                key: "staff-" + index,
                attrs: {
                  show: item.show,
                  canApplyAll: _vm.toConfirmStaffs.length > 1,
                  uuId: item.uuId,
                  name: item.name,
                  utilization: item.utilization,
                  unit: item.quantity,
                  genericStaff: item.genericStaff,
                  cData: item,
                  edgeName: "TASK-STAFF",
                },
                on: {
                  "update:show": function ($event) {
                    return _vm.$set(item, "show", $event)
                  },
                  ok: _vm.toConfirmStaffOk,
                  cancel: _vm.toConfirmStaffCancel,
                },
              })
            : _vm._e(),
        ]
      }),
      _vm.state.resourceSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.state.resourceSelectorShow,
              entityService: _vm.resourceUtil,
              projectIds: [_vm.projectId],
              entity: "RESOURCE",
              nonAdmin: "",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "resourceSelectorShow", $event)
              },
              ok: _vm.resourceSelectorOk,
            },
          })
        : _vm._e(),
      _vm.state.resourceUnitEditShow
        ? _c("ResourceUnitModal", {
            attrs: {
              show: _vm.state.resourceUnitEditShow,
              uuId: _vm.resourceUnitEdit.uuId,
              name: _vm.resourceUnitEdit.name,
              unit: _vm.resourceUnitEdit.unit,
              utilization: parseFloat(_vm.resourceUnitEdit.utilization),
              cData: _vm.resourceUnitEdit.data,
              edgeName: "TASK-RESOURCE",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "resourceUnitEditShow", $event)
              },
              ok: _vm.resourceUnitOk,
            },
          })
        : _vm._e(),
      _vm._l(_vm.toConfirmResources, function (item, index) {
        return [
          item.show
            ? _c("ResourceUnitModal", {
                key: "resource-" + index,
                attrs: {
                  show: item.show,
                  canApplyAll: _vm.toConfirmResources.length > 1,
                  uuId: item.uuId,
                  name: item.name,
                  unit: item.unit,
                  utilization: parseFloat(item.utilization),
                  cData: item,
                  edgeName: "TASK-RESOURCE",
                },
                on: {
                  "update:show": function ($event) {
                    return _vm.$set(item, "show", $event)
                  },
                  ok: _vm.toConfirmResourceOk,
                  cancel: _vm.toConfirmResourceCancel,
                },
              })
            : _vm._e(),
        ]
      }),
      _vm.state.rebateSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.state.rebateSelectorShow,
              entityService: _vm.rebateUtil,
              preselected: _vm.rebateEdit.uuId,
              entity: "REBATE",
              nonAdmin: "",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "rebateSelectorShow", $event)
              },
              ok: _vm.rebateSelectorOk,
            },
          })
        : _vm._e(),
      _vm.state.fileSelectorShow
        ? _c("FileSelectorModal", {
            attrs: {
              show: _vm.state.fileSelectorShow,
              multiple: _vm.fileMultiple,
              allowedMimeType: _vm.fileMimetypes,
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "fileSelectorShow", $event)
              },
              ok: _vm.fileSelectorOk,
            },
          })
        : _vm._e(),
      _vm.downloadProgressShow
        ? _c("DownloadProgressModal", {
            attrs: {
              show: _vm.downloadProgressShow,
              downloadPercentage: _vm.downloadPercentage,
            },
            on: {
              "update:show": function ($event) {
                _vm.downloadProgressShow = $event
              },
              cancel: _vm.downloadCancel,
            },
          })
        : _vm._e(),
      _c("TaskDateTimeDurationCalculation", {
        attrs: {
          show: _vm.state.durationCalculationShow,
          taskName: _vm.taskName,
          resizeMode: _vm.durationCalculation.resizeMode,
          defaultActionForNonWorkPrompt:
            _vm.durationCalculation.defaultActionForNonWorkPrompt != null
              ? _vm.durationCalculation.defaultActionForNonWorkPrompt
              : _vm.isTemplate
              ? "move"
              : null,
          trigger: _vm.durationCalculation.trigger,
          startDateStr: _vm.durationCalculation.startDateStr,
          startTimeStr: _vm.durationCalculation.startTimeStr,
          closeDateStr: _vm.durationCalculation.closeDateStr,
          closeTimeStr: _vm.durationCalculation.closeTimeStr,
          durationDisplay: _vm.durationCalculation.durationDisplay,
          calendar: _vm.durationCalculation.calendar,
          projectScheduleFromStart:
            _vm.durationCalculation.projScheduleFromStart,
          taskAutoScheduleMode: _vm.durationCalculation.taskAutoScheduleMode,
          constraintType: _vm.durationCalculation.constraintType,
          constraintDateStr: _vm.durationCalculation.constraintDateStr,
          lockDuration: _vm.durationCalculation.lockDuration,
          oldDateStr: _vm.durationCalculation.oldDateStr,
          oldTimeStr: _vm.durationCalculation.oldTimeStr,
          projectStartDateStr: _vm.project.startDateStr,
          projectCloseDateStr: _vm.project.closeDateStr,
          oldConstraintType: _vm.durationCalculation.oldConstraintType,
          oldConstraintDateStr: _vm.durationCalculation.oldConstraintDateStr,
          skipOutOfProjectDateCheck:
            _vm.durationCalculation.skipOutOfProjectDateCheck,
          enableManualScheduleSuggestion:
            _vm.durationCalculation.enableManualScheduleSuggestion,
          durationConversionOpts: _vm.durationConversionOpts,
        },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "durationCalculationShow", $event)
          },
          "update:calendar": function ($event) {
            return _vm.$set(_vm.durationCalculation, "calendar", $event)
          },
          success: _vm.durationCalculationOk,
          cancel: _vm.durationCalculationCancel,
          skip: _vm.durationCalculationCancel,
          calendarChange: _vm.durationCalculationCalendarChange,
        },
      }),
      _c("SplitTaskModal", {
        attrs: {
          show: _vm.state.splitTaskModalShow,
          title: _vm.$t("button.split_task"),
          calendar: _vm.calendar,
          duration: _vm.duration,
        },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "splitTaskModalShow", $event)
          },
          success: _vm.splitTaskSuccess,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_task_changed"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            hidden: function ($event) {
              _vm.state.alertTaskChangedShow = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.handleApplyBtnClicked(
                            _vm.staffSelectorToggle
                          )
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.save")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.alertTaskChangedShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "alertTaskChangedShow", $$v)
            },
            expression: "state.alertTaskChangedShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("task.confirmation.task_changed")) + " "
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_change_on_complete"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            hidden: function ($event) {
              _vm.state.confirmChangeOnCompleteShow = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.ok },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.changeOnCompleteCancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.confirmChangeOnCompleteShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "confirmChangeOnCompleteShow", $$v)
            },
            expression: "state.confirmChangeOnCompleteShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("task.confirmation.change_on_complete")) + " "
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("project.confirmation.title_apply_rebates"),
            "ok-title": _vm.$t("button.yes"),
            "cancel-title": _vm.$t("button.no"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.applyRebates },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.applyRebates },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.yes")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.no")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.promptApplyRebates,
            callback: function ($$v) {
              _vm.promptApplyRebates = $$v
            },
            expression: "promptApplyRebates",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _c("p", [
              _vm._v(_vm._s(_vm.$t("task.confirmation.apply_rebates"))),
            ]),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }