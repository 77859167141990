<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="lg" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow"
      @hidden="cancelModal"
      scrollable
    >

      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <template v-if="exists">
          <div class="history-button lock-container">
            <template v-if="isLockVisible">
              <div class="ml-1 mr-1">{{ $t('lock') }}</div>
              <b-form-checkbox :disabled="isLockReadOnly" switch v-model="task.readOnly"/>
            </template>
            <b-button  variant="secondary" size="sm" @click="state.historyShow = true">
              <font-awesome-icon :icon="['far', 'clock-rotate-left']"/>
              {{ $t('button.history') }}
            </b-button>
          </div>
        </template>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <template v-if="isAccessDenied">
        <div class="modal-message-overlay">
        <span class="grid-overlay">
          {{ 
              exists
              ? $t('entity_selector.error.insufficient_permission_to_show_data')
              : restrictedRequiredField != null
              ? $t('entity_selector.error.insufficient_permission_to_add_entity_with_reason', [$t('task.title_singular').toLowerCase(), restrictedRequiredField])
              : $t('entity_selector.error.insufficient_permission_to_add_entity', [$t('task.title_singular').toLowerCase()])
          }}
        </span>
        </div>
      </template>
      <template v-else>

        <b-alert :variant="alertError? 'danger':'success'" dismissible :show="showError" @dismissed="dismissAlert">
          <font-awesome-icon :icon="alertError? ['fas', 'triangle-exclamation'] : ['far', 'check']"/>&nbsp;&nbsp;{{ alertMsg }}
          <ul :show="showErrorDetail" class="mb-0">
            <template v-for="(item, index) in alertMsgDetails">
              <li :key="index">{{ item }}</li>
            </template>
          </ul>
        </b-alert>

        <div class="container pl-0">
          <b-row>
            <b-col v-if="isNameVisible" cols="12" lg="8" class="pr-0">
              <b-form-group :label="$t('task.field.name')" label-for="name">
                <b-input-group>
                  <b-form-input id="name" type="text"
                    :data-vv-as="$t('task.field.name')"
                    data-vv-name="task.name"
                    data-vv-delay="500"
                    v-model="task.name" 
                    v-validate="{ required: true }"
                    :readonly="isNameReadOnly"
                    :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'location.name')"
                    autofocus trim>
                  </b-form-input>
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('task.name') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['name'] != null">
              <b-col v-for="(field, index) in customFieldMap['name']" :key="'name'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col v-if="isIdentifierVisible" cols="12" md="4" class="pr-0">
              <b-form-group :label="$t('field.identifier')" label-for="identifier">
                <b-input-group>
                  <b-form-input id="identifier" type="text"
                    :data-vv-as="$t('field.identifier')"
                    data-vv-name="task.identifier"
                    :maxlength="maxIdentifierLength"
                    v-model="task.identifier" 
                    :readonly="isIdentifierReadOnly"
                    trim>
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          
            <template v-if="customFieldMap['identifier'] != null">
              <b-col v-for="(field, index) in customFieldMap['identifier']" :key="'identifier'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <template v-if="customFieldMap['default'] != null">
              <b-col v-for="(field, index) in customFieldMap['default']" :key="index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <template v-if="isParentVisible">
              <b-col cols="12" sm="6" class="pr-0">
                <b-form-group :label="isTemplate ? $t('task.field.taskTemplate') : $t('task.field.project')" label-for="task-project">
                  <b-input-group>
                    <b-form-input id="task-project" type="text"
                      v-model="project.name"
                      disabled/>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <template v-if="customFieldMap['projects'] != null">
                <b-col v-for="(field, index) in customFieldMap['projects']" :key="'projects'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>

              <b-col cols="12" sm="6" class="pr-0">
                <b-form-group :label="$t('task.field.parent')" label-for="task-parent">
                <b-input-group>
                    <Treeselect instance-id="task-parent" :default-expand-level="Infinity"
                      v-model="task.parent"
                      :multiple="false"
                      :options="optionTask"
                      :data-vv-as="$t('task.field.parent')"
                      data-vv-name="task.parent"
                      :clearable="false"
                      data-vv-delay="500"
                      :disabled="isParentReadOnly"/>
                  </b-input-group>
                  <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showParentError }">
                    <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('task.parent') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <template v-if="customFieldMap['parentTask'] != null">
                <b-col v-for="(field, index) in customFieldMap['parentTask']" :key="'parentTask'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>
            </template>
            <template v-else>
              <template v-if="customFieldMap['projects'] != null">
                <b-col v-for="(field, index) in customFieldMap['projects']" :key="'projects'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>
              <template v-if="customFieldMap['parentTask'] != null">
                <b-col v-for="(field, index) in customFieldMap['parentTask']" :key="'parentTask'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>
            </template>
          
            <b-col v-if="isTaskTypeVisible" cols="12" xl="3" class="pr-0">
              <b-form-group id="field-type" :label="$t('task.field.taskType')" label-for="task-type">
                  <b-form-select id="task-type" v-model="task.taskType" 
                    :options="optionType"
                    text-field="label" 
                    @change="typeChanged()"
                    value-field="key"
                    :disabled="isTaskTypeReadOnly"/>
              </b-form-group>
            </b-col>
            <template v-if="customFieldMap['type'] != null">
              <b-col v-for="(field, index) in customFieldMap['type']" :key="'type'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col v-if="isPriorityVisible" cols="12" xl="3" class="pr-0">
              <b-form-group id="field-priority" :label="$t('task.field.priority')" label-for="task-priority">
                  <select id="task-priority" class="custom-select" v-model="task.priority" :disabled="isPriorityReadOnly">
                    <template v-for="(opt, index) in optionPriority">
                      <option :value="opt.value" :key="index" :style="{ display: opt.num < 0? 'none': 'block' }">{{ opt.text }}</option>
                    </template>
                  </select>
              </b-form-group>
            </b-col>
            <template v-if="customFieldMap['priority'] != null">
              <b-col v-for="(field, index) in customFieldMap['priority']" :key="'priority'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col v-if="isComplexityVisible" cols="12" xl="3" class="pr-0">
              <b-form-group :label="$t('task.field.complexity')" label-for="task-complexity">
                <select id="task-complexity" class="custom-select" v-model="task.complexity" :disabled="isComplexityReadOnly">
                  <template v-for="(opt, index) in optionComplexity">
                    <option :value="opt.value" :key="index" :style="{ display: opt.num < 0? 'none': 'block' }">{{ opt.text }}</option>
                  </template>
                </select>
              </b-form-group>
            </b-col>
            <template v-if="customFieldMap['complexity'] != null">
              <b-col v-for="(field, index) in customFieldMap['complexity']" :key="'complexity'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col v-if="isColorVisible" cols="12" xl="3" class="pr-0">
              <Color v-model="task.color" :update="updatedColor"
                    :disabled="isColorReadOnly"/>
            </b-col>
            <template v-if="customFieldMap['color'] != null">
              <b-col v-for="(field, index) in customFieldMap['color']" :key="'color'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col v-if="isDescriptionVisible" cols="12 pr-0">
              <b-form-group :label="$t('task.field.description')" label-for="task-description">
                <b-form-textarea id="task-description" 
                  :placeholder="isDescriptionReadOnly? '' : $t('task.placeholder.description')"
                  :data-vv-as="$t('task.field.description')"
                  data-vv-name="task.description"
                  data-vv-delay="500"
                  v-model="task.description"
                  :max-rows="6"
                  :readonly="isDescriptionReadOnly"
                  trim
                  :rows="3"/>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDescriptionError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('task.description') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <template v-if="customFieldMap['description'] != null">
              <b-col v-for="(field, index) in customFieldMap['description']" :key="'description'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
            
          <!-- </b-row>

          <b-row class="mb-3"> -->
            <b-col cols="12"  xl="6" class="pr-0" v-if="isStartTimeVisible">
              <b-row class="mb-3">
                <b-col cols="12">
                  <b-form-group :label="$t('task.field.startTime')" label-for="task-startDate">
                    <b-form-datepicker id="task-startDate" v-model="duration.startDate" class="mb-2" @[dateTimeCalcInputTrigger]="dateTimeInputHandler($event, 'startDate')"
                      today-button
                      reset-button
                      close-button
                      hide-header
                      :label-today-button="$t('date.today')"
                      :label-reset-button="$t('date.reset')"
                      :label-close-button="$t('date.close')"
                      :label-no-date-selected="$t('date.none')"
                      today-button-variant="primary"
                      reset-button-variant="danger" 
                      close-button-variant="secondary"
                      v-bind="{ ...(duration.closeDate != null && { initialDate: duration.closeDate })}"
                      :readonly="isStartTimeReadOnly"
                    >
                      <template v-slot:button-content="{ }">
                        <font-awesome-icon :icon="['far', 'calendar-days']" />
                      </template>
                    </b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <Timepicker v-model="duration.startTime" @[dateTimeCalcInputTrigger]="dateTimeInputHandler($event, 'startTime')" 
                    :disableTime="isStartTimeReadOnly">
                  </Timepicker>
                </b-col>
              </b-row>
            </b-col>
            <template v-if="customFieldMap['startTime'] != null">
              <b-col v-for="(field, index) in customFieldMap['startTime']" :key="'startTime'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12" xl="6" class="pr-0" v-if="isCloseTimeVisible">
              <b-row class="mb-3">
                <b-col cols="12">
                  <b-form-group :label="$t('task.field.closeTime')" label-for="task-closeDate">
                    <b-form-datepicker id="task-closeDate" v-model="duration.closeDate" class="mb-2" @[dateTimeCalcInputTrigger]="dateTimeInputHandler($event, 'closeDate')"
                      today-button
                      reset-button
                      close-button
                      hide-header
                      :label-today-button="$t('date.today')"
                      :label-reset-button="$t('date.reset')"
                      :label-close-button="$t('date.close')"
                      :label-no-date-selected="$t('date.none')"
                      today-button-variant="primary"
                      reset-button-variant="danger" 
                      close-button-variant="secondary"
                      v-bind="{ ...(duration.startDate != null && { initialDate: duration.startDate })}"
                      :readonly="isCloseTimeReadOnly"
                    >
                      <template v-slot:button-content="{ }">
                        <font-awesome-icon :icon="['far', 'calendar-days']" />
                      </template>
                    </b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <Timepicker v-model="duration.closeTime" @[dateTimeCalcInputTrigger]="dateTimeInputHandler($event, 'closeTime')" 
                    :disableTime="isCloseTimeReadOnly">
                  </Timepicker>
                </b-col>
              </b-row>
            </b-col>
            <template v-if="customFieldMap['closeTime'] != null">
              <b-col v-for="(field, index) in customFieldMap['closeTime']" :key="'closeTime'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col v-if="isFixedDurationVisible" cols="12" md="6" class="pr-0">
              <b-form-group :label="$t('task.field.fixedDuration')" label-for="task-fixed-duration">
                <b-input-group>
                  <b-input-group-prepend v-if="!isFixedDurationReadOnly">
                    <b-button id="FIXED_DURATION_SUBTRACT" @click.prevent="fixedDurationAddMinus(-1)" :disabled="disableFixedDuration">
                      <font-awesome-icon :icon="['far', 'minus']"/>
                      <b-popover
                        target="FIXED_DURATION_SUBTRACT"
                        placement="top"
                        triggers="hover"
                        :content="$t('task.button.duration_subtract')">
                      </b-popover>
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input id="task-fixed-duration" type="text"
                    @keydown="onFixedDurationKeyDown($event)" 
                    @keyup="onFixedDurationKeyUp($event)"
                    @change="onFixedDurationChange"
                    :disabled="disableFixedDuration"
                    :data-vv-as="$t('task.field.fixedDuration')"
                    data-vv-name="task.fixedDuration"
                    :class="fixedDurationClass"
                    data-vv-delay="500"
                    v-model="task.fixedDuration" 
                    :readonly="isFixedDurationReadOnly"
                    >
                  </b-form-input>
                  <b-input-group-append v-if="!isFixedDurationReadOnly">
                    <b-button id="FIXED_DURATION_ADD" @click.prevent="fixedDurationAddMinus(1)" :disabled="disableFixedDuration">
                      <font-awesome-icon :icon="['far', 'plus']"/>
                      <b-popover
                        target="FIXED_DURATION_ADD"
                        placement="top"
                        triggers="hover"
                        :content="$t('task.button.duration_add')">
                      </b-popover>
                    </b-button>
                  </b-input-group-append>
                  <b-input-group-append v-if="fixedDurationAlert">
                    <b-input-group-text id="fixed-duration-alert" class="fixed-duration-alert">
                      <font-awesome-icon :icon="['far', 'circle-exclamation']"/>
                    </b-input-group-text>
                    <b-popover target="fixed-duration-alert" triggers="hover" placement="topleft">
                      {{ fixedDurationAlertMsg }}
                    </b-popover>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showFixedDurationError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('fixedDuration') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <template v-if="customFieldMap['fixedDuration'] != null">
              <b-col v-for="(field, index) in customFieldMap['fixedDuration']" :key="'fixedDuration'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col v-if="isDurationVisible" cols="12" md="6" class="pr-0">
              <b-form-group :label="$t('task.field.duration')" label-for="task-duration">
                <b-input-group>
                  <b-input-group-prepend v-if="!isDurationReadOnly">
                    <b-button id="DURATION_SUBTRACT" @click.prevent="durationAddMinus(-1)">
                      <font-awesome-icon :icon="['far', 'minus']"/>
                      <b-popover
                        target="DURATION_SUBTRACT"
                        placement="top"
                        triggers="hover"
                        :content="$t('task.button.duration_subtract')">
                      </b-popover>
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input id="task-duration" type="text" @change="durationValueChanged($event)"
                    @keydown="onDurationKeyDown($event)" 
                    @keyup="onDurationKeyUp($event)"
                    :disabled="disableDuration"
                    :data-vv-as="$t('task.field.duration')"
                    data-vv-name="duration.value"
                    data-vv-delay="500"
                    v-model="duration.value" 
                    :readonly="isDurationReadOnly"
                    >
                  </b-form-input>
                  <b-input-group-append v-if="!isDurationReadOnly">
                    <b-button id="DURATION_ADD" @click.prevent="durationAddMinus(1)">
                      <font-awesome-icon :icon="['far', 'plus']"/>
                      <b-popover
                        target="DURATION_ADD"
                        placement="top"
                        triggers="hover"
                        :content="$t('task.button.duration_add')">
                      </b-popover>
                    </b-button>
                  </b-input-group-append>
                  <b-input-group-append v-if="isDurationVisible">
                    <span id="lockDurationButton">
                      <b-button @click.prevent="toggleLockDuration()" :disabled="isDurationReadOnly" :variant="task.lockDuration? 'success':'secondary'">
                        <font-awesome-icon :icon="task.lockDuration? ['far','lock']:['far','unlock']"/>
                      </b-button>
                    </span>
                    <b-popover target="lockDurationButton" triggers="hover" placement="topleft">
                      {{ $t('task.popover.lockDuration') }}
                    </b-popover>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDurationError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duration.value') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <template v-if="customFieldMap['estimatedDuration'] != null">
              <b-col v-for="(field, index) in customFieldMap['estimatedDuration']" :key="'estimatedDuration'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12" md="6" class="pr-0 mb-3 mb-md-0" v-if="isActualDurationVisible">
              <b-form-group>
                <label class="mr-1">{{ $t(`task.field.actualDuration`) }}</label>
                <b-input-group>
                  <div id="taskActualDurationDiv" :disabled="!disableWorkEffort" class="actual-duration-form-control-fix">
                    <b-form-input id="task-actual-duration" type="text"
                      :disabled="isActualDurationReadOnly || disableWorkEffort"
                      :class="workEffortClass"
                      v-model="totalWorkEffort" 
                      readonly
                      @click="staffWorkEffortToggle"
                      >
                    </b-form-input>
                  </div>
                  <b-popover target="taskActualDurationDiv" triggers="hover" placement="top" v-if="!isActualDurationReadOnly">
                    {{ $t('task.alert.cannot_edit_work_effort') }}
                  </b-popover>
                  <b-input-group-append v-if="workEffortAlert">
                    <b-input-group-text id="work-effort-alert" class="work-effort-alert">
                      <font-awesome-icon :icon="['far', 'circle-exclamation']"/>
                    </b-input-group-text>
                    <b-popover target="work-effort-alert" triggers="hover" placement="topleft">
                      {{ workEffortAlertMsg }}
                    </b-popover>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <template v-if="customFieldMap['actualDuration'] != null">
              <b-col v-for="(field, index) in customFieldMap['actualDuration']" :key="'actualDuration'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          
            <b-col cols="12" sm="6" v-if="isETCVisible" class="pr-0">
              <b-form-group id="field-progress" :label="$t('task.field.etoc')" label-for="task-etoc">
                <b-input-group>
                  <b-input-group-prepend v-if="!isETCReadOnly">
                    <b-button id="ETC_SUBTRACT" @click.prevent="etocAddMinus(-1)" :disabled="isETCReadOnly">
                      <font-awesome-icon :icon="['far', 'minus']"/><b-popover
                        target="ETC_SUBTRACT"
                        placement="top"
                        triggers="hover"
                        :content="$t('task.button.etc_subtract')">
                      </b-popover>
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input id="task-etoc" v-model="etocInput" type="text" 
                    :readonly="isETCReadOnly"
                    @change="onEtocInput"
                    @keydown="onEtocKeyDown($event)" 
                    @keyup="onEtocKeyUp($event)"
                  />
                  <b-input-group-append v-if="!isETCReadOnly">
                    <b-button id="ETC_ADD" @click.prevent="etocAddMinus(1)" :disabled="disableEtc || isReadOnly">
                      <font-awesome-icon :icon="['far', 'plus']"/>
                      <b-popover
                        target="ETC_ADD"
                        placement="top"
                        triggers="hover"
                        :content="$t('task.button.etc_add')">
                      </b-popover>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <template v-if="customFieldMap['etc'] != null">
              <b-col v-for="(field, index) in customFieldMap['etc']" :key="'etc'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12" sm="6" v-if="isProgressVisible" class="pr-0">
              <b-form-group id="field-progress" :label="$t('task.field.progress')" label-for="task-progress">
                <b-input-group>
                  <b-input-group-prepend v-if="!isProgressReadOnly">
                    <b-button id="PROGRESS_SUBTRACT" @click.prevent="progressAddMinus(-1)" :disabled="isProgressReadOnly">
                      <font-awesome-icon :icon="['far', 'minus']"/>
                      <b-popover
                        target="PROGRESS_SUBTRACT"
                        placement="top"
                        triggers="hover"
                        :content="$t('task.button.progress_subtract')">
                      </b-popover>
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input id="task-progress" v-model="task.progress" 
                    type="text" @blur="progressFormat" 
                    :readonly="isProgressReadOnly">
                  </b-form-input>
                  <template v-if="!isProgressReadOnly">
                    <b-input-group-append>
                      <b-button id="PROGRESS_ADD" @click.prevent="progressAddMinus(1)">
                        <font-awesome-icon :icon="['far', 'plus']"/>
                        <b-popover
                          target="PROGRESS_ADD"
                          placement="top"
                          triggers="hover"
                          :content="$t('task.button.progress_add')">
                        </b-popover>
                      </b-button>
                    </b-input-group-append>
                    <b-input-group-append>
                      <span id="BTN_PROGRESS_COMPLETE">
                        <b-button @click.prevent="progressComplete()" :variant="task.progress == 100? 'success':'secondary'">
                          <font-awesome-icon :icon="['far', 'check']"/>
                        </b-button>
                      </span>
                      <b-popover target="BTN_PROGRESS_COMPLETE" triggers="hover" placement="topleft">
                        {{ $t('task.popover.progressComplete') }}
                      </b-popover>
                    </b-input-group-append>
                  </template>
                </b-input-group>
              </b-form-group>
            </b-col>
            <template v-if="customFieldMap['progress'] != null">
              <b-col v-for="(field, index) in customFieldMap['progress']" :key="'progress'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          
            <b-col v-if="isAutoSchedulingVisible" cols="12" sm="6" class="pr-0">
              <b-form-group :label="$t('task.field.autoScheduling')" label-for="task-autoschedule">
                <b-form-select v-model="task.autoScheduling" 
                    id="task-autoschedule" 
                    @[dateTimeCalcChangeTrigger]="dateTimeInputHandler($event, 'taskScheduleMode')"
                    :readonly="isAutoSchedulingReadOnly" 
                    :disabled="isAutoSchedulingReadOnly">
                  <template>
                    <option :value="true">{{ $t('task.autoschedule.auto') }}</option>
                    <option :value="false">{{ $t('task.autoschedule.manual') }}</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <template v-if="customFieldMap['autoScheduling'] != null">
              <b-col v-for="(field, index) in customFieldMap['autoScheduling']" :key="'autoScheduling'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12" sm="6" v-if="isStageVisible" class="pr-0">
              <b-form-group id="field-stage" :label="$t('task.field.stage')" label-for="task-stage">
                  <b-form-select id="task-stage" v-model="stage" 
                    :options="optionStage" 
                    :disabled="isStageReadOnly"
                    text-field="label" 
                    value-field="key"/>
              </b-form-group>
            </b-col>
            <template v-if="customFieldMap['stage'] != null">
              <b-col v-for="(field, index) in customFieldMap['stage']" :key="'stage'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          
          
            <b-col cols="12" v-if="isConstraintVisible">
              <b-form-group id="task-constraint" :label="$t('task.field.constraint')" label-for="task-constraint_type">
                <b-row>
                  <b-col cols="12" xl="6" class="mb-2 mb-xl-0 pr-0">
                    <b-form-select id="task-constraint_type" 
                      :disabled="isConstraintReadOnly"
                      v-model="constraint.type" 
                      :options="optionConstraint"
                      value-field="key"
                      text-field="label"
                      @[dateTimeCalcChangeTrigger]="dateTimeInputHandler($event, 'constraintType')"
                    />
                  </b-col>
                  <b-col cols="12" xl="6" class="pr-0">
                    <b-form-datepicker id="task-constraintDate" v-model="constraint.date"
                      :disabled="disableConstraintDate || isConstraintReadOnly"
                      today-button
                      reset-button
                      close-button
                      hide-header
                      :label-today-button="$t('date.today')"
                      :label-reset-button="$t('date.reset')"
                      :label-close-button="$t('date.close')"
                      :label-no-date-selected="$t('date.none')"
                      today-button-variant="primary"
                      reset-button-variant="danger" 
                      close-button-variant="secondary"
                      @[dateTimeCalcInputTrigger]="dateTimeInputHandler($event, 'constraintDate')"
                    />
                    <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showConstraintDateError }">
                      <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('constraint.date') }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <template v-if="customFieldMap['constraint'] != null">
              <b-col v-for="(field, index) in customFieldMap['constraint']" :key="'constraint'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12" class="pr-0" v-if="isSkillVisible">
              <b-form-group>
                <label class="mr-1">{{ $t(`task.field.skills`) }}</label>
                <button v-if="!isSkillReadOnly" id="SKILL_ADD" :disabled="isSkillReadOnly" class="btn-action" @click="skillSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                  <b-popover
                    target="SKILL_ADD"
                    placement="top"
                    triggers="hover"
                    :content="$t('task.button.skill_add')">
                  </b-popover>
                </button>
                <BadgeGroup v-model="skills" :readOnly="isSkillReadOnly">
                  <template v-slot:default="{ item, index }">
                    <Badge @badgeRemove="skillBadgeRemove(index)" @badgeClick="skillBadgeClick(item.uuId)"
                      :text="item.level ? `${item.name} (${item.level})` : item.name" 
                      variant="primary" 
                      :pillable="!!item.pillable" :key="index" 
                      :readOnly="isSkillReadOnly" />
                    </template>
                </BadgeGroup>
              </b-form-group>
            </b-col>
            <template v-if="customFieldMap['skills'] != null">
              <b-col v-for="(field, index) in customFieldMap['skills']" :key="'skills'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12" class="pr-0" v-if="isStaffVisible">
              <b-form-group>
                <label class="mr-1">{{ $t(`task.field.staffs`) }}</label>
                <button v-if="!isStaffReadOnly" id="STAFF_ADD" :disabled="isStaffReadOnly" class="btn-action" @click="preStaffSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                  <b-popover
                    target="STAFF_ADD"
                    placement="top"
                    triggers="hover"
                    :content="$t('task.button.staff_add')">
                  </b-popover>
                </button>
              
                <BadgeGroup v-model="staffs" :readOnly="isStaffReadOnly">
                  <template v-slot:default="{ item, index }">
                    <Badge @badgeRemove="staffBadgeRemove(index)" @badgeClick="staffBadgeClick(item.uuId)"
                      :text="`${item.name} (${item.quantity > 1? item.quantity + ' x ': ''}${item.utilization? Math.trunc(item.utilization * 100) : 100}%)`" 
                      :variant="item.genericStaff? 'info' : staffUsage[item.name] ? 'warning' : 'primary'"
                      :errorMessage="staffUsage[item.name] || null"
                      :pillable="!!item.pillable" :key="index" 
                      :readOnly="isStaffReadOnly" />
                    </template>
                </BadgeGroup>
              </b-form-group>
            </b-col>
            <template v-if="customFieldMap['staffs'] != null">
              <b-col v-for="(field, index) in customFieldMap['staffs']" :key="'staffs'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12" class="pr-0" v-if="isResourceVisible">
              <b-form-group>
                <label class="mr-1">{{ $t(`task.field.resources`) }}</label>
                <button v-if="!isResourceReadOnly" id="RESOURCE_ADD" :disabled="isResourceReadOnly" class="btn-action" @click="resourceSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                  <b-popover
                    target="RESOURCE_ADD"
                    placement="top"
                    triggers="hover"
                    :content="$t('task.button.resource_add')">
                  </b-popover>
                </button>
                <BadgeGroup v-model="resources" :readOnly="isResourceReadOnly">
                  <template v-slot:default="{ item, index }">
                    <Badge @badgeRemove="resourceBadgeRemove(index)" @badgeClick="resourceBadgeClick(item.uuId)"
                      :text="`${item.name} (${item.quantity > 1? item.quantity + ' x ' :''}${item.utilization? item.utilization * 100 : 100}%)`" 
                      variant="primary" 
                      :pillable="!!item.pillable" :key="index"
                      :readOnly="isResourceReadOnly" />
                    </template>
                </BadgeGroup>
              </b-form-group>
            </b-col>
            <template v-if="customFieldMap['resources'] != null">
              <b-col v-for="(field, index) in customFieldMap['resources']" :key="'resources'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
            
              <b-col cols="12" md="6" v-if="isFixedCostNetVisible || isFixedCostVisible" class="pr-0">
                <b-form-select class="title-dropdown mb-1" v-model="fixedCostMode">
                  <option v-if="isFixedCostVisible" value="gross">{{ $t('task.field.fixedCost') }}</option>
                  <option v-if="isFixedCostNetVisible" value="net">{{ $t('task.field.fixedCostNet') }}</option>
                </b-form-select>
                <b-form-group>
                  <b-input-group>
                    <b-form-input id="cost" type="number" :min="0.00" :step="1" lazy-formatter
                      v-if="fixedCostMode === 'gross'"
                      :formatter="floatFormatter(null, 0.00)"
                      :data-vv-as="$t('task.field.fixedCost')"
                      data-vv-name="task.fixedCost"
                      data-vv-delay="500"
                      v-model="task.fixedCost"
                      :readonly="isFixedCostReadOnly">
                    </b-form-input>
                    <b-form-input id="costNet" type="number" :min="0.00" :step="1" lazy-formatter
                      v-if="fixedCostMode === 'net'"
                      :formatter="floatFormatter(null, 0.00)"
                      :data-vv-as="$t('task.field.fixedCost')"
                      data-vv-name="task.fixedCost"
                      data-vv-delay="500"
                      v-model="task.fixedCostNet"
                      readonly>
                    </b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <template v-if="customFieldMap['fixedCost'] != null">
                <b-col v-for="(field, index) in customFieldMap['fixedCost']" :key="'fixedCost'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>

              <b-col cols="12" md="6" v-if="isCurrencyCodeVisible" class="pr-0">
                <b-form-group :label="$t('task.field.currencyCode')">
                  <b-input-group>
                    <select class="custom-select" v-model="task.currencyCode" :disabled="isCurrencyCodeReadOnly">
                      <template v-for="(opt, index) in optionCurrency">
                        <option :value="opt.value" :key="index" :style="{ display: opt.num < 0? 'none': 'block' }">{{ opt.text }}</option>
                      </template>
                    </select>
                  </b-input-group>
                  <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showCurrencyError }">
                    <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('task.currencyCode') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <template v-if="customFieldMap['currencyCode'] != null">
                <b-col v-for="(field, index) in customFieldMap['currencyCode']" :key="'currencyCode'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>
            
              <b-col v-if="isEstimatedCostVisible || isEstimatedCostNetVisible" cols="12" md="6" class="pr-0">
                <b-form-select class="title-dropdown mb-1" v-model="estimatedCostMode">
                  <option v-if="isEstimatedCostVisible" value="gross">{{ $t('task.field.estimatedCost') }}</option>
                  <option v-if="isEstimatedCostNetVisible" value="net">{{ $t('task.field.estimatedCostNet') }}</option>
                </b-form-select>
                <b-form-group>
                  <b-input-group>
                    <b-form-input id="estimatedCost"
                      v-if="estimatedCostMode === 'gross'"
                      :value="estimatedCost"
                      readonly>
                    </b-form-input>
                    <b-form-input id="estimatedCostNet"
                      v-if="estimatedCostMode === 'net'"
                      :value="estimatedCostNet"
                      readonly>
                    </b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <template v-if="customFieldMap['estimatedCost'] != null">
                <b-col v-for="(field, index) in customFieldMap['estimatedCost']" :key="'estimatedCost'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>

              <b-col cols="12" md="6" v-if="isActualCostVisible || isActualCostNetVisible" class="pr-0">
                <b-form-select class="title-dropdown mb-1" v-model="actualCostMode">
                  <option v-if="isActualCostVisible" value="gross">{{ $t('task.field.actualCost') }}</option>
                  <option v-if="isActualCostNetVisible" value="net">{{ $t('task.field.actualCostNet') }}</option>
                </b-form-select>
                <b-form-group>
                  <b-input-group>
                    <b-form-input id="actualCost"
                      v-if="actualCostMode === 'gross'"
                      :value="actualCost"
                      :class="actualCostClass"
                      readonly>
                    </b-form-input>
                    <b-form-input id="actualCostNet"
                      v-if="actualCostMode === 'net'"
                      :value="actualCostNet"
                      :class="actualCostClass"
                      readonly>
                    </b-form-input>
                    <b-input-group-append v-if="actualCostAlert">
                      <b-input-group-text id="actualCostAlert" class="work-effort-alert">
                        <font-awesome-icon :icon="['far', 'circle-exclamation']"/>
                      </b-input-group-text>
                      <b-popover target="actualCostAlert" triggers="hover" placement="top">
                        {{ actualCostAlertMsg }}
                      </b-popover>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <template v-if="customFieldMap['actualCost'] != null">
                <b-col v-for="(field, index) in customFieldMap['actualCost']" :key="'actualCost'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>
            
              <b-col cols="12" class="pr-0" v-if="isRebateVisible">
                <b-form-group>
                  <label class="mr-1">{{ $t(`task.field.rebates`) }}</label>
                  <button v-if="!isRebateReadOnly" id="REBATE_ADD" :disabled="isRebateReadOnly" class="btn-action" @click="rebateSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                    <b-popover
                      target="REBATE_ADD"
                      placement="top"
                      triggers="hover"
                      :content="$t('task.button.rebate_add')">
                    </b-popover>
                  </button>

                  <b-form-text class="rebate-total mr-1">
                    {{ $t(`task.field.total_rebate`, [totalRebate]) }}
                  </b-form-text>

                  <BadgeGroup v-model="rebates" :readOnly="isRebateReadOnly">
                    <template v-slot:default="{ item, index }">
                      <Badge @badgeRemove="rebateBadgeRemove(index)" @badgeClick="rebateBadgeClick(item.uuId)"
                        :text="`${item.name} (${formatRebate(item.rebate)}%)`" 
                        variant="primary" 
                        :pillable="!!item.pillable" :key="index"
                        :readOnly="isRebateReadOnly" />
                      </template>
                  </BadgeGroup>
                </b-form-group>
              </b-col>
              <template v-if="customFieldMap['rebates'] != null">
                <b-col v-for="(field, index) in customFieldMap['rebates']" :key="'rebates'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>

              <b-col cols="12" class="pr-0" v-if="isStorageFileVisible">
                <b-form-group>
                  <label class="mr-1">{{ $t(`task.field.files`) }}</label>
                  <button v-if="!isStorageFileReadOnly" id="FILE_ADD" class="btn-action" :disabled="isStorageFileReadOnly" @click="fileSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                    <b-popover
                      target="FILE_ADD"
                      placement="top"
                      triggers="hover"
                      :content="$t('task.button.file_add')">
                    </b-popover>
                  </button>
                  <BadgeGroup v-model="files" :readOnly="isStorageFileReadOnly">
                    <template v-slot:default="{ item, index }">
                      <Badge @badgeRemove="fileBadgeRemove(index)" @badgeClick="fileBadgeClick(item)"
                        :text="labelFilename(item.name, item.type)" 
                        variant="primary" 
                        :pillable="!!item.pillable" :key="index"
                        :readOnly="isStorageFileReadOnly"
                        enableClickWhenReadOnly
                      />
                    </template>
                  </BadgeGroup>
                </b-form-group>
              </b-col>
              <template v-if="customFieldMap['files'] != null">
                <b-col v-for="(field, index) in customFieldMap['files']" :key="'files'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>

              <b-col cols="12" class="pr-0" v-if="isPredecessorVisible">
                <b-form-group>
                  <PredecessorList :predecessors="predecessorData" :readOnly="isPredecessorReadOnly" @change="predecessorChange" @delete="predecessorDelete" :taskId="id" :projectId="projectId" :taskName="task.name" :isTemplate="isTemplate"/>
                </b-form-group>
              </b-col>
              <template v-if="customFieldMap['predecessors'] != null">
                <b-col v-for="(field, index) in customFieldMap['predecessors']" :key="'predecessors'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>

              <b-col cols="12" class="pr-0" v-if="isStorageFileVisible">
                <b-form-group class="mb-0">
                  <label class="mr-1">{{ $t(`task.field.image`) }}</label>
                  <template v-if="!isStorageFileReadOnly">
                    <button id="IMAGE_ADD" class="btn-action" @click="imageSelectorToggle" :disabled="isStorageFileReadOnly" v-if="avatarUrl === null"><font-awesome-icon :icon="['far', 'plus']"/>
                      <b-popover
                        target="IMAGE_ADD"
                        placement="top"
                        triggers="hover"
                        :content="$t('task.button.image_add')">
                      </b-popover>
                    </button>
                    <button class="btn-action" @click="imageRemove" :disabled="isStorageFileReadOnly" v-if="avatarUrl !== null"><font-awesome-icon :icon="['far', 'trash-can']"/></button>
                  </template>
                  <div class="image-preview-container mb-3">
                    <b-img-lazy id="image-preview" class="image-preview" style="object-fit:cover" :src="avatarUrl" v-if="avatarUrl != null" @error.native="handleImgError"/>
                  </div>
                </b-form-group>
              </b-col>
              <template v-if="customFieldMap['image'] != null">
                <b-col v-for="(field, index) in customFieldMap['image']" :key="'image'+index" cols="12" class="pr-0">
                  <b-form-group>
                    <template v-if="field.type !== 'Boolean'" slot="label">
                      <span class="mr-2">{{ field.displayName }}</span>
                      <span v-if="field.description">
                        <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                        <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                          {{ field.description }}
                        </b-popover>  
                      </span>
                    </template>
                    <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                  </b-form-group>
                </b-col>
              </template>
            
            <b-col cols="12" class="pr-0" v-if="isTagVisible">
              <b-form-group>
                <TagList :holderId="id" :readOnly="isTagReadOnly" :tags="tags" @modified="tagsModified" />
              </b-form-group>
            </b-col>
            <template v-if="customFieldMap['tags'] != null">
              <b-col v-for="(field, index) in customFieldMap['tags']" :key="'tags'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
            
            <b-col cols="12" class="pr-0" v-if="isNoteVisible">
              <b-form-group>
                <NoteList :readOnly="isNoteReadOnly" :notes="notes" @add="addNote" @edit="editNote" @toRemove="removeNote" />
              </b-form-group>
            </b-col>
            <template v-if="customFieldMap['notes'] != null">
              <b-col v-for="(field, index) in customFieldMap['notes']" :key="'notes'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="task[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          </b-row>
        </div>
      </template>

      <template v-slot:modal-footer="{ cancel }">
        <b-button v-if="canEdit() && canAdd()" :disabled="task.readOnly" size="sm" variant="secondary" @click="splitTask" style="margin-right: auto">
          {{ $t('button.split_task') }}
        </b-button>
        <template v-if="!isAccessDenied && (canEdit() || !exists)">
          <b-button size="sm" variant="success" disabled v-if="submittedBy == 'ok'">
            <b-spinner small type="grow" />{{ $t('button.saving') }}
          </b-button>
          <b-button size="sm" variant="success" :disabled="disableOk()" @click="handleOkBtnClicked()" v-else>{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
        <template v-if="!isAccessDenied && (canEdit() || !exists)">
          <b-button size="sm" variant="success" disabled v-if="submittedBy == 'apply'">
              <b-spinner small type="grow" />{{ $t('button.saving') }}
            </b-button>
          <b-button size="sm" variant="success" :disabled="disableOk()" v-else @click="handleApplyBtnClicked()">{{ $t('button.apply') }}</b-button>
        </template>
      </template>
    </b-modal>

    <template v-if="exists">
      <GenericHistoryModal v-if="state.historyShow" :show.sync="state.historyShow" :id="id" :entityType="historyEntityType" :customFields="customFields" links="STAGE,REBATE,NOTE,STAFF,PROJECT,RESOURCE,TAG,SKILL,STORAGE_FILE" />
      <NoteModal v-if="state.noteShow" :show.sync="state.noteShow" :note="note" @toAdd="toAddNote" @toUpdate="toUpdateNote"/>
    </template>
    
    <!-- skill selector -->
    <GenericSelectorModalForAdmin v-if="state.skillSelectorShow"
      :show.sync="state.skillSelectorShow" 
      :entityService="skillUtil"
      entity="SKILL"
      nonAdmin
      @ok="skillSelectorOk"
    />

    <SkillLevelModal 
      v-if="state.skillLevelEditShow" 
      :show.sync="state.skillLevelEditShow" 
      :uuId="skillLevelEdit.uuId" 
      :name="skillLevelEdit.name" 
      :level="skillLevelEdit.level" 
      :cData="skillLevelEdit.data"
      :edgeName="isTemplate ? 'TASK_TEMPLATE-SKILL' : 'TASK-SKILL'" 
      @ok="skillLevelOk"
    />
    <template v-for="(item, index) in toConfirmSkills">
      <SkillLevelModal 
        :key="`skill-${index}`" 
        :show.sync="item.show" 
        :canApplyAll="toConfirmSkills.length > 1" 
        :uuId="item.uuId" :name="item.name" 
        :level="item.level" 
        :cData="item"
        :edgeName="isTemplate ? 'TASK_TEMPLATE-SKILL' : 'TASK-SKILL'" 
        @ok="toConfirmSkillOk" 
        @cancel="toConfirmSkillCancel"
      />
    </template>

    <!-- staff selector -->
    <StaffSelectorModalForAdmin v-if="state.staffSelectorShow"
      :show.sync="state.staffSelectorShow" 
      :hideBookings="isTemplate" 
      :projectIds="[projectId]" 
      :companies="project ? project.company : null"
      :taskUuId="exists? id : null"
      nonAdmin
      @ok="staffSelectorOk"
    />

    <StaffWorkEffortModal :readOnly="isReadOnly"
      v-if="state.staffWorkEffortShow" 
      :childTaskEfforts="childTasksActualDuration" 
      :workEfforts="staffs" 
      :show.sync="state.staffWorkEffortShow" 
      @ok="staffWorkEffortOk"/>
    <StaffUtilizationModal 
      v-if="state.staffUtilizationEditShow" 
      :show.sync="state.staffUtilizationEditShow" 
      :uuId="staffUtilizationEdit.uuId" 
      :name="staffUtilizationEdit.name" 
      :utilization="staffUtilizationEdit.utilization" 
      :unit="staffUtilizationEdit.unit" 
      :genericStaff="staffUtilizationEdit.genericStaff" 
      :cData="staffUtilizationEdit.data"
      edgeName="TASK-STAFF" 
      @ok="staffUtilizationOk"
    />
    <template v-for="(item, index) in toConfirmStaffs">
      <StaffUtilizationModal 
        v-if="item.show" 
        :key="`staff-${index}`" 
        :show.sync="item.show" 
        :canApplyAll="toConfirmStaffs.length > 1" 
        :uuId="item.uuId" 
        :name="item.name" 
        :utilization="item.utilization" 
        :unit="item.quantity" 
        :genericStaff="item.genericStaff" 
        :cData="item"
        edgeName="TASK-STAFF" 
        @ok="toConfirmStaffOk" 
        @cancel="toConfirmStaffCancel"
      />
    </template>

    <!-- resource selector -->
    <GenericSelectorModalForAdmin v-if="state.resourceSelectorShow"
      :show.sync="state.resourceSelectorShow" 
      :entityService="resourceUtil"
      :projectIds="[projectId]"
      entity="RESOURCE"
      nonAdmin
      @ok="resourceSelectorOk"
    />

    <ResourceUnitModal 
      v-if="state.resourceUnitEditShow" 
      :show.sync="state.resourceUnitEditShow" 
      :uuId="resourceUnitEdit.uuId" 
      :name="resourceUnitEdit.name" 
      :unit="resourceUnitEdit.unit" 
      :utilization="parseFloat(resourceUnitEdit.utilization)"
      :cData="resourceUnitEdit.data"
      edgeName="TASK-RESOURCE" 
      @ok="resourceUnitOk"
    />
    <template v-for="(item, index) in toConfirmResources">
      <ResourceUnitModal 
        v-if="item.show" 
        :key="`resource-${index}`" 
        :show.sync="item.show" 
        :canApplyAll="toConfirmResources.length > 1" 
        :uuId="item.uuId" 
        :name="item.name" 
        :unit="item.unit" 
        :utilization="parseFloat(item.utilization)" 
        :cData="item"
        edgeName="TASK-RESOURCE" 
        @ok="toConfirmResourceOk" 
        @cancel="toConfirmResourceCancel"
      />
    </template>

    <!-- rebate selector -->
    <GenericSelectorModalForAdmin v-if="state.rebateSelectorShow"
      :show.sync="state.rebateSelectorShow" 
      :entityService="rebateUtil"
      :preselected="rebateEdit.uuId" 
      entity="REBATE"
      nonAdmin
      @ok="rebateSelectorOk"
    />

    <FileSelectorModal v-if="state.fileSelectorShow" :show.sync="state.fileSelectorShow" @ok="fileSelectorOk" :multiple="fileMultiple" :allowedMimeType="fileMimetypes"/>
    <DownloadProgressModal v-if="downloadProgressShow" :show.sync="downloadProgressShow" :downloadPercentage="downloadPercentage" @cancel="downloadCancel"/>
    <TaskDateTimeDurationCalculation :show.sync="state.durationCalculationShow" 
      :taskName="taskName"
      :resizeMode="durationCalculation.resizeMode"
      :defaultActionForNonWorkPrompt="durationCalculation.defaultActionForNonWorkPrompt != null? durationCalculation.defaultActionForNonWorkPrompt : isTemplate? 'move': null"
      :trigger="durationCalculation.trigger"
      :startDateStr="durationCalculation.startDateStr"
      :startTimeStr="durationCalculation.startTimeStr"
      :closeDateStr="durationCalculation.closeDateStr"
      :closeTimeStr="durationCalculation.closeTimeStr"
      :durationDisplay="durationCalculation.durationDisplay"
      :calendar.sync="durationCalculation.calendar"
      :projectScheduleFromStart="durationCalculation.projScheduleFromStart"
      :taskAutoScheduleMode="durationCalculation.taskAutoScheduleMode"
      :constraintType="durationCalculation.constraintType"
      :constraintDateStr="durationCalculation.constraintDateStr"
      :lockDuration="durationCalculation.lockDuration"
      :oldDateStr="durationCalculation.oldDateStr"
      :oldTimeStr="durationCalculation.oldTimeStr"
      :projectStartDateStr="project.startDateStr"
      :projectCloseDateStr="project.closeDateStr"
      :oldConstraintType="durationCalculation.oldConstraintType"
      :oldConstraintDateStr="durationCalculation.oldConstraintDateStr"
      :skipOutOfProjectDateCheck="durationCalculation.skipOutOfProjectDateCheck"
      :enableManualScheduleSuggestion="durationCalculation.enableManualScheduleSuggestion"
      :durationConversionOpts="durationConversionOpts"
      @success="durationCalculationOk"
      @cancel="durationCalculationCancel"
      @skip="durationCalculationCancel"
      @calendarChange="durationCalculationCalendarChange"
    />

    <SplitTaskModal :show.sync="state.splitTaskModalShow"
      :title="$t('button.split_task')"
      :calendar="calendar"
      :duration="duration"
      @success="splitTaskSuccess"
    />

    <b-modal :title="$t('task.confirmation.title_task_changed')"
        v-model="state.alertTaskChangedShow"
        @hidden="state.alertTaskChangedShow=false"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('task.confirmation.task_changed') }}
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="handleApplyBtnClicked(staffSelectorToggle)">{{ $t('button.save') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('task.confirmation.title_change_on_complete')"
        v-model="state.confirmChangeOnCompleteShow"
        @hidden="state.confirmChangeOnCompleteShow=false"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('task.confirmation.change_on_complete') }}
      </div>
      <template v-slot:modal-footer="{}">
        <b-button size="sm" variant="success" @click="ok">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="changeOnCompleteCancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <b-modal :title="$t('project.confirmation.title_apply_rebates')"
        v-model="promptApplyRebates"
        :ok-title="$t('button.yes')"
        :cancel-title="$t('button.no')"
        @ok="applyRebates"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        <p>{{ $t('task.confirmation.apply_rebates') }}</p>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="applyRebates">{{ $t('button.yes') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.no') }}</b-button>
      </template>
    </b-modal>
    
  </div>
</template>

<script>
import { persistNotes } from '@/components/Note/script/crud-util';
import { updateTags } from '@/components/Tag/script/crud-util';
import { updateFiles } from '@/helpers/file';
import { updateSkills } from '@/helpers/skill';
import { updateRebates } from '@/helpers/rebate';
import { updateResources } from '@/helpers/resource';
import { getCustomFieldInfo, customFieldValidate, filterCustomFields, compareKeys } from '@/helpers/custom-fields';
import { cloneDeep, debounce } from 'lodash';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import { removeDeniedProperties } from '@/views/management/script/common';
// import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import { strRandom
  , forceFileDownload, labelFilename, floatFormatter, incrementDuration, maxDuratioUnit, costFormat, costFormatAdv
  , msToTime
  , onDurationKeyDown, onDurationKeyUp 
  , loadViewProfile, updateViewProfile
  , calculateStaffUsage, processSystemCalendar
  , processCalendar
  , transformCalendar
  // , processRegExp
} from '@/helpers';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { taskService, projectService, locationService, templateProjectService, stageService,
  taskLinkSuccessorService, taskLinkFileService, taskLinkSkillService, taskLinkStaffService, taskLinkResourceService,
  templateTaskService, templateTaskLinkSuccessorService, templateTaskLinkFileService, templateTaskLinkSkillService, 
  templateTaskLinkStaffService, templateTaskLinkResourceService, taskLinkStageService, fileService, layoutProfileService, 
  taskLinkRebateService, templateTaskLinkRebateService, calendarService, staffService, templateTaskLinkTagService, taskLinkTagService
} from '@/services';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import currencies from '@/views/management/script/currencies';
import { DEFAULT_CALENDAR, TRIGGERS, getEarliestOrLatestWorkHour, isValidDateStrFormat
  , calcDateTimeDurationv2, convertDurationToDisplay, convertDisplayToDuration, analyzeDurationAUM, extractDurationConversionOpts
  , toFixed, CONSTRAINT_TYPES, WEEKDAY, isWorkingDay
} from '@/helpers/task-duration-process';
import { getAppendAfterObjectWithTopDownRelationship } from '@/components/modal/script/field';
import { rebateUtil } from '@/views/management/script/rebate';
import { resourceUtil } from '@/views/management/script/resource';
import { skillUtil } from '@/views/management/script/skill';

const TASK_TYPE = {
  TASK: 'Task'
  , SUMMARY_TASK: 'Project'
  , MILESTONE: 'Milestone'
}
Object.freeze(TASK_TYPE);

export default {
  name: 'TaskModal',
  components: {
    PredecessorList: () => import('@/components/Task/TaskPredecessorList'),
    Treeselect: () => import('@riophae/vue-treeselect'),
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    SkillLevelModal: () => import('@/components/modal/SkillLevelModal'),
    StaffSelectorModalForAdmin: () => import('@/components/modal/StaffSelectorModalForAdmin'),
    StaffUtilizationModal: () => import('@/components/modal/StaffUtilizationModal'),
    StaffWorkEffortModal: () => import('@/components/modal/StaffWorkEffortModal'),
    ResourceUnitModal: () => import('@/components/modal/ResourceUnitModal'),
    FileSelectorModal: () => import('@/components/modal/FileSelectorModal'),
    DownloadProgressModal: () => import('@/components/modal/DownloadProgressModal'),
    // VueBootstrapTypeahead,
    GenericHistoryModal: () => import('@/components/modal/GenericHistoryModal'),
    TaskDateTimeDurationCalculation: () => import('@/components/Task/TaskDateTimeDurationCalculation'),
    NoteList: () => import('@/components/Note/NoteList.vue'),
    NoteModal: () => import('@/components/modal/NoteModal.vue'),
    TagList: () => import('@/components/Tag/TagList.vue'),
    Color: () => import('@/components/Color/Color.vue'),
    Timepicker: () => import('@/components/Calendar/Timepicker.vue'),
    CustomField: () => import('@/components/CustomField.vue'),
    SplitTaskModal: () => import('@/components/modal/SplitTaskModal'),
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin')
  },
  props: {
    id:        { type: String,   default: `TASK_NEW_${strRandom(5)}` },
    title:     { type: String,   default: null },
    readOnly:  { type: Boolean,  default: false },
    show:      { type: Boolean, required: true },
    projectId: { type: String, default: null },
    projectName: { type: String, default: null },
    parentId:  { type: String, default: 'ROOT' },
    isTemplate: { type: Boolean, default: false },
    stageForNew:    { type: String, default: null },
    progressForNew: { type: Number, default: null },
    taskType: { type: String, default: null, validator: (val) => [null, ...Object.values(TASK_TYPE)].includes(val) },
  },
  data() {
    return {
      permissionName: this.isTemplate ? "TEMPLATE__TASK" : "TASK",
      modelInfo: null,
      alertError: false,
      alertMsg: null,
      alertMsgDetails: [],
      settings: {},
      state: {
        editable:                 false,
        isSubmitting:             false,
        modalShow:                false,
        skillSelectorShow:        false,
        skillLevelEditShow:       false,
        staffSelectorShow:        false,
        staffWorkEffortShow:      false,
        staffUtilizationEditShow: false,
        resourceSelectorShow:     false,
        resourceUnitEditShow:     false,
        fileSelectorShow:         false,
        alertTaskChangedShow:     false,
        selectingImage:           false,
        confirmChangeOnCompleteShow: false,
        historyShow:              false,
        rebateSelectorShow:       false,
        durationCalculationShow:  false,
        noteShow:                 false,
        splitTaskModalShow:   false
      },
      submittedBy: null,
      task: {
        uuId:           null,
        name:           null,
        description:    null,
        durationAUM:    null,
        fixedDuration:  null,
        duration:       null,
        lockDuration:   false,
        priority:       null,
        complexity:     null,
        taskType:       null,
        estimatedCost: 0.0,
        estimatedCostNet: 0.0,
        actualCost: 0.0,
        actualCostNet: 0.0,
        autoScheduling: true,
        progress:       null,
        parent:         null,
        fixedCost:      null,
        fixedCostNet: null,
        currencyCode:   null,
        avatarRef:      null,
        identifier:     null,
        color:          null,
        fullPath:       null,
        readOnly:       false
        
      },
      childTasksActualDuration: 0,
      constraint: {
        type: null,
        date: null
      },
      duration: {
        startDate: null,
        startTime: null,
        closeDate: null,
        closeTime: null,
        value: null
      },
      childStartDate: 0,
      childCloseDate: 0,
      taskList: [
        //{ uuId, name .. }
      ],

      skills: [
        // { uuId: "1", name: "Rigging", level: "Senior" }
      ],
      toConfirmSkills: [],
      skillLevelEdit: {
        uuId:  null,
        name:  null,
        level: null,
      },

      staffs: [
        // { uuId: "1", name: "John", resourceLink: { } }
      ],
      toConfirmStaffs: [],
      staffUtilizationEdit: {
        uuId:  null,
        name:  null,
        utilization: null,
      },

      resources: [
        // { uuId: "1", name: "Desk", unit: 1, utilization: 1.00 }
      ],
      toConfirmResources: [],
      resourceUnitEdit: {
        uuId: null,
        name: null,
        unit: null,
        utilization: null
      },

      stage: null,
      tags: [],
      
      project: {
        uuId: null,
        name: null,
        locationId: null,
        durationAUM: null,
        stageList: [],
        tagList: [],
        scheduleMode: null,
        autoScheduling: null,
        rebateList: [],
        currencyCode: null,
        company: [],
        startDateStr: null,
        closeDateStr: null
      },
      calendar: DEFAULT_CALENDAR,
      optionConstraint: [],
      optionPriority: [],
      optionType: [],
      optionStage: [],
      optionComplexity: [],
      optionCurrency: [],

      predecessorData: [],
      optionTask: [],
      // parentRootId: null,

      notes: [],
      note: {
        uuId: null,
        text: null,
        identifier: null
      },

      files: [],
      downloadProgressShow: false,
      downloadPercentage: 0,
      downloadCancelTokenSource: null,
      downloadCancelled: false,
      
      save_content: false,
      
      taskNames: [],

      etocInput: null,
      
      rebates: [],
      rebateEdit: {
        uuId: null,
        name: null,
        rebate: null
      },
      actualCostMode: 'gross',
      fixedCostMode: 'gross',
      estimatedCostMode: 'gross',

      durationCalculation: {
        trigger: TRIGGERS.START_DATE
        , startDateStr: null
        , startTimeStr: null
        , closeDateStr: null
        , closeTimeStr: null
        , durationDisplay: null
        , calendar: DEFAULT_CALENDAR
        , projScheduleFromStart: true
        , taskAutoScheduleMode: true
        , constraintType: null
        , constraintDateStr: null
        , oldDateStr: null
        , oldTimeStr: null
        , lockDuration: false
        , skipOutOfProjectDateCheck: false
      },
      staffUsage: {},
      updatedColor: null,

      dateTimeCalcInputTrigger: null,
      dateTimeCalcChangeTrigger: null,

      isAccessDenied: false,
      
      layoutProfile: null,
      promptApplyRebates: false,
      
      customFields: [],
      predecessorCustomFields: [],
      customFieldMap: {},

      //Fixed #2036
      calendarType: {
        holderId: null,
        type: null, //possible value: ['system','project','staff']
      },

      projectCalendar: null,
      systemCalendar: null,
      hasSplit: false,

      restrictedRequiredField: null,
      durationConversionOpts: {}
    }
  },
  created() {
    this.durationGroup = ['startTime','closeTime','duration','durationAUM','lockDuration'
      ,'constraintTime','constraintType','autoScheduling']
    this.durationViewGroup = ['startTime','closeTime','estimatedDuration','durationAUM','lockDuration'
      ,'constraintTime','constraintType','autoScheduling']
    this.getModelInfo();
    this.fieldValidateUtil = fieldValidateUtil;
    this.original = {
      predecessors: []
      , skills: []
      , staffs: []
      , resources: []
      , files: []
      , stage: null
      , startTime: null
      , closeTime: null
      , progress: null
      , constraintDate: null
      , constraintType: null
      , rebates: []
      , description: null
      , task: null
      , readOnly: false
    }
    this.rebateUtil = rebateUtil;
    this.resourceUtil = resourceUtil;
    this.skillUtil = skillUtil;

    if (this.show) {
      this.processWhenModalShown()
    }

    this.floatFormatter = floatFormatter;
    this.prevStartDateStr = null;
    this.prevStartTimeStr = null;
    this.prevCloseDateStr = null;
    this.prevCloseTimeStr = null;
    this.prevConstraintType = null;
    this.prevConstraintDateStr = null;
    this.parentCandidateList = null;
    this.originNotes = [];
    this.originTags = [];
    this.isDurationCalculationInProgress = false;
    this.btnApplyCallback = null;
  },
  mounted() {
    // this.state.editable =  (!this.exists && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    this.floatFormatter = null;
    this.original = null;
    this.prevStartDateStr = null;
    this.prevStartTimeStr = null;
    this.prevCloseDateStr = null;
    this.prevCloseTimeStr = null;
    this.prevConstraintType = null;
    this.prevConstraintDateStr = null;
    this.parentCandidateList = null;
    this.originNotes = null;
    this.originTags = null;
    this.durationGroup = null;
    this.rebateUtil = null;
    this.resourceUtil = null;
    this.skillUtil = null;
  },
  computed: {
    componentId() {
      return `TASK_FORM_${this.id}`;
    },
    customFieldsFiltered() {
      return this.customFields.filter(f => this.canView(this.permissionName, [f.name]) && ((!this.exists && this.canAdd(this.permissionName, [f.name]))
      || this.exists));
    },
    isReadOnly() {
      return !this.state.editable || this.readOnly || this.task.readOnly;
    },
    showError() {
      return this.alertMsg != null;
    },
    showErrorDetail() {
      return this.alertMsgDetails != null && this.alertMsgDetails.length > 0;
    },
    showParentError() {
      return fieldValidateUtil.hasError(this.errors, 'task.parent');
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'task.name');
    },
    showDescriptionError() {
      return fieldValidateUtil.hasError(this.errors, 'task.description');
    },
    showDurationError() {
      return fieldValidateUtil.hasError(this.errors, 'duration.value');
    },
    showFixedDurationError() {
      return fieldValidateUtil.hasError(this.errors, 'fixedDuration');
    },
    showCurrencyError() {
      return fieldValidateUtil.hasError(this.errors, 'task.currencyCode');
    },
    showConstraintDateError() {
      return fieldValidateUtil.hasError(this.errors, 'constraint.date');
    },
    exists() {
      return this.id && !this.id.startsWith('TASK_NEW_');
    },
    labelTitle() {
      return this.title? this.title : (this.exists? this.$t('task.title_detail') : this.$t('task.title_new'));
    },
    disableAutoSchedule() {
      return this.task.taskType === TASK_TYPE.SUMMARY_TASK;
    },
    disableConstraint() {
      return this.task.taskType === TASK_TYPE.SUMMARY_TASK || this.task.autoScheduling != true;
    },
    disableEtc() {
      return this.task.taskType === TASK_TYPE.SUMMARY_TASK;
    },
    disableConstraintDate() {
      return this.task.taskType === TASK_TYPE.SUMMARY_TASK || this.constraint.type === 'As_late_as_possible' || this.constraint.type === 'As_soon_as_possible' || this.task.autoScheduling != true;
    },
    disableDuration() {
      return this.task.taskType === TASK_TYPE.MILESTONE || this.task.taskType === TASK_TYPE.SUMMARY_TASK;
    },
    disableFixedDuration() {
      return this.task.taskType === TASK_TYPE.MILESTONE || (this.exists && !this.canEdit(this.permissionName, ['fixedDuration']));
    },
    disableCloseDate() {
      return this.task.taskType === TASK_TYPE.MILESTONE || this.task.taskType === TASK_TYPE.SUMMARY_TASK;
    },
    disableStartDate() {
      return this.task.taskType === TASK_TYPE.SUMMARY_TASK;
    },
    disableProgress() {
      return this.task.taskType === TASK_TYPE.SUMMARY_TASK;
    },
    disableWorkEffort() {
      return this.staffs.length == 0;
    },
    disableStage() {
      return this.task.taskType === TASK_TYPE.SUMMARY_TASK || this.optionStage == null || this.optionStage.length == 0;
    },
    skillsChanged() {
      const originSkills = this.original.skills;
      const skills = this.skills;
      const origLen = originSkills? originSkills.length : 0;
      const len = skills? skills.length: 0;
      if (origLen != len && origLen < 1) {
        return true;
      } else if(origLen == len && origLen < 1) {
        return false;
      } else if(origLen != len) {
        return true;
      }
      let index, curSkill;
      for(const origSkill of originSkills) {
        index = skills.findIndex(i => i.uuId === origSkill.uuId);
        if (index > -1) {
          curSkill = skills[index];
          if(curSkill.level != origSkill.level) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    },
    maxNameLength() {
      var values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "name";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    maxIdentifierLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "identifier";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    fileMimetypes() {
      if (this.state.selectingImage) {
        return ['image/'];
      }
      else {
        return [];
      }
    },
    fileMultiple() {
      if (this.state.selectingImage) {
        return false;
      }
      else {
        return true;
      }
    },
    avatarUrl() {
      return this.task.avatarRef && this.task.avatarRef !== "00000000-0000-0000-0000-000000000000" ? `${process.env.BASE_URL}api/file/${this.task.avatarRef}`: null;
    },
    totalWorkEffort() {
      let total = 0;
      let unit = 'D';
      for(const item of this.staffs) {
        total += item.duration || 0;
        const { unit: itemUnit } = analyzeDurationAUM(item.durationAUM);
        unit = maxDuratioUnit(unit, itemUnit || 'm');
      }
      return convertDurationToDisplay(total + this.childTasksActualDuration, unit, this.durationConversionOpts);
    },
    estimatedTimeToComplete() {
      const duration = convertDisplayToDuration(this.duration.value, this.durationConversionOpts);
      const actualDuration = convertDisplayToDuration(this.totalWorkEffort, this.durationConversionOpts);
      if (actualDuration.value < duration.value) {
        return convertDurationToDisplay(duration.value - actualDuration.value, 'D', this.durationConversionOpts);
      }
      else {
        return '0D';
      }
    },
    estimatedCost() {
      return this.task.currencyCode == null || this.task.currencyCode.trim().length == 0? `$${costFormat(this.task.estimatedCost)}` : costFormatAdv(this.task.estimatedCost, this.task.currencyCode);
    },
    estimatedCostNet() {
      return this.task.currencyCode == null || this.task.currencyCode.trim().length == 0? `$${costFormat(this.task.estimatedCostNet)}` : costFormatAdv(this.task.estimatedCostNet, this.task.currencyCode);
    },
    actualCost() {
      return this.task.currencyCode == null || this.task.currencyCode.trim().length == 0? `$${costFormat(this.task.actualCost)}` : costFormatAdv(this.task.actualCost, this.task.currencyCode);
    },
    actualCostNet() {
      return this.task.currencyCode == null || this.task.currencyCode.trim().length == 0? `$${costFormat(this.task.actualCostNet)}` : costFormatAdv(this.task.actualCostNet, this.task.currencyCode);
    },
    actualCostAlert() {
      if(this.task && this.task.actualCost != null) {
        const fixedCost = this.task.fixedCost > 0? this.task.fixedCost : 0;
        if(this.task.actualCost > fixedCost && fixedCost !== 0) {
          return true;
        } else if(this.task.estimatedCost && this.task.actualCost > this.task.estimatedCost) {
          return true;
        }
      }
      return false;
    },
    actualCostAlertMsg() {
      if(this.task && this.task.actualCost != null) {
        const fixedCost = this.task.fixedCost ? this.task.fixedCost : 0;
        const isCurrencyCodeValid = this.task.currencyCode != null && this.task.currencyCode.trim().length > 0;
        if(this.task.actualCost > fixedCost && fixedCost !== 0) {
          const diff = isCurrencyCodeValid? costFormatAdv(this.task.actualCost - fixedCost, this.task.currencyCode) : `$${costFormat(this.task.actualCost - fixedCost)}`;
          return this.$t('task.alert.actual_cost_exceeds_fixed_cost', [diff]);
        } else if(this.task.estimatedCost && this.task.actualCost > this.task.estimatedCost) {
          const diff = isCurrencyCodeValid? costFormatAdv(this.task.actualCost - this.task.estimatedCost, this.task.currencyCode) :  `$${costFormat(this.task.actualCost - this.task.estimatedCost)}`;
          return this.$t('task.alert.actual_cost_exceeds_estimated_cost', [diff]);
        }
      }
      return '';
    },
    fixedDurationAlertMsg() {
      if(this.task && this.task.fixedDuration != null) {
        const { value: dValue } = convertDisplayToDuration(this.duration.value, this.durationConversionOpts);
        const { value: fixedDuration } = convertDisplayToDuration(this.task.fixedDuration, this.durationConversionOpts);
        const { value: actualDuration } = convertDisplayToDuration(this.totalWorkEffort, this.durationConversionOpts);
        if(actualDuration > fixedDuration) {
          const diff = convertDurationToDisplay(actualDuration - fixedDuration, 'D', this.durationConversionOpts);
          return this.$t('task.alert.actual_duration_exceeds_fixed_duration', [diff]);
        } else if(dValue > fixedDuration) {
          const diff = convertDurationToDisplay(dValue - fixedDuration, 'D', this.durationConversionOpts);
          return this.$t('task.alert.estimated_duration_exceeds_fixed_duration', [diff]);
        }
      }
      return '';
    },
    workEffortAlertMsg() {
      return this.$t('task.alert.work_effort_exceeds_estimated_duration');
    },
    workEffortAlert() {
      const { value: dValue } = convertDisplayToDuration(this.duration.value, this.durationConversionOpts);
      const { value: tWorkEffort } = convertDisplayToDuration(this.totalWorkEffort, this.durationConversionOpts);
      return tWorkEffort > dValue;
    },
    fixedDurationAlert() {
      if (this.task.fixedDuration && !['0','0Y','0M','0W','0D','0h','0m'].includes(this.task.fixedDuration)) {
        const { value: dValue } = convertDisplayToDuration(this.duration.value, this.durationConversionOpts);
        const { value: fixedDuration } = convertDisplayToDuration(this.task.fixedDuration, this.durationConversionOpts);
        const { value: actualDuration } = convertDisplayToDuration(this.totalWorkEffort, this.durationConversionOpts);
        return fixedDuration < dValue || fixedDuration < actualDuration;
      }
      return false;
    },
    historyEntityType() {
      return this.isTemplate? "TEMPLATE/TASK" : "TASK";
    },
    actualCostClass() {
      if ((this.task.fixedCost && this.task.actualCost > this.task.fixedCost) ||
          (this.task.estimatedCost < this.task.actualCost)) {
        return 'colorRed';
      }
      return '';
    },
    workEffortClass() {
      return { 
        colorRed: this.workEffortAlert,
        'field-readonly-no-color-filter': !this.disableWorkEffort && !this.isReadOnly
      }
    },
    fixedDurationClass() {
      return { 
        colorRed: this.fixedDurationAlert,
        'field-readonly-no-color-filter': !this.disableFixedDuration && !this.isReadOnly
      }
    },
    totalRebate() {
      var total = 0;
      for (const rebate of this.rebates) {
        total += rebate.rebate;
      }
      return toFixed(total*100, 2);
    },
    taskName() {
      if (this.task == null || this.task.name == null || this.task.name.trim().length == 0) {
        return '[unnamed]';
      }
      return this.task.name;
    },
    isNameVisible() {
      //Name is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['name'])
    },
    isNameReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['name']))
    },
    isIdentifierVisible() {
      return this.canView(this.permissionName, ['identifier']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['identifier'])) || this.exists)
    },
    isIdentifierReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['identifier']))
    },
    isColorVisible() {
      return this.canView(this.permissionName, ['color']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['color'])) || this.exists)
    },
    isColorReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['color']))
    },
    isLocationVisible() {
      //Link creation requires main entity's edit permission
      return this.canView('LOCATION') && this.canView(this.permissionName, ['LOCATION']) 
      && ((!this.exists && this.canEdit(this.permissionName, ['LOCATION'])) || this.exists)
    },
    isLocationReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['LOCATION'])
    },
    isTagVisible() {
      //Tag field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('TAG') && this.canView(this.permissionName, ['TAG'])
    },
    isTagReadOnly() {
      return this.isReadOnly || !this.canAdd('TAG') || !this.canEdit('TAG') || !this.canEdit(this.permissionName, ['TAG'])
    },
    isNoteVisible() {
      //Note field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('NOTE') && this.canView(this.permissionName, ['NOTE'])
    },
    isNoteReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['NOTE'])
    },
    isStorageFileVisible() {
      //Link creation to check against canEdit()
      return this.canView(this.permissionName, ['STORAGE_FILE']) 
      && ((!this.exists && this.canEdit(this.permissionName, ['STORAGE_FILE'])) || this.exists)
    },
    isStorageFileReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['STORAGE_FILE']))
    },
    isDescriptionVisible() {
      return this.canView(this.permissionName, ['description'])
      && ((!this.exists && this.canAdd(this.permissionName, ['description'])) || this.exists)
    },
    isDescriptionReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['description']))
    },
    isParentVisible() {
      return this.canView('PROJECT', ['TASK']) && this.canView(this.permissionName, ['TASK']) 
      && ((!this.exists && this.canEdit('PROJECT', ['TASK']) && this.canEdit(this.permissionName, ['TASK'])) || this.exists)
    },
    isParentReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit('PROJECT', ['TASK'] && !this.canEdit(this.permissionName, ['TASK'])))
    },
    isDurationGroupReadOnly() {
      return this.isReadOnly || this.task.taskType == TASK_TYPE.SUMMARY_TASK ||
        (!this.canView(this.permissionName, this.durationViewGroup) 
          || (this.exists && !this.canEdit(this.permissionName, this.durationGroup)) 
          || (!this.exists && !this.canAdd(this.permissionName, this.durationGroup))
        )
    },
    isStartTimeVisible() {
      return this.canView(this.permissionName, ['startTime']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['startTime'])) || this.exists)
    },
    isStartTimeReadOnly() {
      return this.isDurationGroupReadOnly
    },
    isCloseTimeVisible() {
      return this.canView(this.permissionName, ['closeTime']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['closeTime'])) || this.exists)
    },
    isCloseTimeReadOnly() {
      return this.task.taskType == 'Milestone' || this.isDurationGroupReadOnly
    },
    isDurationVisible() {
      return this.canView(this.permissionName, ['estimatedDuration']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['duration'])) || this.exists)
    },
    isDurationReadOnly() {
      return this.task.taskType == 'Milestone' || this.isDurationGroupReadOnly
    },
    isFixedDurationVisible() {
      return this.canView(this.permissionName, ['fixedDuration']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['fixedDuration'])) || this.exists)
    },
    isFixedDurationReadOnly() {
      return this.task.taskType == 'Milestone'  ||
        (!this.canView(this.permissionName, ['fixedDuration']) 
          || (this.exists && !this.canEdit(this.permissionName, ['fixedDuration'])) 
          || (!this.exists && !this.canAdd(this.permissionName, ['fixedDuration']))
        )
        || this.task.readOnly
    },
    isTaskTypeVisible() {
      //TaskType is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['taskType'])
    },
    isTaskTypeReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['taskType']))
    },
    isPriorityVisible() {
      return this.canView(this.permissionName, ['priority']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['priority'])) || this.exists)
    },
    isPriorityReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['priority']))
    },
    isComplexityVisible() {
      return this.canView(this.permissionName, ['complexity']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['complexity'])) || this.exists)
    },
    isComplexityReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['complexity']))
    },
    isActualDurationVisible() {
      return !this.isTemplate && this.isStaffVisible && this.exists && this.canView(this.permissionName, ['actualDuration'])
    },
    isActualDurationReadOnly() {
      return this.isReadOnly || this.isDurationGroupReadOnly
    },
    isETCVisible() {
      return !this.isTemplate && this.task.taskType != TASK_TYPE.SUMMARY_TASK && this.canView(this.permissionName, ['estimatedTimeToComplete']) && this.isDurationVisible && this.isActualDurationVisible
    },
    isETCReadOnly() {
      return this.task.taskType == TASK_TYPE.MILESTONE || this.isDurationGroupReadOnly
    },
    isProgressVisible() {
      return !this.isTemplate && this.canView(this.permissionName, ['progress'])
      && ((!this.exists && this.canAdd(this.permissionName, ['progress'])) || this.exists)
    },
    isProgressReadOnly() {
      return this.isReadOnly || this.task.taskType == TASK_TYPE.SUMMARY_TASK || (this.exists && !this.canEdit(this.permissionName, ['progress']))
    },
    isAutoSchedulingVisible() {
      return this.canView(this.permissionName, ['autoScheduling']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['autoScheduling'])) || this.exists)
    },
    isAutoSchedulingReadOnly() {
      return this.task.taskType == TASK_TYPE.SUMMARY_TASK || this.isDurationGroupReadOnly
    },
    isStageVisible() {
      return !this.isTemplate && this.canView('STAGE') && this.canView(this.permissionName, ['STAGE'])
      && ((!this.exists && this.canEdit(this.permissionName, ['STAGE'])) || this.exists)
    },
    isStageReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['STAGE'])) 
      || this.optionStage == null || this.optionStage.length == 0
    },
    isConstraintVisible() {
      return this.canView(this.permissionName, ['constraintType','constraintTime']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['constraintType','constraintTime'])) || this.exists)
    },
    isConstraintReadOnly() {
      return this.isDurationGroupReadOnly || this.task.taskType === TASK_TYPE.SUMMARY_TASK || this.task.autoScheduling != true
    },
    isSkillVisible() {
      //Link creation requires main entity's edit permission
      return this.canView('SKILL') && this.canView(this.permissionName, ['SKILL']) 
      && ((!this.exists && this.canEdit(this.permissionName, ['SKILL'])) || this.exists)
    },
    isSkillReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['SKILL'])
    },
    isStaffVisible() {
      //Link creation requires main entity's edit permission
      return this.canView('STAFF') && this.canView(this.permissionName, ['STAFF']) 
      && ((!this.exists && this.canEdit(this.permissionName, ['STAFF'])) || this.exists)
    },
    isStaffReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['STAFF'])
    },
    isResourceVisible() {
      //Link creation requires main entity's edit permission
      return this.canView('RESOURCE') && this.canView(this.permissionName, ['RESOURCE']) 
      && ((!this.exists && this.canEdit(this.permissionName, ['RESOURCE'])) || this.exists)
    },
    isResourceReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['RESOURCE'])
    },
    isFixedCostVisible() {
      return this.canView(this.permissionName, ['fixedCost'])
      && ((!this.exists && this.canAdd(this.permissionName, ['fixedCost'])) || this.exists)
    },
    isFixedCostNetVisible() {
      return this.canView(this.permissionName, ['fixedCostNet'])
      && this.exists
    },
    isFixedCostReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['fixedCost']))
    },
    isCurrencyCodeVisible() {
      return this.canView(this.permissionName, ['currencyCode'])
      && ((!this.exists && this.canAdd(this.permissionName, ['currencyCode'])) || this.exists)
    },
    isCurrencyCodeReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['currency']))
    },
    isEstimatedCostVisible() {
      return this.canView(this.permissionName, ['estimatedCost'])
      && this.exists
    },
    isEstimatedCostNetVisible() {
      return this.canView(this.permissionName, ['estimatedCostNet'])
      && this.exists
    },
    isActualCostVisible() {
      return !this.isTemplate && this.canView(this.permissionName, ['actualCost'])
      && this.exists
    },
    isActualCostNetVisible() {
      return !this.isTemplate && this.canView(this.permissionName, ['actualCostNet'])
      && this.exists
    },
    isRebateVisible() {
      //Link creation requires main entity's edit permission
      return this.canView('REBATE') && this.canView(this.permissionName, ['REBATE']) 
      && ((!this.exists && this.canEdit(this.permissionName, ['REBATE'])) || this.exists)
    },
    isRebateReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['REBATE'])
    },
    isPredecessorVisible() {
      return this.exists && this.canView(this.permissionName, ['TASK'])
    },
    isPredecessorReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['TASK'])
    },
    isLockVisible() {
      return this.canView(this.permissionName, ['readOnly'])
      && ((!this.exists && this.canAdd(this.permissionName, ['readOnly'])) || this.exists)
    },
    isLockReadOnly() {
      return !this.state.editable || this.readOnly || (this.exists && !this.canEdit(this.permissionName, ['readOnly']))
    }
  },
  watch: {
    async show(newValue) {
      if (newValue != this.state.modalShow) {
        this.state.modalShow = newValue;
        this.state.noteShow = false;
        this.originNotes = [];
        this.notes = [];
        this.alertMsg = null;
        this.alertError = false;
        this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
        this.submittedBy = null;
        this.resetProperties();
        this.state.editable =  (!this.exists && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
        this.actualCostMode = this.isActualCostVisible? 'gross' : 'net';
        this.fixedCostMode = this.isFixedCostVisible? 'gross' : 'net';
        this.estimatedCostMode = this.isEstimatedCostVisible? 'gross' : 'net';
        this.restrictedRequiredField = null;
        if (newValue) {
          this.processWhenModalShown()
        }
      }
    },
    projectId(newValue) {
      this.project.name = null;
      this.project.uuId = null;
      if(newValue != null) {
        this.projectGet(newValue);
      }
    },
    estimatedTimeToComplete(newValue) {
      if(this.etocInput != newValue) {
        this.etocInput = newValue || '0D';
      }
    },
    optionTask() {
      //Set the startDate and startTime with parent's startDate and startTime when task is not existing task.
      //Priority: immediate Parent > grandParent > ... > ROOT (Project startDate).
      //Explanation: Use parent's startDate (if parent's startDate is not empty). If parent's startDate is empty, use grandparent's startDate... and repeat up to the ROOT (project's startDate)
      //             If Project's startDate is empty, today date will be the default value. 
      //             Today date is default value set in resetProperties().
      if (!this.exists && this.task.parent != null && this.task.parent != 'ROOT') {
        const pUuId = this.task.parent;
        const reversedList = (cloneDeep(this.parentCandidateList)).reverse();
        const index = reversedList.findIndex(i => i.id === pUuId);
        let foundParent = null;
        if (index > -1) {
          for (let i = index, len = reversedList.length; i < len; i++) {
            const curItem = reversedList[i];
            if (curItem.startDate != null && curItem.startDate != 0) {
              foundParent = curItem;
              break;
            }
          }
        }

        if (foundParent != null) {
          const sDate = moment.utc(foundParent.startDate);
          this.$set(this.duration, 'startDate', sDate.format('YYYY-MM-DD'))
          if (foundParent.id == 'ROOT') { //Use project startDate and earlier workhour of the day.
            const todayWeekDay = this.getTodayWeekDay(sDate);
            //Set the earliest work hour for a new task's start time when calendar is valid
            const cal = this.calendar;
            if(cal != null && cal[todayWeekDay] != null && cal[todayWeekDay].length > 0) {
              const weekDayHours = cal[todayWeekDay];
              const earliestHour = weekDayHours.reduce((earliestHour, currentValue) => {
                if(earliestHour == null || earliestHour.trim().length < 5) {
                  return currentValue.startHour;
                }
                if(earliestHour > currentValue.startHour) {
                  return currentValue.startHour;
                } else {
                  return earliestHour;
                }
              }, null);
              this.$set(this.duration, 'startTime', earliestHour);
            } else {
              //Use the current local time when calendar is invalid.
              const { timeStr } = this.getTodayLocalDate();
              this.$set(this.duration, 'startTime', timeStr);
            }
          } else {
            //Use parent startTime
            this.$set(this.duration, 'startTime', sDate.format('HH:mm'));
          }
        }
      }
    },
    staffs(newValue) {
      //Fix the glitch of popover stuck in displaying state when disabling the popover during displaying state.
      if (newValue != null && newValue.length > 0) {
        this.$root.$emit('bv::hide::popover', 'taskActualDurationDiv')
      }
    },
    tags(newValue) {
      if (newValue != null) {
        this.populateTaskStage();
      }
    }
  },
  methods: {
    disableOk() {
      return (this.original.readOnly && this.task.readOnly) || this.state.isSubmitting;
    },
    async processWhenModalShown() {
      // if (!(this.canView('PROJECT', ['TASK']) 
      //       && this.canView(this.permissionName, ['name', 'taskType', 'TASK', ...this.durationGroup])
      // )) {
      //   this.isAccessDenied = true;
      //   return;
      // } else {
      //   this.isAccessDenied = false;
      // }

      const requests = [
        this.getDurationConversionOpts(),
        getCustomFieldInfo(this, 'TASK').catch(e => this.httpAjaxError(e))
        , getCustomFieldInfo(this, 'PLAN_LINK', null, { customFieldsPropName: 'predecessorCustomFields' }).catch(e => this.httpAjaxError(e))
      ];
      await Promise.allSettled(requests);
      
      this.$validator.resume();
      if (this.customFields.length == 0) {
        this.customFieldMap = {};
      } else {
        this.customFieldMap = getAppendAfterObjectWithTopDownRelationship(this.customFields, this.allowViewFunc);
      }

      const requiredFields = ['name', 'taskType', 'TASK', ...this.durationGroup]
      const requiredCustomFields = this.customFields.filter(i => i.notNull == true).map(i => i.name);
      if (requiredCustomFields.length > 0) {
        requiredFields.push(...requiredCustomFields);
      }
      let result = this.canView2(this.permissionName, requiredFields);
      if (result.val) {
        result = this.canAdd2(this.permissionName, requiredFields)
      } 

      if (result.val && !this.canView('PROJECT', ['TASK'])) {
        result =  { val: false, restrictedProp: 'PROJECT' }
      }
      
      if (result.restrictedProp != null) {
        this.restrictedRequiredField = this.getDisplayNameOfProperty(result.restrictedProp);
      }

      if (result.val) {
        this.isAccessDenied = false;
      } else {
        this.isAccessDenied = true;
      }
    
   
      // if (!(this.canView('PROJECT', ['TASK']) 
      //       && this.canView(this.permissionName, ['name', 'taskType', 'TASK', ...this.durationGroup])
      // )) {
      //   this.isAccessDenied = true;
      //   return;
      // } else {
      //   this.isAccessDenied = false;
      // }
      
      this.populateTaskConstraint();
      this.populateTaskType();
      this.populateTaskTree();
      
      const projectLinks = ['LOCATION', 'STAGE_LIST', 'COMPANY', 'REBATE'];
      const taskLinks = ['TASK', 'STAGE', 'SKILL', 'STAFF', 'REBATE', 'RESOURCE', 'STORAGE_FILE', 'NOTE', 'TAG'];
      const projectId = this.projectId
      const taskId = this.id
      
      if(this.exists) {
        this.isAccessDenied = false;
        const requests = [];
        requests.push(this.getEnumList());
        requests.push(loadViewProfile(this.settings, this.$store.state.authentication.user.uuId));
        if (projectId) {
          requests.push(this.isTemplate? templateProjectService.get([{ uuId: projectId }], projectLinks) : projectService.get([{ uuId: projectId }], projectLinks));
        }
        requests.push(this.isTemplate? templateTaskService.get([{ uuId: taskId }], taskLinks) : taskService.get([{ uuId: taskId }], taskLinks))
        
        await Promise.allSettled(requests)
        .then(responses => {
          const loadViewProfileResponse = responses[1];
          const projectResponse = responses[2];
          const taskResponse = responses[projectId ? 3 : 2];
          if (loadViewProfileResponse.status == 'fulfilled') {
            this.settings = loadViewProfileResponse.value;
          } else if (projectResponse.reason != null && 
                    projectResponse.reason.response != null && 
                    projectResponse.reason.response.status == 403) {
            this.isAccessDenied = true;
            return
          }

          if (projectId) {
            if (projectResponse.status == 'fulfilled') {
              const projectData = projectResponse.value.data[projectResponse.value.data.jobCase] || [];
              if(projectData.length > 0) {
                const redactedProperties = projectData.redacted != null && projectData.redacted.length > 0? projectData.redacted : [];
                this.checkProjectRedactedProps(redactedProperties);
                if (this.isAccessDenied) {
                  return;
                }
                this.digestProjectResponse(projectData[0]);
              } else if(this.isTemplate) {
                console.error('Provided template project\'s uuId is invalid.'); // eslint-disable-line no-console
              } else {
                console.error('Provided project\'s uuId is invalid. Without project, task duration calculation logic can\'t work properly'); // eslint-disable-line no-console
              }
            } else if (projectResponse.reason != null && 
                      projectResponse.reason.response != null && 
                      projectResponse.reason.response.status == 403) {
              this.isAccessDenied = true;
              return
            }
          }
                
          if (taskResponse.status == 'fulfilled') {
            const taskData = taskResponse.value.data[taskResponse.value.data.jobCase] || [];
            if(taskData.length > 0) {
              if (!projectId && taskData[0].projectRef) {
                const pService = this.isTemplate? templateProjectService : projectService;
                pService.get([{ uuId: taskData[0].projectRef }], projectLinks).then(projectRes => {
                  const projectData = projectRes.data[projectRes.data.jobCase] || [];
                  if(projectData.length > 0) {
                    const redactedProperties = projectData.redacted != null && projectData.redacted.length > 0? projectData.redacted : [];
                    this.checkProjectRedactedProps(redactedProperties);
                    if (this.isAccessDenied) {
                      return;
                    }
                    this.digestProjectResponse(projectData[0]);
                  }
                });
              }
              this.digestResponse(taskData[0]);
            }
          } else if (taskResponse.reason != null && 
                    taskResponse.reason.response != null && 
                    taskResponse.reason.response.status == 403) {
            this.isAccessDenied = true
            return
          }
        })
        .catch(e => {
          this.httpAjaxError(e);
        });
        
        this.taskPredecessorList(taskId);
      } else if (projectId !== null) {
        await Promise.allSettled([
          this.getEnumList(),
          layoutProfileService.list(projectId, this.$store.state.authentication.user.uuId),
          loadViewProfile(this.settings, this.$store.state.authentication.user.uuId),
          this.isTemplate? templateProjectService.get([{ uuId: projectId }], projectLinks) : projectService.get([{ uuId: projectId }], projectLinks),
        ])
        .then(responses => {
          const layoutProfileResponse = responses[1];
          const loadViewProfileResponse = responses[2];
          const projectResponse = responses[3];

          const func = () => {
            if (loadViewProfileResponse.status == 'fulfilled') {
              this.settings = loadViewProfileResponse.value;
            } else if (projectResponse.reason != null && 
                      projectResponse.reason.response != null && 
                      projectResponse.reason.response.status == 403) {
              this.isAccessDenied = true;
              return
            }

            if (projectResponse.status == 'fulfilled') {
              const projectData = projectResponse.value.data[projectResponse.value.data.jobCase] || [];
              if(projectData.length > 0) {
                const redactedProperties = projectData.redacted != null && projectData.redacted.length > 0? projectData.redacted : [];
                this.checkProjectRedactedProps(redactedProperties);
                if (this.isAccessDenied) {
                  return;
                }
                this.digestProjectResponse(projectData[0]);
              } else if(this.isTemplate) {
                console.error('Provided template project\'s uuId is invalid.'); // eslint-disable-line no-console
              } else {
                console.error('Provided project\'s uuId is invalid. Without project, task duration calculation logic can\'t work properly'); // eslint-disable-line no-console
              }
            } else if (projectResponse.reason != null && 
                      projectResponse.reason.response != null && 
                      projectResponse.reason.response.status == 403) {
              this.isAccessDenied = true;
              return
            }

            

            if (this.stageForNew) {
              this.stage = this.stageForNew;
            }
            else if (this.layoutProfile.stage) {
              this.stage = this.layoutProfile.stage;
            }
            
            if (this.layoutProfile.priority) {
              this.task.priority = this.layoutProfile.priority;
            }
            
            if (this.layoutProfile.complexity) {
              this.task.complexity = this.layoutProfile.complexity;
            }
            
            if (this.progressForNew) {
              this.task.progress = this.progressForNew;
            }
            
            if (this.project.scheduleMode == 'ASAP') {
              this.constraint.type = 'As_soon_as_possible';
            } else if (this.project.scheduleMode == 'ALAP') {
              this.constraint.type = 'As_late_as_possible';
            }
            
            // New tasks adopt the project rebates
            if (this.project.rebateList) {
              this.rebates = JSON.parse(JSON.stringify(this.project.rebateList));
            }

            if (this.project.autoScheduling != null) {
              this.task.autoScheduling = this.project.autoScheduling
            }

            if (this.settings.currency && this.optionCurrency.find(i => i.value == this.settings.currency && i.num > -1)) {
              this.task.currencyCode = this.settings.currency
            } else {
              this.task.currencyCode = this.project.currencyCode != null? this.project.currencyCode : null
            }

            this.task.parent = this.parentId || 'ROOT';
          }

          if (layoutProfileResponse.status == 'fulfilled') {
            const response = layoutProfileResponse.value
            const profileData = response.data[response.data.jobCase];
            if (profileData.length === 0) {
              return this.createLayoutProfile().
              then(() => {
                func();
              });
            } else {
              this.layoutProfile = profileData[0];
              func();
            }
          }

        })
        .catch(e => {
          this.httpAjaxError(e);
        });
      }
      
    },
    getDisplayNameOfProperty(val) {
      if (val == 'TASK') {
        return this.$t('task.title_singular');
      } else if (val == 'PROJECT') {
        return this.$t('task.field.project');
      } else {
        const found = this.customFields.find(i => i.name == val);
        if (found != null) {
          return found.displayName;
        }
        return  this.$t(`task.field.${val}`);
      }
    },
    getTodayLocalDate() {
      //Get the local date time literally and set it as UTC time. (Specification given by Paul)
      const localDateTime = new Date();
      const localYear = localDateTime.getFullYear();
      const localMonth = localDateTime.getMonth() + 1; //Provided month is 0-index based. Add 1 to get actual month value.
      const localDate = localDateTime.getDate();
      const localHour = localDateTime.getHours();
      const localMinutes = localDateTime.getMinutes();
      const localDateInStr = `${localYear}-${localMonth < 10? '0' + localMonth: localMonth}-${localDate < 10? '0' + localDate: localDate}`;
      const localTimeInStr = `${localHour < 10? '0' + localHour : localHour}:${localMinutes < 10? '0' + localMinutes: localMinutes }:00`;
      return { dateStr: localDateInStr, timeStr: localTimeInStr };
    },
    formatRebate(rebate) {
      return toFixed(rebate*100, 2);
    },
    getTodayWeekDay(momentDate=null) {
      const targetLocale = moment.locale();
      moment.locale('en');
      const weekday = momentDate != null? momentDate.locale('en').format('dddd') : moment.utc().locale('en').format('dddd');
      moment.locale(targetLocale);
      return weekday;
    },
    async getEnumList() {
      return this.$store.dispatch('data/enumList').then(response => {
        if (response.jobCase != null && response[response.jobCase] != null) {
          const propertyList = response[response.jobCase]
          if (propertyList != null) {
            if (propertyList.GanttPriorityEnum != null) {
              const obj = propertyList.GanttPriorityEnum
              const codes = Object.keys(obj)
              const list = []
              for (const c of codes) {
                list.push({ value: c, text: c, num: obj[c] })
              }
              this.optionPriority.splice(0, this.optionPriority.length, ...list)
            }
            if (propertyList.GanttComplexityEnum != null) {
              const obj = propertyList.GanttComplexityEnum
              const codes = Object.keys(obj)
              const list = []
              for (const c of codes) {
                list.push({ value: c, text: c, num: obj[c] })
              }
              this.optionComplexity.splice(0, this.optionComplexity.length, ...list)
            }
            if (propertyList.CurrencyEnum != null) {
              const obj = propertyList.CurrencyEnum
              const codes = Object.keys(obj)
              const list = []
              for (const c of codes) {
                const found = currencies.find(i => i.code == c)
                const text = found != null && found.name != null? `${c} (${found.name})` : c
                list.push({ value: c, text, num: obj[c] })
              }
              this.optionCurrency.splice(0, this.optionCurrency.length, ...list)
            }
          } 
        }
      }).catch(e => {
        this.httpAjaxError(e);
      });
    },
    getModelInfo() {
      const self = this;
      this.$store.dispatch('data/info', {type: "api", object: "TASK"}).then(value => {
        self.modelInfo = value.TASK.properties;
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    durationChanged() {
      let originDuration = this.original.duration;
      const duration = this.duration;
      
      //Fixed a false dialog prompt due to null origin duration.
      if (originDuration == null && duration != null) {
        originDuration = {}
        for (const prop in duration) {
          originDuration[prop] = null;
        }
      }

      if(originDuration.startDate != duration.startDate ||
          !isTimeEqual(originDuration.startTime, duration.startTime) ||
          originDuration.closeDate != duration.closeDate ||
          !isTimeEqual(originDuration.closeTime, duration.closeTime)) {
        return true;
      }
      return false;

      function isTimeEqual(time1, time2) {
        const t1 = time1? time1.length > 5? time1.substring(0, 5): time1 : '';
        const t2 = time2? time2.length > 5? time2.substring(0, 5): time2 : '';
        return t1 === t2;
      }
    },
    taskProjectGet(taskId, projectId) {
      const projectLinks = ['LOCATION', 'STAGE_LIST', 'COMPANY', 'REBATE'];
      const taskLinks = ['TASK', 'STAGE', 'SKILL', 'STAFF', 'REBATE', 'RESOURCE', 'STORAGE_FILE', 'NOTE', 'TAG'];
      Promise.allSettled([
        this.getEnumList(),
        this.isTemplate? templateProjectService.get([{ uuId: projectId }], projectLinks) : projectService.get([{ uuId: projectId }], projectLinks),
        this.isTemplate? templateTaskService.get([{ uuId: taskId }], taskLinks) : taskService.get([{ uuId: taskId }], taskLinks)
      ])
      .then(responses => {
        const projectResponse = responses[1];
        const taskResponse = responses[2];
        if (projectResponse.status == 'fulfilled') {
          const projectData = projectResponse.value.data[projectResponse.value.data.jobCase] || [];
          if(projectData.length > 0) {
            const redactedProperties = projectData.redacted != null && projectData.redacted.length > 0? projectData.redacted : [];
            this.checkProjectRedactedProps(redactedProperties);
            if (this.isAccessDenied) {
              return;
            }
            this.digestProjectResponse(projectData[0]);
          } else if(this.isTemplate) {
            console.error('Provided template project\'s uuId is invalid.'); // eslint-disable-line no-console
          } else {
            console.error('Provided project\'s uuId is invalid. Without project, task duration calculation logic can\'t work properly'); // eslint-disable-line no-console
          }
        } else if (projectResponse.reason != null && 
                  projectResponse.reason.response != null && 
                  projectResponse.reason.response.status == 403) {
          this.isAccessDenied = true
        }

              
        if (taskResponse.status == 'fulfilled') {
          const taskData = taskResponse.value.data[taskResponse.value.data.jobCase] || [];
          if(taskData.length > 0) {
            this.digestResponse(taskData[0]);
          }
        } else if (taskResponse.reason != null && 
                  taskResponse.reason.response != null && 
                  taskResponse.reason.response.status == 403) {
          this.isAccessDenied = true
        }
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    taskGet(id) {
      const taskLinks = ['TASK', 'STAGE', 'SKILL', 'STAFF', 'REBATE', 'RESOURCE', 'STORAGE_FILE', 'NOTE', 'TAG'];
      Promise.allSettled([
        this.getEnumList(),
        this.isTemplate? templateTaskService.get([{ uuId: id }], taskLinks) : taskService.get([{ uuId: id }], taskLinks)
      ]).then(responses => {
        const response = responses[1].value
        const listName = response.data.jobCase;
        const data = response.data[listName] || [];
        if(data.length > 0) {
          this.digestResponse(data[0]);
        }
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    taskPredecessorList(taskId) {
      const service = this.isTemplate? templateTaskService : taskService;
      service.predecessorList({ start: 0, limit: -1 }, taskId, this.predecessorCustomFields)
      .then((response) => {
        const data = response.data || [];
        this.original.predecessors = cloneDeep(data);
        this.predecessorData.splice(0, this.predecessorData.length, ...data);
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    digestResponse(data) {
      // this.$root.$emit('bv::hide::popover', 'lockDurationButton'); //Fix #392: the glitch of popover stuck in displaying state when disabling the popover during displaying state.
      const t = this.task;
      for (const key of Object.keys(t)) {
        if(data[key]) {
          t[key] = data[key]
        }
      }
      
      this.original.readOnly = data.readOnly;
      
      for (const field of this.customFields) {
        if (typeof data[field.name] !== 'undefined') {
          t[field.name] = data[field.name];
        }
      }
      
      this.original.description = data.description;

      //Set lockDuration to false (default value) when it is not found in the response.
      if(!Object.prototype.hasOwnProperty.call(data, 'lockDuration')) {
        t.lockDuration = false;
      }
      
      //Set autoScheduling before duration object to ensure Duration calculation process get the correct task autoScheduling value.
      if(!Object.prototype.hasOwnProperty.call(data, 'autoScheduling')) {
        t.autoScheduling = true;
      }
      else {
        t.autoScheduling = data.autoScheduling;
      }

      //Fix vue-boostrap-typehead bug by manually set task name here
      if(t.name != null && this.$refs.typeahead) {
        if (this.readOnly) {
          this.$refs.typeahead.$el.children[0].children[0].readOnly = true;
        }
        this.$refs.typeahead.inputValue = t.name;
        this.$refs.typeahead.baseHandleBlur = this.$refs.typeahead.handleBlur;
        const self = this;
        // workaround for the trim operation of the typeahead component
        this.$refs.typeahead.handleBlur = (param) => { 
          this.$refs.typeahead.baseHandleBlur(param);
          self.task.name = self.task.name.trim();
          this.$refs.typeahead.inputValue = self.task.name;
        };
      }

      data.taskType = data.taskType || null;

      //Keep a copy of original values. They will be used in type changed event.
      this.original.progress = data.progress * 100;
      this.original.estimatedCost = Math.round(t.estimatedCost);
      this.original.actualCost = Math.round(t.actualCost);
      this.original.estimatedDuration = data.estimatedDuration && data.estimatedDuration > 0? data.estimatedDuration : 0;
      this.original.fixedDuration = data.fixedDuration;
      if (data.progress != null && t.taskType !== TASK_TYPE.SUMMARY_TASK) {
        t.progress = Math.round(data.progress * 100);
      }
      
      if (data.progress != null && t.taskType === TASK_TYPE.SUMMARY_TASK) {
        t.progress = this.original.progress;
      }

      if (isNaN(t.progress)) {
        t.progress = 0;
      }      
      this.original.progress = t.progress;

      if (data.fixedCost && data.fixedCost == -1) {
        t.fixedCost = null;
      }
      t.estimatedCost = this.original.estimatedCost;
      t.actualCost = this.original.actualCost;
      
      t.currencyCode = data.currencyCode;
      //Convert estimatedDuration from milliseconds to minutes
      t.estimatedDuration = this.original.estimatedDuration;
      t.durationAUM = data.durationAUM;
      
      if (data.fixedDuration !== null) {
        t.fixedDuration = convertDurationToDisplay(data.fixedDuration, data.durationAUM, this.durationConversionOpts);
      }
      
      if (t.parent == this.projectId) {
        t.parent = 'ROOT';
      }
      
      //Disable dateTimeCalc Event listener. 
      //Reason: when setting up duration object, constraint type, the dateTime calculation event will be fired if not disabled.
      this.toggleDateTimeCalcEvent(false);

      if (this.task.taskType === TASK_TYPE.MILESTONE) {
        this.duration.value = '0D';
        if (data.startTime) {
          const d = moment(data.startTime);
          this.$set(this.duration, 'startDate', d.format('YYYY-MM-DD'));
          this.$set(this.duration, 'startTime', d.format('HH:mm'));
        }
        this.duration.closeDate = this.duration.startDate;
        this.duration.closeTime = this.duration.startTime;
      }
      else {
        //Handling edge case when the task has no valid startTime or closeTime
        if(data.startTime == 0 && data.duration == 0)  {
          data.startTime = null;
        } 
        //8640000000000000 is maximumn value of date. Larger than that is considered invalid.
        if(data.closeTime > 8640000000000000 && data.duration == 0)  {
          data.closeTime = null;
        }

        if (data.startTime) {
          const d = moment(data.startTime).utc();
          this.$set(this.duration, 'startDate', d.format('YYYY-MM-DD'));
          this.$set(this.duration, 'startTime', d.format('HH:mm'));
        } else {
          this.$set(this.duration, 'startDate', null);
          this.$set(this.duration, 'startTime', null);
        }
        if (data.closeTime) {
          const d = moment(data.closeTime).utc();
          this.$set(this.duration, 'closeDate', d.format('YYYY-MM-DD'));
          this.$set(this.duration, 'closeTime', d.format('HH:mm'));
        } else {
          this.$set(this.duration, 'closeDate', null);
          this.$set(this.duration, 'closeTime', null);
        }
      }
      this.prevStartDateStr = this.duration.startDate;
      this.prevStartTimeStr = this.duration.startTime;
      this.prevCloseDateStr = this.duration.closeDate;
      this.prevCloseTimeStr = this.duration.closeTime;

      this.original.startTime = this.constructDateTimeStr(this.duration.startDate, this.duration.startTime);
      this.original.closeTime = this.constructDateTimeStr(this.duration.closeDate, this.duration.closeTime);

      let durationAUM = data.durationAUM;
      if(!durationAUM || durationAUM.length == 0) {
        durationAUM = 'D'; //As agreed default unit is always 'D'.
      }

      if (data.duration != null) {
        this.duration.value = convertDurationToDisplay(data.duration, durationAUM, this.durationConversionOpts);
        this.$set(this.original, 'duration', cloneDeep(this.duration));
      } else {
        this.duration.value = '0D'; //When the existing task doesn't have any duration value. Set '0D' as default value.
      }

      if (data.taskList && data.taskList.length > 0) {
        this.taskList.splice(0, this.taskList.length, ...data.taskList);
      }

      this.constraint.type = data.constraintType || 'As_soon_as_possible'; //As decided by Paul, if it is null, set 'As_soon_as_possible' as default.
      
      if (data.constraintTime) {
        const d = moment(data.constraintTime);
        this.constraint.date = d.format('YYYY-MM-DD');
      }
      this.original.constraintDate = this.constraint.date;
      this.original.constraintType = this.constraint.type;
      this.prevConstraintType = this.constraint.type;
      this.prevConstraintDateStr = this.constraint.date;

      //Re-enable dateTimeCalc Event listener
      setTimeout(() => {
        this.toggleDateTimeCalcEvent(true);
      }, 100)
      

      if (data.stage) {
        this.stage = data.stage.uuId;
        this.original.stage = data.stage.uuId;
      }

      //Setup Skills data
      if (data.skillList && data.skillList.length > 0) {
        const list = data.skillList.map(i => { return { uuId: i.uuId, name: i.name, level: i.skillLink.level, ...filterCustomFields(i.skillLink, ['uuId']) }})
        this.original.skills.splice(0, this.original.skills.length, ...list);
        this.skills.splice(0, this.skills.length, ...cloneDeep(list));
      }

      //Setup Tags data
      const list = typeof data.tagList !== 'undefined' ? data.tagList : [];
      this.originTags.splice(0, this.originTags.length, ...list);
      this.tags.splice(0, this.tags.length, ...cloneDeep(list));
      
      //Setup Staff List data
      if (data.staffList && data.staffList.length > 0) {
        const list = data.staffList.map(i => { 
          let name = null;
          if(i.firstName && i.lastName) {
            name = `${i.firstName} ${i.lastName}`;
          } else if(i.firstName == null && i.lastName) {
            name = i.lastName;
          } else if (i.firstName && i.lastName == null) {
            name = i.firstName;
          } else {
            name = 'n/a';
          }
          return { 
            uuId: i.uuId, 
            name,
            utilization: i.resourceLink.utilization,
            quantity: i.resourceLink.quantity,
            duration: i.resourceLink.duration,
            durationAUM: i.resourceLink.durationAUM,
            genericStaff: i.genericStaff? i.genericStaff : false,
            ...filterCustomFields(i.resourceLink, ['uuId'])
          }
        });
        this.original.staffs.splice(0, this.original.staffs.length, ...list);
        this.staffs.splice(0, this.staffs.length, ...cloneDeep(list));
      }

      const totalActualDuration = data.actualDuration? data.actualDuration : 0;
      const reducer = (acc, curValue) => acc + (curValue.duration != null? curValue.duration: 0);
      const actualDuration = this.staffs.reduce(reducer, 0);
      this.childTasksActualDuration = this.task.taskType == 'Project' ? totalActualDuration : totalActualDuration - actualDuration;

      // Setup rebates data
      if (data.rebateList && data.rebateList.length > 0) {
        this.rebates = cloneDeep(data.rebateList);
        this.original.rebates.splice(0, this.original.rebates.length, ...data.rebateList);
      }
      
      //Setup Resources data
      if (data.resourceList && data.resourceList.length > 0) {
        const list = data.resourceList.map(i => { return { uuId: i.uuId, name: i.name, quantity: i.resourceLink? i.resourceLink.quantity: null, utilization: i.resourceLink? i.resourceLink.utilization: null, ...filterCustomFields(i.resourceLink, ['uuId']) }})
        this.original.resources.splice(0, this.original.resources.length, ...list);
        this.resources.splice(0, this.resources.length, ...cloneDeep(list));
      }

      //Setup Files data
      if (data.storageFileList && data.storageFileList.length > 0) {
        const list = data.storageFileList.map(i => { return { uuId: i.uuId, name: i.name, type: i.type }})
        this.original.files.splice(0, this.original.files.length, ...list);
        this.files.splice(0, this.files.length, ...cloneDeep(list));
      }

      //Setup Comment data
      this.notes = typeof data.noteList !== 'undefined' ? data.noteList : [];
      this.notes.sort((a, b) => {
        return b.modified - a.modified;
      });
      this.originNotes = cloneDeep(this.notes);
      if (data.noteList && data.noteList.length > 0) {
        const container = this.$refs['comments'];
        if (typeof container !== 'undefined') {
          container.scrollTop = container.scrollHeight;
        }
      }

      this.getStaffUsageDetails();
      
      this.original.task = {
        uuId: t.uuId
        , name: t.name
        , description: t.description
        , durationAUM: t.durationAUM
        , duration: t.duration
        , lockDuration: t.lockDuration
        , priority: t.priority
        , complexity: t.complexity
        , taskType: t.taskType
        , autoScheduling: t.autoScheduling
        , progress: t.progress != null? toFixed(t.progress / 100, 2) : null
        , parent: t.parent === 'ROOT'? this.projectId: t.parent
        , fixedCost: t.fixedCost != null? t.fixedCost : -1
        , currencyCode: t.currencyCode
        , avatarRef: t.avatarRef
        , constraintType: this.original.constraintType
        , constraintTime: this.original.constraintDate != null? moment.utc(this.original.constraintDate, 'YYYY-MM-DD').valueOf() : null
        , startTime: moment.utc(this.original.startTime, 'YYYY-MM-DDTHH:mm:ss').valueOf()
        , closeTime: moment.utc(this.original.closeTime, 'YYYY-MM-DDTHH:mm:ss').valueOf()
        , identifier: t.identifier
        , color: t.color
      };
    },
    async projectGet(projectId) {
      const redactedProperties = []

      let service = this.isTemplate? 'data/templateProjectGet' : 'data/projectGet';
      let data = await Promise.allSettled([
        this.getEnumList(),
        this.$store.dispatch(service, {projectIds: [{ uuId: projectId }], links: ['LOCATION', 'STAGE_LIST', 'COMPANY', 'REBATE']})
      ]).then(responses => {
        const value = responses[1].value
        if (value != null && value.jobCase != null && value[value.jobCase] != null) {
          if (value.redacted != null && value.redacted.length > 0) {
            redactedProperties.splice(0, redactedProperties.length, ...value.redacted)
          }

          const _value = value[value.jobCase]
          return _value != null? _value : []
        }
        return []
      })
      .catch(e => {
        if (e != null && e.response != null && e.response.status == 403) {
          this.isAccessDenied = true;
        }
        return [];
      });
      
      this.checkProjectRedactedProps(redactedProperties);
      if (this.isAccessDenied) {
        return;
      }
      
      if(data.length > 0) {
        const projectData = data[0];
        this.digestProjectResponse(projectData);
      }
    },
    digestProjectResponse(projectData) {
      const p = this.project;
      p.uuId = projectData.uuId;
      p.name = projectData.name;

      p.startDateStr = projectData.scheduleStart != null? moment.utc(projectData.scheduleStart).format('YYYY-MM-DD') : null;
      p.closeDateStr = projectData.scheduleFinish != null? moment.utc(projectData.scheduleFinish).format('YYYY-MM-DD') : null;

      const pAUM = projectData.durationAUM;
      const lenOfAUM = pAUM? pAUM.length : 0;
      p.durationAUM = pAUM? lenOfAUM > 1? pAUM.substring(lenOfAUM-1) : pAUM : null;

      p.autoScheduling = projectData.autoScheduling != null? projectData.autoScheduling : true;
      p.rebateList = projectData.rebateList || [];
      p.scheduleMode = projectData.scheduleMode != null? projectData.scheduleMode : 'ASAP';
      let curCode = null;
      if (projectData.currencyCode != null && this.optionCurrency.find(i => i.value == projectData.currencyCode && i.num > -1) != null) {
        curCode = projectData.currencyCode;
      }
      if (curCode == null && this.optionCurrency.find(i => i.num > -1) != null) {
        curCode = this.optionCurrency.find(i => i.num > -1).value;
      }
      
      p.currencyCode = curCode;
      p.company = projectData.companyList || [];
      const locationList = projectData.locationList || [];
      if(locationList.length > 0) { 
        p.locationId = locationList[0].uuId;
        this.calendarType.holderId = p.locationId;
        this.calendarType.type = 'project-location';
        this.locationCalendar(p.locationId);
      } else {
        p.locationId = null;
        //Default system calendar is used when it is template task or the project has no location.
        this.systemLocationCalendar();
      }
      if (projectData.stageList) {
        p.stageList = projectData.stageList;
        this.populateTaskStage();
      } else {
        p.stageList = [];
        this.optionStage = [];
      }
    },
    async systemLocationCalendar() {
      this.calendarType.holderId = null;
      this.calendarType.type = 'system';
      let data = await calendarService.get([{ uuId: '00000000-0000-0000-0000-000000000000'}])
      .then(response => {
        return (response && response.data? response.data : []) || [];
      })
      .catch(e => {
        this.httpAjaxError(e);
        return [];
      })
      if (data.length > 0) {
        this.digestCalendarResponse(data, ['base_calendar']);
      } else {
        this.calendar = DEFAULT_CALENDAR; // Fallback to default calendar.
      }
    },
    async locationCalendar(locationId) {
      let data = await locationService.calendar(locationId)
      .then(response => {
        return (response && response.data && response.data.jobCase? response.data[response.data.jobCase] : []) || [];
      })
      .catch(e => {
        this.httpAjaxError(e);
        return [];
      })
      if (data.length > 0) {
        this.digestCalendarResponse(data);

      }
    },
    digestCalendarResponse(data, calendarOrder=['location','base_calendar']) {
      const calendar = {};
      const existingTypes = [];
      for(const order of calendarOrder) {
        const calObj = data.find(i => i.name === order); 
        if(!calObj) {
          continue;
        }
        const calendarList = calObj.calendarList;
        
        for(const type of calendarList) {
          if (!type || type.length < 1 || (calendar[type.type] && calendar[type.type][0].calendar !== order)) {
            continue;
          }
          if(!calendar[type.type]) {
            existingTypes.push(type.type);
            calendar[type.type] = [];
          }
          const cloned = cloneDeep(type);
          cloned.calendar = order;
          if(cloned.startHour != null) {
            cloned.startHour = msToTime(cloned.startHour);
          }
          if(cloned.endHour != null) {
            cloned.endHour = msToTime(cloned.endHour);
          }
          calendar[type.type].push(cloned);
        }
      }
      
      const nonDayOfWeek = ['Leave', 'Working']
      const types = Object.keys(calendar);
      for(const type of types) {
        const typeObj = calendar[type];
        if(!nonDayOfWeek.includes(type) || typeObj.isWorking) {
          //typeObj.startHour = msToTime(typeObj.startHour);
          //typeObj.endHour = msToTime(typeObj.endHour);
        }
      }
      
      if (this.calendarType.type == 'project-location') {
        this.projectCalendar = JSON.parse(JSON.stringify(calendar));
      } else if (this.calendarType.type == 'system') {
        this.systemCalendar = JSON.parse(JSON.stringify(calendar));
      }
      this.$set(this, 'calendar', calendar);
      
      if (!this.exists) {
        if (this.project?.scheduleMode === 'ALAP') {
          const newCloseTimeObj = this.getSuggestedCloseTimeObj();
          this.$set(this.duration,'closeDate', newCloseTimeObj.dateStr); 
          this.$set(this.duration,'closeTime', newCloseTimeObj.timeStr);
        } else {
          const newStartTimeObj = this.getSuggestedStartTimeObj();
          this.$set(this.duration,'startDate', newStartTimeObj.dateStr); 
          this.$set(this.duration,'startTime', newStartTimeObj.timeStr);
        }
        this.$set(this.duration, 'value', '1D');
        this.initDurationCalculation();
      }
    },
    changeOnCompleteCancel() {
      this.state.isSubmitting = false;
      this.submittedBy = null;
      this.save_content = false;
      this.state.confirmChangeOnCompleteShow = false;
    },
    preOk(isSaveContent = false, callback=null) {
    
      const customFields = this.customFieldsFiltered;
      for (const field of customFields) {
        if (!customFieldValidate(field, this.task[field.name])) {
          field.showError = true;
          this.state.isSubmitting = false;
          this.submittedBy = null;
          return;  
        }
      }
      
      /** Specification by Paul: When a task is complete, user makes changes to the following properties:
       *  [start date, end date, duration, work effort, skills, staff, resources, constraints and progress]
       *  , we should prompt the user "This task has been already completed.  Proceed with changes?" Cancel/Confirm."
       */
      this.save_content = isSaveContent;
      const original = this.original;
      //If original progress is 100 and there is change which will affect task schedule, show confirmation dialog.
      if(original.progress == 100) {
        const curStartDateTime  = this.constructDateTimeStr(this.duration.startDate, this.duration.startTime);
        if(original.startTime !== curStartDateTime) {
          this.state.confirmChangeOnCompleteShow = true;
          return;
        }
        const curCloseDateTime  = this.constructDateTimeStr(this.duration.closeDate, this.duration.closeTime);
        if(original.closeTime !== curCloseDateTime) {
          this.state.confirmChangeOnCompleteShow = true;
          return;
        }
        if(original.duration !== null && original.duration.value !== this.duration.value) {
          this.state.confirmChangeOnCompleteShow = true;
          return;
        }
        if(this.original.constraintType !== this.constraint.type || this.original.constraintDate != this.constraint.date) { 
          this.state.confirmChangeOnCompleteShow = true;
          return;
        }
        const skillChanged = ((originSkills, skills) => {
          let isChanged = false;
          for(let i = 0, len = skills.length; i < len; i++) {
            const skill = skills[i];
            const index = originSkills.findIndex(j => j.uuId === skill.uuId);
            if(index == -1) {
              isChanged = true;
            } else {
              const originSkill = originSkills[index];
              if(originSkill.level !== skill.level) {
                isChanged = true;
              }
            }
            if(isChanged) {
              break;
            }
          }
          return isChanged;
        })(this.original.skills ,cloneDeep(this.skills));
        if(skillChanged) {
          this.state.confirmChangeOnCompleteShow = true;
          return;
        }
        const staffChanged = ((originStaffs, staffs) => {
          let isChanged = false;
          for(let i = 0, len = staffs.length; i < len; i++) {
            const staff = staffs[i];
            const index = originStaffs.findIndex(j => j.uuId === staff.uuId);
            if(index == -1) {
              isChanged = true;
            } else {
              const originStaff = originStaffs[index];
              if(originStaff.duration !== staff.duration || originStaff.utilization !== staff.utilization) {
                isChanged = true;
              }
            }
            if(isChanged) {
              break;
            }
          }
          return isChanged;
        })(this.original.staffs, cloneDeep(this.staffs));
        if(staffChanged) {
          this.state.confirmChangeOnCompleteShow = true;
          return;
        }
        const resourceChanged = ((originResources, resources) => {
          let isChanged = false;
          for(let i = 0, len = resources.length; i < len; i++) {
            const resource = resources[i];
            const index = originResources.findIndex(j => j.uuId === resource.uuId);
            if(index == -1) {
              isChanged = true;
            } else {
              const originResource = originResources[index];
              if(originResource.quantity != resource.quantity ||
                 originResource.utilization !== resource.utilization) {
                isChanged = true;
              }
            }
            if(isChanged) {
              break;
            }
          }
          return isChanged;
        })(this.original.resources, cloneDeep(this.resources));
        if(resourceChanged) {
          this.state.confirmChangeOnCompleteShow = true;
          return;
        }
      }
      //Safe to proceed to save task.
      this.ok(callback);
    },
    ok(callback=null) {
      if(this.state.alertTaskChangedShow) {
        this.state.alertTaskChangedShow = false;
      }
      if(this.state.confirmChangeOnCompleteShow) {
        this.state.confirmChangeOnCompleteShow = false;
      }
      
      if (this.totalRebate > 100) {
        this.alertMsg = this.$t('rebate.error.total');
        this.alertError = true;
        this.state.isSubmitting = false;
        this.submittedBy = null;
        return;
      }
      
      //Apply $nextTick() here to fix an issue caused by a change in duration field is not reflected when user click 'save' button directly right after changing the value in the textbox. 
      //The change event is not triggered. $nextTick() waits for the change event to happen before proceed to task saving process.
      this.$nextTick(() => {
        this.errors.clear();
        this.alertMsg = null;
        this.alertError = false;
        this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
        this.validateCurrencyCode();
        this.validateConstraintDate();
        this.$validator.validate().then(valid => {
          if (valid && this.errors.items.length < 1) {
            this.taskSubmit(callback);
          } else {
            this.alertMsg = this.$t('error.attention_required');
            this.alertError = true;
            this.submittedBy = null;
            this.scrollToTop();
          }
        });
      });
    },
    async taskSubmit(callback=null) {
      const data = cloneDeep(this.task);
      if (this.settings.currency !== this.task.currencyCode) {
        this.settings.currency = this.task.currencyCode;
        await updateViewProfile(this.settings, this.$store.state.authentication.user.uuId);
      }
      
      if(data.progress != null) {
        if (isNaN(data.progress)) {
          data.progress = 0;
        } else {
          data.progress = toFixed(data.progress / 100, 2);
        }
        
      }
      
      if (data.fixedDuration !== null) {
        const { unit } = analyzeDurationAUM(data.fixedDuration);
        data.durationAUM = unit;
        const { value } = convertDisplayToDuration(data.fixedDuration, this.durationConversionOpts);
        data.fixedDuration = value;
      }
      
      if (this.duration.value != null) {
        const { unit } = analyzeDurationAUM(this.duration.value);
        data.durationAUM = unit;
        const { value } = convertDisplayToDuration(this.duration.value, this.durationConversionOpts);
        data.duration = value;
      } else {
        const taskAutoScheduleMode = data.autoScheduling != null? data.autoScheduling : true;
        data.duration = taskAutoScheduleMode? 480 : null; // Default to 1D when task autoScheduling is true.
        data.durationAUM = 'D';
      }

      let sDate = this.duration.startDate != null && this.duration.startDate != ''? this.duration.startDate : null;
      if (sDate != null) {
        const sDateTime = moment.utc(sDate, 'YYYY-MM-DD');
        let sTime = this.duration.startTime;
        if (sTime == null || sTime.trim().length == 0) {
          sTime = getEarliestOrLatestWorkHour(this.calendar, moment.utc(sDate, 'YYYY-MM-DD'), { isLatest: false, ignoreLeave: false });
        }
        if (sTime != null) {
          const token = sTime.split(':');
          sDateTime.hour(token[0]).minute(token[1]);
        }
        data.startTime = sDateTime.valueOf();
      } else {
        data.startTime = null;
      }
      
      let cDate = this.duration.closeDate != null && this.duration.closeDate != ''? this.duration.closeDate : null;
      if (cDate != null) {
         const cDateTime = moment.utc(cDate, 'YYYY-MM-DD');
        let cTime = this.duration.closeTime;
        if (cTime == null || cTime.trim().length == 0) {
          cTime = getEarliestOrLatestWorkHour(this.calendar, moment.utc(cDate, 'YYYY-MM-DD'), { isLatest: true, ignoreLeave: false });
        }
        if (cTime != null) {
          const token = cTime.split(':');
          cDateTime.hour(token[0]).minute(token[1]);
        }
        data.closeTime = cDateTime.valueOf();
      } else {
        data.closeTime = null;
      }

      const constraintType = this.constraint.type;
      if (constraintType) {
        data.constraintType = constraintType;
      }
      
      if (this.constraint.date == null || CONSTRAINT_TYPES.ASAP == constraintType || CONSTRAINT_TYPES.ALAP == constraintType) {
        data.constraintTime = null;
      } else {
        data.constraintTime = moment(this.constraint.date, 'YYYY-MM-DD').valueOf();
      }

      if (data.taskType == TASK_TYPE.TASK && data.autoScheduling == true && (data.duration == null || data.startTime == null || data.closeTime == null)) {
        let parentStartDateStr = moment.utc().format('YYYY-MM-DD');
        let parentStartTimeStr = null;
        if (data.parent === 'ROOT') {
          data.parent = this.projectId;
          if (isValidDateStrFormat(this.project.startDateStr)) {
            parentStartDateStr = this.project.startDateStr;
          }
        } else {
          const foundParent = this.parentCandidateList.find(i => i.id == data.parent);
          if (foundParent != null && foundParent.startDate != null && foundParent.startDate != 0) {
            const parentStartDateTime = moment.utc(foundParent.startDate);
            parentStartDateStr = parentStartDateTime.format('YYYY-MM-DD');
            parentStartTimeStr = parentStartDateTime.format('HH:mm');
          } else if (isValidDateStrFormat(this.project.startDateStr)) {
            parentStartDateStr = this.project.startDateStr;
          }
        }

        const projectScheduleFromStart = this.project != null? this.project.scheduleMode != 'ALAP': true;
        let _durationDisplay = null;
        if (data.duration == null) {
          _durationDisplay = convertDurationToDisplay(480, data.duratioAUM, this.durationConversionOpts);
        } else {
          _durationDisplay = convertDurationToDisplay(data.duration, data.durationAUM, this.durationConversionOpts);
        }

        let _constraintType = data.constraintType;
        let _constraintDateStr = null;
        if (data.constraintTime != null) {
          _constraintDateStr = moment.utc(data.constraintTime).format('YYYY-MM-DD');
        }

        let _startDateStr = null;
        let _startTimeStr = null;
        let _closeDateStr = null;
        let _closeTimeStr = null;
        if (data.startTime == null && data.closeTime == null) {
          if (projectScheduleFromStart) {
            _startDateStr = parentStartDateStr;
            _startTimeStr = parentStartTimeStr;
          } else {
            _closeDateStr = parentStartDateStr;
            _closeTimeStr = parentStartTimeStr;
          }
        } else if (data.startTime != null) {
          const _startDateTime = moment.utc(data.startTime);
          _startDateStr = _startDateTime.format('YYYY-MM-DD');
          _startTimeStr = _startDateTime.format('HH:mm');
        } else { //closeTime != null
          const _closeDateTime = moment.utc(data.closeTime);
          _closeDateStr = _closeDateTime.format('YYYY-MM-DD');
          _closeTimeStr = _closeDateTime.format('HH:mm');
        }

        const payload = {
          trigger: TRIGGERS.DURATION
          , startDateStr: _startDateStr
          , startTimeStr: _startTimeStr
          , closeDateStr: _closeDateStr
          , closeTimeStr: _closeTimeStr
          , durationDisplay: _durationDisplay
          , calendar: this.calendar
          , projScheduleFromStart: projectScheduleFromStart
          , constraintType: _constraintType
          , constraintDateStr: _constraintDateStr
          , projectStartDateStr: this.project.startDateStr
          , projectCloseDateStr: this.project.closeDateStr
          , oldDateStr: null
          , oldTimeStr: null
          , taskAutoScheduleMode: true
          , lockDuration: false
          , autoMoveForNonWorkingDay: true
          , skipOutOfProjectDateCheck: true
          , resizeMode: false
          , durationConversionOpts: this.durationConversionOpts
        }

        const result = calcDateTimeDurationv2(payload);
        this.duration.startDate = result.startDateStr;
        this.duration.startTime = result.startTimeStr;
        this.duration.closeDate = result.closeDateStr;
        this.duration.closeTime = result.closeTimeStr;
        this.duration.value = result.durationDisplay;
        this.constraint.type = result.constraintType;
        this.constraint.date = result.constraintDateStr;
        data.startTime = moment.utc(`${result.startDateStr} ${result.startTimeStr}`, 'YYYY-MM-DD HH:mm').valueOf();
        data.closeTime = moment.utc(`${result.closeDateStr} ${result.closeTimeStr}`, 'YYYY-MM-DD HH:mm').valueOf();
        data.duration = convertDisplayToDuration(result.durationDisplay, this.durationConversionOpts).value;
        data.constraintType = result.constraintType;
        data.constraintTime = result.constraintDateStr != null? moment.utc(result.constraintDateStr, 'YYYY-MM-DD').valueOf() : null;
      } else {
        data.parent = data.parent === 'ROOT'? this.projectId: data.parent;
      }

      if(data.fixedCost == null) {
        data.fixedCost = -1;
      }
      
      if (data.fixedCost === '') {
        this.task.fixedCost = data.fixedCost = '0'; // set a zero value so that it updates
      }
      
      if (this.original.description == data.description) {
        delete data.description;
      }

      if(this.original.constraintTime == data.constraintTime && data.constraintTime == null) {
        delete data.constraintTime;
      }

      if(this.original.constraintType == data.constraintType) {
        delete data.constraintType;
      }

      delete data.estimatedDuration;
      delete data.fixedCostNet;
      delete data.actualCostNet;
      delete data.actualCost;
      delete data.estimatedCostNet;
      delete data.estimatedCost;
      delete data.fullPath;

      //As suggested by Chrism, do not sent these fields when type is 'Project'
      if (data.taskType == 'Project') {
        delete data.startTime;
        delete data.closeTime;
        delete data.duration;
        delete data.progress;
        delete data.constraintType;
        delete data.constraintTime;
        delete data.autoScheduling;
      }

      let method = 'update';
      if(!this.exists) {
        delete data['uuId'];
        method = 'create';
      }
      this.taskPost(method, data, this.projectId, callback);
    },
    async taskPost(method, data, projectId, callback=null) {
      this.state.isSubmitting = true;
      let taskId = null;
      
      //Skip updating task if there is no change in task properties.
      let hasChanged = false;
      if (method != 'create') {
        hasChanged = this.removeUnchangedTaskProperties(data);
      }

      //Filter out properties which in denyRules
      const propKeys = Object.keys(data);
      const permissionName = method == 'create'? 'TASK__ADD' : 'TASK__EDIT';
      const permList = this.$store.state.authentication.user.permissionList.filter(f => f.name === permissionName);
      const perms = permList.length > 0 ? permList[0] : [];
      const denyRules = perms && perms.permissionLink && perms.permissionLink.denyRules ?
                        perms.permissionLink.denyRules : [];
      for (const k of propKeys) {
        if (denyRules.includes(k)) {
          delete data[k];
        }
      }

      if (method == 'create' || hasChanged) {
        const result = await this.updateTask(method, data, projectId);
        if(result.hasError) {
          this.alertMsg = result.msg;
          this.alertError = true;
          this.state.isSubmitting = false;
          this.submittedBy = null;
          return;
        }
        taskId = result.taskId;
      } else {
        taskId = data.uuId;
      }
      
      // update the last used values
      if (this.layoutProfile !== null) {
        this.layoutProfile.complexity = data.complexity;
        this.layoutProfile.priority = data.priority;
        this.layoutProfile.stage = this.stage;
        this.updateLayoutProfile();
      }
      
      let hasError = false;

      // save the color in the profile
      this.updatedColor = data.color;
      if (!this.isSkillReadOnly) {
        const skillResult = await updateSkills(taskId, this.isTemplate? templateTaskLinkSkillService : taskLinkSkillService, this.original.skills, this.skills)
        if (skillResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`task.${method}_partial`);
          if (skillResult.errorCodes.length > 0) {
            if (skillResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('skill.title_selector').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('skill.title_selector').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('skill.title_selector').toLowerCase()]))
          }
        }
      }
      
      if (!this.isPredecessorReadOnly) {
        const predecessorResult = await this.updatePredecessors(taskId);
        if (predecessorResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`task.${method}_partial`);
          if (predecessorResult.errorCodes.length > 0) {
            if (predecessorResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('task_predecessor.title_predecessors').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('task_predecessor.title_predecessors').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('task_predecessor.title_predecessors').toLowerCase()]))
          }
        }
      }
      
      if (!this.isStaffReadOnly) {
        const staffResult = await this.updateStaffs(taskId);
        if (staffResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`task.${method}_partial`);
          if (staffResult.errorCodes.length > 0) {
            if (staffResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('staff.title').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('staff.title').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('staff.title').toLowerCase()]))
          }
        }
      }
      
      if (!this.isRebateReadOnly) {
        const rebateResult = await updateRebates(taskId, this.isTemplate? templateTaskLinkRebateService : taskLinkRebateService, this.original.rebates, this.rebates)
        if (rebateResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`task.${method}_partial`);
          if (rebateResult.errorCodes.length > 0) {
            if (rebateResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('rebate.title_selector').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('rebate.title_selector').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('rebate.title_selector').toLowerCase()]))
          }
        }
      }      

      if (!this.isResourceReadOnly) {
        const resourceResult = await updateResources(taskId, this.isTemplate? templateTaskLinkResourceService : taskLinkResourceService, this.original.resources, this.resources)
        if (resourceResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`task.${method}_partial`);
          if (resourceResult.errorCodes.length > 0) {
            if (resourceResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('resource.title_selector').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('resource.title_selector').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('resource.title_selector').toLowerCase()]))
          }
        }
      }
      
      if (!this.isStorageFileReadOnly) {
        const fileResult = await updateFiles(taskId, this.isTemplate? templateTaskLinkFileService : taskLinkFileService, this.original.files, this.files)
        if (fileResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`task.${method}_partial`);
          if (fileResult.errorCodes.length > 0) {
            if (fileResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('file.title.list').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('file.title.list').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('file.title.list').toLowerCase()]))
          }
        }
      }
      
      if (!this.isStageReadOnly) {
        const stageResult = await this.updateStage(taskId);
        if (stageResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`task.${method}_partial`);
          if (stageResult.errorCodes.length > 0) {
            if (stageResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('stage.title').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('stage.title').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('stage.title').toLowerCase()]))
          }
        }
      }

      if (!this.isTagReadOnly) {
        const tagResult = await updateTags(taskId, this.isTemplate ? templateTaskLinkTagService : taskLinkTagService, this.originTags, this.tags);
        if (tagResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`task.${method}_partial`);
          if (tagResult.errors.filter(i => i.response != null && i.response.status == 403).length > 0) {
            this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('tag.title').toLowerCase()]))
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('tag.title').toLowerCase()]))
          }
        }
      }
      
      //Notes
      //Remove uuId of new notes before saving
      if (!this.isNoteReadOnly) {
        const notes = cloneDeep(this.notes);
        for (let i = 0, len = notes.length; i < len; i++) {
          if (notes[i].uuId != null && notes[i].uuId.startsWith('NEW_NOTE')) {
            delete notes[i].uuId;
          }
        }      
        const noteResult = await persistNotes(taskId, this.originNotes, notes);
        if (noteResult.errors.length > 0 || noteResult.errorCodes.length > 0) {
          hasError = true;
          this.alertMsg = this.$t(`task.${method}_partial`);
          if (noteResult.errorCodes.length > 0) {
            if (noteResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('notes').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('notes').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('notes').toLowerCase()]))
          }
        }
      }

      const stageName = this.optionStage.find(s => s.key === this.stage);
      this.state.isSubmitting = false;
      this.submittedBy = null;
      if(hasError) {
        // this.alertMsgDetails.splice(0, this.alertMsgDetails.length, ...alertDetails);
        // this.alertMsg = this.$t(`task.${method}_partial`);
        this.alertError = true;
        this.scrollToTop();
      } else if (this.save_content) {
        this.save_content = false;
        this.$emit('success', { 
          id: taskId, 
          msg: this.$t(`task.${method}`), 
          imageChanged: this.original.task == null || (this.original.task.avatarRef !== data.avatarRef), 
          parentChanged: this.original.task !== null && this.original.task.parent !== this.task.parent,
          data: { ...this.task, project: this.project, duration: this.duration, rebates: this.rebates }, 
          type: 'task',
          staff: this.staffs,
          origStaff: this.original.staffs,
          stage: stageName ? stageName.label : null,
          stageUuid: stageName ? stageName.key : null,
          hasSplit: this.hasSplit
        });
        this.$emit('update:id', taskId);
        this.taskProjectGet(taskId, this.projectId);
        this.taskPredecessorList(taskId);
        this.alertError = false;
        this.alertMsg = this.$t(`task.${method}`);
        if (callback != null && typeof callback == 'function') {
          callback();
        }
      } else {
        this.$emit('update:show', false);
        this.$emit('success', { 
          id: taskId, 
          msg: this.$t(`task.${method}`), 
          imageChanged: this.original.task == null || (this.original.task.avatarRef !== data.avatarRef), 
          parentChanged: this.original.task !== null && this.original.task.parent !== this.task.parent,
          data: { ...this.task, project: this.project, duration: this.duration, rebates: this.rebates }, 
          type: 'task',
          staff: this.staffs,
          origStaff: this.original.staffs,
          stage: stageName ? stageName.label : null,
          stageUuid: stageName ? stageName.key : null,
          hasSplit: this.hasSplit
        });
      }
    },
    async updateTask(method, data, projectId) {
      const result = {
        hasError: false,
        msg: this.$t(`task.${method}`)
      }
      
      removeDeniedProperties(this.permissionName, data, this.exists? 'EDIT':'ADD');
      const service = this.isTemplate? templateTaskService : taskService;
      let taskId = await service[method]([data], projectId)
      .then(response => {
        const data = response.data;
        return data[data.jobCase][0].uuId;
      })
      .catch(e => {
        result.hasError = true;
        const response = e.response;
        let errorMsg = this.$t('error.internal_server');
        if (response && 403 === response.status) {
          errorMsg = this.$t('error.authorize_action');
        } else if (response && 422 === response.status) {
          const feedback = response.data[response.data.jobCase][0];
          const clue = feedback.clue.trim().toLowerCase();
          if(['missing_argument','cannot_be_blank', 
              'string_limit_exceeded', 'number_limit_exceeded',
              'date_limit_exceeded', 'day_limit_exceeded',
              'duration_limit_exceeded'].includes(clue)) {
            errorMsg = this.$t('error.attention_required');
            const fieldKey = `task.${feedback.args[0]}`;
            const args = [this.$t(`task.field.${feedback.args[0]}`)];
            let clueNotHandled = false;
            switch (clue) {
              case 'missing_argument': //Do nothing. Doesn't need additional argument
                break;
              case 'date_limit_exceeded':
                args.push(moment.utc(feedback.args[1]).format('YYYY-MM-DD'));
                break;
              case 'day_limit_exceeded':
                args.push(moment.utc(feedback.args[1]).format('HH:mm'));
                break;
              case 'duration_limit_exceeded':
              case 'string_limit_exceeded':
              case 'number_limit_exceeded':
                args.push(feedback.args[1]);
                break;
              default:
                clueNotHandled = true;
                errorMsg = this.$('error.internal_server'); //reset the errorMsg to internal_server error.
            }
            if (!clueNotHandled) {
              this.errors.add({
                field: fieldKey,
                msg: this.$t(`error.${clue}`, args)
              });
            }
          }
        }
        result.msg = errorMsg;
      });
      result.taskId = taskId;
      return result;
    },
    async updatePredecessors(taskId) {
      const customFieldKeys = this.predecessorCustomFields.map(i => i.name);

      const service = this.isTemplate? templateTaskLinkSuccessorService : taskLinkSuccessorService;
      const result = {
        hasError: false,
        errorCodes: []
      }
      const predecessors = cloneDeep(this.predecessorData);
      const originPredecessors = this.original.predecessors;
      const toAdd = [];
      const toUpdate = [];
      const unchangedIds = [];

      const self = this;
      const convertToMinutesForSaving = function(predecessor) {
        if (predecessor.lag != null) {
          predecessor.lag = convertDisplayToDuration(`${predecessor.lag}D`, self.durationConversionOpts).value;
        }
      }
      
      for(let i = 0, len = predecessors.length; i < len; i++) {
        const predecessor = predecessors[i];
        const index = originPredecessors.findIndex(j => j.uuId === predecessor.uuId);
        if(index == -1) {
          convertToMinutesForSaving(predecessor);
          toAdd.push(predecessor);
        } else {
          const originPredecessor = originPredecessors[index];
          let hasChanged = originPredecessor.type !== predecessor.type || originPredecessor.lag !== predecessor.lag;
          if (!hasChanged) {
            for (const k of customFieldKeys) {
              if (originPredecessor[k] !== predecessor[k]) {
                hasChanged = true;
                break;
              }
            }
          }
          if(hasChanged) {
            convertToMinutesForSaving(predecessor);
            toUpdate.push(predecessor);
          } else {
            unchangedIds.push(predecessor.uuId);
          }
        }
      }

      const toAddIds = toAdd.map(i => i.uuId);
      const toUpdateIds = toUpdate.map(i => i.uuId);
      const toRemove = originPredecessors.filter(i => !toAddIds.includes(i.uuId) && !toUpdateIds.includes(i.uuId) && !unchangedIds.includes(i.uuId));

      //To add new predecessors if there is any.
      for(let i = 0, len = toAdd.length; i < len; i++) {
        const predecessor = toAdd[i];
        const successor = { uuId: taskId, type: predecessor.type, lag: predecessor.lag }
        for (const k of customFieldKeys) {
          if (Object.hasOwn(predecessor, k)) {
            successor[k] = predecessor[k];
          }
        }
        let { needUpdate=[], hasError=false, errorCode=null } = await service.create(predecessor.uuId, [successor], this.predecessorCustomFields)
        .catch(e => {
          if(e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            const needUpdate = list.filter(i => i.clue === 'Already_have_edge').length > 0;
            const invalidRelation = list.filter(i => i.clue === 'Invalid_relation').length > 0;
            return { 
              needUpdate: needUpdate? [predecessor] : [], 
              hasError: invalidRelation, 
              errorCode: 422 };
          } else {
            return { hasError: true, errorCode: e != null && e.response != null? e.response.status : null }
          }
        });
        if(hasError) {
          result.hasError = true;
          result.errorCodes.push(errorCode);
        }
        //Collect those predecessors require Update (instead of add) and add them to toUpdate list.
        if(needUpdate && needUpdate.length > 0) {
          toUpdate.splice(toUpdate.length, 0, ...needUpdate);
        }
      }

      //To update existing predecessors if there is any.
      for(let i = 0, len = toUpdate.length; i < len; i++) {
        const predecessor = toUpdate[i];
        const successor = { uuId: taskId, type: predecessor.type, lag: predecessor.lag }
        for (const k of customFieldKeys) {
          if (Object.hasOwn(predecessor, k)) {
            successor[k] = predecessor[k];
          }
        }
        let{ needAdd=[], hasError=false, errorCode=null } = await service.update(predecessor.uuId, [successor], this.predecessorCustomFields)
        .catch(e => {
          if(e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            const needAdd = list.filter(i => i.clue === 'Unknown_relation').length > 0;
            return { 
              needAdd: needAdd? [predecessor] : [], 
              hasError: !needAdd,
              errorCode: 422
            };
          } else {
            return { hasError: true, errorCode: e != null && e.response != null? e.response.status : null }
          }
        });
        if(hasError) {
          result.hasError = true;
          result.errorCodes.push(errorCode);
        }
        

        //To add predecessors which require Add operation (instead of Update) if there is any.
        if(needAdd) {
          for(let i = 0, len = needAdd.length; i < len; i++) {
            const predecessor = needAdd[i];
            const successor = { uuId: taskId, type: predecessor.type, lag: predecessor.lag }
            for (const k of customFieldKeys) {
              if (Object.hasOwn(predecessor, k)) {
                successor[k] = predecessor[k];
              }
            }
            let { hasError=false, errorCode=null } = await service.create(predecessor.uuId, [successor], this.predecessorCustomFields)
            .catch(e => {
              if(e.response && 422 == e.response.status) {
                return { hasError: true, errorCode: 422 };
              } else {
                return { hasError: true, errorCode: e != null && e.response != null? e.response.status : null }
              }
            });
            if(hasError) {
              result.hasError = true;
              result.errorCodes.push(errorCode);
            }
          }
        }
      }

      //Try remove predecessors which user wants to remove if there is any
      for(let i = 0, len = toRemove.length; i < len; i++) {
        const predecessor = toRemove[i];
        const successor = { uuId: taskId, type: predecessor.type, lag: predecessor.lag }
        let { hasError=false, errorCode=null } = await service.remove(predecessor.uuId, [successor])
        .catch(e => {
          if(e.response && 422 == e.response.status) {
            return { hasError: true, errorCode: 422 };
          } else {
            return { hasError: true, errorCode: e != null && e.response != null? e.response.status : null }
          }
        });
        if(hasError) {
          result.hasError = true;
          result.errorCodes.push(errorCode);
        }
      }

      return result;
    },
    httpAjaxError(e) {
      console.error(e); // eslint-disable-line no-console
      const response = e.response;
      if (response && 403 === response.status) {
        this.alertMsg = this.$t('error.authorize_action');
        this.alertError = true;
        
      } else if (response && 422 === response.status) {
        const feedback = response.data[response.data.jobCase][0];
        if(feedback !== null && feedback.spot) {
          this.alertMsg = this.$t('error.attention_required');
          this.alertError = true;
          this.errors.add({
            field: `task.${feedback.spot}`,
            msg: this.$t(`error.${feedback.clue}`, feedback.args)
          })
        } else {
          console.error(e); // eslint-disable-line no-console
          this.alertMsg = this.$t('error.internal_server');
          this.alertError = true;
        }
      } else {
        console.error(e); // eslint-disable-line no-console
        this.alertMsg = this.$t('error.internal_server');
        this.alertError = true;
      }
      this.scrollToTop();
    },
    scrollToTop() {
      //Find the data-v-xxxx value in div element which id is #TASK_FORM_XXX
      //Use it to find the relevant div element with '.modal-body' style class and scroll to top.
      setTimeout(() => {
        const _keys = Object.keys(document.querySelector(`#TASK_FORM_${this.id}`).dataset)
        let elem = null
        let dataSelector = null
        for (const k of _keys) {
          dataSelector = `[data-${k}=""]`
          elem = document.querySelector(`${dataSelector} .alert`)
          
          if (elem != null) {
            break
          }
        }
        if (elem != null) {
          elem.closest('.modal-body').scrollTo({
            top: 0,
            left: 0,
            behavior: "auto",
          })
        }
      }, 0)
    },
    dismissAlert() {
      this.alertMsg = null;
        this.alertError = false;
    },
    resetProperties() {
      const keys = Object.keys(this.task);
      this.errors.clear();
      this.$validator.reset();
      for(let i = 0, len = keys.length; i < len; i++) {
        if(keys[i] === 'uuId' || keys[i] === 'parent') {
          continue;
        }
        
        let customField = this.customFields.find(f => f.name === keys[i])
        if (customField) {
          if (customField.def) {
            this.task[keys[i]] = customField.def;
            continue;
          }
        }
        this.task[keys[i]] = null;
      }
      
      this.task.priority = null;
      if (this.taskType != null) {
        this.$set(this.task, 'taskType', this.taskType);
      } else {
        this.$set(this.task, 'taskType', TASK_TYPE.TASK);
      }
      
      this.task.progress = 0;
      this.$set(this.task, 'autoScheduling', true);
      
      this.original.description = null;
      this.original.duration = null;
      this.predecessorData.splice(0, this.predecessorData.length);
      this.original.predecessors = [];
      
      this.original.skills = [];
      this.skills = [];
      this.toConfirmSkills = [];
      this.skillLevelEdit.uuId = null;
      this.skillLevelEdit.name = null;
      this.skillLevelEdit.level = null;

      this.original.staffs = [];
      this.staffs = [];
      this.toConfirmStaffs = [];
      this.staffUtilizationEdit.uuId = null;
      this.staffUtilizationEdit.name = null;
      this.staffUtilizationEdit.utilization = null;
      this.staffUtilizationEdit.unit = null;
      this.staffUtilizationEdit.genericStaff = false;

      this.original.rebates = [];
      this.rebates = [];

      this.original.resources = [];
      this.resources = [];
      this.toConfirmResources = [];
      this.resourceUnitEdit.uuId = null;
      this.resourceUnitEdit.name = null;
      this.resourceUnitEdit.unit = null;
      this.resourceUnitEdit.utilization = null;

      this.original.files = [];
      this.files = [];

      this.duration.closeDate = null;
      this.duration.closeTime = null;

      this.original.startTime = null;
      this.original.closeTime = null;
      this.original.progress = null;
      this.original.constraintDate = null;
      this.original.constraintType = null;
      this.original.task = null;

      if (this.project?.scheduleMode === 'ALAP') {
        const newCloseTimeObj = this.getSuggestedCloseTimeObj();
        this.$set(this.duration,'startDate', newCloseTimeObj.dateStr); 
        this.$set(this.duration,'startTime', newCloseTimeObj.timeStr);
        this.$set(this.duration, 'value', '1D');
      } else {
        const newStartTimeObj = this.getSuggestedStartTimeObj();
        this.$set(this.duration,'startDate', newStartTimeObj.dateStr); 
        this.$set(this.duration,'startTime', newStartTimeObj.timeStr);
        this.$set(this.duration, 'value', '1D');
      }

      this.constraint.type = null;
      this.constraint.date = null;
      this.taskList.splice(0, this.taskList.length);

      this.original.stage = null;
      this.stage = null;
      this.etocInput = null;

      this.childTasksActualDuration = 0; //Fix #393.
      this.isDurationCalculationInProgress = false;
    },
    async populateTaskConstraint() {
      if (this.optionConstraint.length !== 0) {
        return;
      }
      
      const service = this.isTemplate? templateTaskService : taskService;
      let list = await service.optionConstraint()
      .then(response => {
        return response;
      })
      .catch(e => {
        if (e != null && e.response != null && 
            e.response.data != null && e.response.data.jobClue != null &&
            e.response.data.jobClue.clue == 'Forbidden_api') {
          this.isAccessDenied = true
        } else {
          this.httpAjaxError(e);
        }
        return [];
      });
      this.optionConstraint.splice(0, this.optionConstraint.length, ...list.map(i => { return { key: i.label, label: this.$t(`constraint_type.${i.label}`)} }));
    },
    async populateTaskType() {
      if (this.optionType.length !== 0 ) {
        return;
      }
      
      const service = this.isTemplate? templateTaskService : taskService;
      let list = await service.optionType()
      .then(response => {
        return response;
      })
      .catch(e => {
        if (e != null && e.response != null && 
            e.response.data != null && e.response.data.jobClue != null &&
            e.response.data.jobClue.clue == 'Forbidden_api') {
          this.isAccessDenied = true
        } else {
          this.httpAjaxError(e);
        }
        return [];
      });
      list = list.filter(i => i.key !== '9'); // Remove 'Master' from type options as requested by Paul.
      this.optionType.splice(0, this.optionType.length, ...list.map(i => { return { key: i.label, label: this.$t(`task_type.${i.label}`)} }));
    },
    async populateTaskStage() {
      //Filtered by task's tags
      const stageIds = this.project.stageList.map(i => i.uuId);
      const tagNames = this.tags.map(i => i.name);
      const canViewTag = this.canView('TAG') && this.canView('STAGE', ['TAG']);
      let stageList = JSON.parse(JSON.stringify(this.project.stageList));
      let currentStageOpt = null;
      if (stageIds.length > 0 && tagNames.length > 0 && canViewTag) {
        const listParams = { start: 0, limit: -1, ksort: 'name' }
        const tagFilterList = ['_or_'];
        const tagList = [];
        for(const t of tagNames) {
          tagList.push(['STAGE.TAG.name', 'is', t])
        }
        tagFilterList.push(tagList);
        listParams.tagFilter = tagFilterList;

        listParams.holder = stageIds;
        await stageService.list(listParams).then((response) => {
          if (response.arg_total > 0) {
            if (this.stage != null && response.data.find(i => i.uuId == this.stage) == null) {
              const found = stageList.find(i => i.uuId == this.stage);
              //Keep the disabled but currently selected option
              if (found != null) {
                currentStageOpt = found;
              }
            }
            stageList = response.data;
          }
        }).catch(e => {
          console.error(e) // eslint-disable-line no-console
        });
      }

      let options = [{key: null, label: ""}]; // First item is blank
      if (stageList != null) {
        stageList.forEach(stage => {
          options.push({key: stage.uuId, label: stage.name});
        });
        //Add the disabled but currently selected option with disabled prop
        if (currentStageOpt != null) {
          options.push({key: currentStageOpt.uuId, label: currentStageOpt.name, disabled: true });
        }
        this.optionStage = options; 
      }
    },
    onDurationKeyDown(event) {
      onDurationKeyDown(event, false);
    },
    onDurationKeyUp(event) {
      this.duration.value = onDurationKeyUp(event, this.duration.value);
    },
    onFixedDurationKeyDown(event) {
      onDurationKeyDown(event, false);
    },
    onFixedDurationKeyUp(event) {
      this.task.fixedDuration = onDurationKeyUp(event, this.task.fixedDuration);
    },
    onEtocKeyDown(event) {
      onDurationKeyDown(event, false);
    },
    onEtocKeyUp(event) {
      this.etocInput = onDurationKeyUp(event, this.etocInput);
    },
    onEtocInput: debounce(function(value) {
      this.calcDurationWhenEtocChanged(value || '0D');
    }, 100),
    progressAddMinus(delta) {
      if(isNaN(this.task.progress)) {
        this.task.progress = 0;
      }
      const value = parseInt(this.task.progress);
      this.task.progress = value + delta;
      // round to nearest 10
      if (this.task.progress % 10 !== 0) {
        if (delta > 0) {
          this.task.progress = Math.ceil(this.task.progress / 10) * 10;
        }
        else {
          this.task.progress = Math.floor(this.task.progress / 10) * 10;
        }
      }
      this.progressFormat();
    },
    progressComplete() {
      this.task.progress = 100;
      this.progressFormat();
    },
    durationAddMinus(delta) {
      this.duration.value = incrementDuration(this.duration.value != null? this.duration.value : '0D', delta);
      this.initDurationCalculation();
    },
    fixedDurationAddMinus(delta) {
      this.task.fixedDuration = incrementDuration(this.task.fixedDuration ? this.task.fixedDuration : '0D', delta);
    },
    etocAddMinus(delta) {
      this.etocInput = incrementDuration(this.etocInput != null? this.etocInput : '0D', delta);
      this.calcDurationWhenEtocChanged(this.etocInput);
    },
    calcDurationWhenEtocChanged(newEtoc) {
      const etocValue = newEtoc != null? convertDisplayToDuration(newEtoc, this.durationConversionOpts).value : 0;
      const totalWorkEffort = convertDisplayToDuration(this.totalWorkEffort, this.durationConversionOpts).value;
      this.duration.value = convertDurationToDisplay(etocValue + totalWorkEffort, 'D', this.durationConversionOpts);
      this.initDurationCalculation();
    },
    typeChanged() {
      this.task.lockDuration = false;
      if (this.task.taskType === "Milestone") {
        this.$set(this.duration, 'value', '0D');
        let startTime = this.original.newStartTime;
        if (this.exists) {
          startTime = this.original.startTime;
        }
        if (startTime == null) {
          const dateTimeObj = this.getSuggestedStartTimeObj();
          startTime = dateTimeObj.dateTimeStr;
        }

        const index = startTime.indexOf('T');
        if (index > -1) {
          this.$set(this.duration, 'startDate', startTime.substring(0, index));
          this.$set(this.duration, 'startTime', startTime.substring(index+1));
        } else {
          this.$set(this.duration, 'startDate', startTime);  
        }
        this.$set(this.duration, 'closeDate', this.duration.startDate);
        this.$set(this.duration, 'closeTime', this.duration.startTime);
        this.$set(this.duration, 'value', '0D');
        return;
      }
      else if (this.task.taskType === "Project") {
        this.$set(this.duration, 'startDate', null);
        this.$set(this.duration, 'startTime', null);
        this.$set(this.duration, 'closeDate', null);
        this.$set(this.duration, 'closeTime', null);
        this.$set(this.duration, 'value', null);
        this.$set(this.constraint, 'type', 'As_soon_as_possible');
        this.$set(this.constraint, 'date', null);
        return;
      }
      //When Task.taskType is 'Task'
      if (this.exists) {
        const dateTime = this.original.startTime;
        const index = dateTime != null? dateTime.indexOf('T') : -1;
        if (index > -1) {
          this.$set(this.duration, 'startDate', dateTime.substring(0, index));
          this.$set(this.duration, 'startTime', dateTime.substring(index+1));
        } else {
          this.$set(this.duration, 'startDate', dateTime);
          this.$set(this.duration, 'startTime', null);
        }
        this.$set(this.duration, 'closeDate', null);
        this.$set(this.duration, 'closeTime', null);
      } else {
        if (this.project?.scheduleMode === 'ALAP') {
          const newCloseTimeObj = this.getSuggestedCloseTimeObj();
          this.$set(this.duration, 'startDate', null);
          this.$set(this.duration, 'startTime', null);
          this.$set(this.duration, 'closeDate', newCloseTimeObj.dateStr);
          this.$set(this.duration, 'closeTime', newCloseTimeObj.timeStr);
          this.$set(this.constraint, 'type', 'As_late_as_possible');
        } else {
          const newStartTimeObj = this.getSuggestedStartTimeObj();
          this.$set(this.duration, 'startDate', newStartTimeObj.dateStr);
          this.$set(this.duration, 'startTime', newStartTimeObj.timeStr);
          this.$set(this.duration, 'closeDate', null);
          this.$set(this.duration, 'closeTime', null);
          this.$set(this.constraint, 'type', 'As_soon_as_possible');
        }
      }
      this.$set(this.duration, 'value', '1D');
      const _progress = isNaN(this.original.progress)? 0 : this.original.progress;
      this.$set(this.task, 'progress', _progress);
      this.$set(this.constraint, 'date', null);
      
      this.$nextTick(() => {
        this.initDurationCalculation({});
      });
    },
    getSuggestedStartTimeObj() {
      const returnObj = { 
        dateStr: null
        , timeStr: null
        , dateTimeStr: null
      }
      //Get the local date time literally and set it as UTC time. (Specification given by Paul)
      const { dateStr } = this.getTodayLocalDate();
      
      //Set the earliest work hour for a new task's start time.
      const cal = this.calendar;
      
      if (cal != null) {
        let hasWorkday = false;
        for (const weekday of WEEKDAY) {
          if (Array.isArray(cal[weekday]) && cal[weekday].length > 0 && cal[weekday][0].isWorking == true) {
            hasWorkday = true;
            break;
          }
        }
        if (hasWorkday) {
          let curDate = moment.utc(dateStr, 'YYYY-MM-DD');
          while (!isWorkingDay(cal, curDate)) {
            curDate.add(1, 'days');
          }
          
          const timeStr = getEarliestOrLatestWorkHour(cal, curDate);
          if (timeStr != null) {
            returnObj.dateStr = curDate.format('YYYY-MM-DD');
            returnObj.timeStr = timeStr;
            returnObj.dateTimeStr = this.constructDateTimeStr(returnObj.dateStr, timeStr);
          }
        }
      }
    
      if (returnObj.timeStr == null) {
        returnObj.dateStr = dateStr;
        returnObj.timeStr = '09:00'; //default: assume no valid work hour/time
        returnObj.dateTimeStr = this.constructDateTimeStr(dateStr, '09:00');
      }
      
      return returnObj;
    },
    getSuggestedCloseTimeObj() {
      const returnObj = { 
        dateStr: null
        , timeStr: null
        , dateTimeStr: null
      }
      //Get the local date time literally and set it as UTC time. (Specification given by Paul)
      const { dateStr } = this.getTodayLocalDate();
      
      //Set the earliest work hour for a new task's start time.
      const cal = this.calendar;
      
      if (cal != null) {
        let hasWorkday = false;
        for (const weekday of WEEKDAY) {
          if (Array.isArray(cal[weekday]) && cal[weekday].length > 0 && cal[weekday][0].isWorking == true) {
            hasWorkday = true;
            break;
          }
        }
        if (hasWorkday) {
          let curDate = moment.utc(dateStr, 'YYYY-MM-DD');
          while (!isWorkingDay(cal, curDate)) {
            curDate.subtract(1, 'days');
          }
          
          const timeStr = getEarliestOrLatestWorkHour(cal, curDate, { isLatest: true });
          if (timeStr != null) {
            returnObj.dateStr = curDate.format('YYYY-MM-DD');
            returnObj.timeStr = timeStr;
            returnObj.dateTimeStr = this.constructDateTimeStr(returnObj.dateStr, timeStr);
          }
        }
      }
    
      if (returnObj.timeStr == null) {
        returnObj.dateStr = dateStr;
        returnObj.timeStr = '17:00'; //default: assume no valid work hour/time
        returnObj.dateTimeStr = this.constructDateTimeStr(dateStr, '17:00');
      }
      
      return returnObj;
    },
    progressFormat() {
      if(isNaN(this.task.progress)) {
        this.task.progress = 0;
      }
      const value = parseInt(this.task.progress);
      if(value < 0) {
        this.task.progress = 0;
      } else if(value > 100) {
        this.task.progress = 100;
      } else {
        this.task.progress = value;
      }
    },
    predecessorChange(result) {
      if(result.changed.isNew) {
        delete result.changed.isNew;
        this.predecessorData.push(result.changed);
      } else {
        const index = this.predecessorData.findIndex(i => i.uuId === result.old.uuId);
        if(index > -1) {
          this.predecessorData.splice(index, 1, result.changed);
        }
      }
    },
    predecessorDelete(predecessorIds) {
      let index = -1;
      predecessorIds.forEach(id => {
        index = this.predecessorData.findIndex(i => i.uuId === id);
        if(index > -1) {
          this.predecessorData.splice(index, 1);
        }
      });
    },
    populateTaskNames: debounce(async function(/** value */) {
      // update the task name search list wait 1 second
      this.taskNames = await taskService.listNames(this.task.name);
    }, 1000),
    async populateTaskTree() {
      if (this.projectId === null) {
        return;
      }

      const projectId = this.projectId;
      const taskId = this.exists? this.id : null;
      const taskName = this.task.name;
      const dispatchName = `data/${this.isTemplate? 'templateTaskListTree' : 'taskListSummaryTree'}`;

      let list = await this.$store.dispatch(dispatchName, { params: { start: 0, limit: -1 }, projectId })
      .then(response => {
        return response? response.data:[];
      })
      .catch(e => {
        this.httpAjaxError(e);
        return [];
      });
      
      // make sure to copy the list before modifying it
      list = cloneDeep(list);
      
      const rootType = this.isTemplate ? this.$t('task.option.rootTemplate') : this.$t('task.option.root');
      const projectName = this.project.name !== null ? this.project.name : this.projectName !== null ? this.projectName : this.$store.state.breadcrumb.contextName;
      const rootname = projectName !== null ? `${rootType} (${projectName})` : this.$t('task.option.root');
      const projectStartTime = this.project.startDateStr != null?  moment.utc(this.project.startDateStr, 'YYYY-MM-DD').valueOf() : 0;
      list.unshift({ uuId: 'ROOT', name: rootname, pUuId: null, startTime: projectStartTime });

      const l = list.map(i => { return { id: i.uuId, label: i.name, parentId: i.pUuId }});
      this.parentCandidateList = cloneDeep(list);
      // let thisTask = null;
      this.taskNames = [];
      const parentList = cloneDeep(l);
      
      l.forEach(i => {
        const filtered = l.filter(j => j.parentId == i.id);
        if(filtered.length > 0) {
          i.children = l.filter(j => j.parentId == i.id);
        }
        if(taskId && taskId === i.id) {
          // thisTask = i;
          i.isDisabled = true;
          if(taskName) {
            i.label = taskName;
          }
        }
      });
      
      parentList.forEach(i => {
        const filtered = parentList.filter(j => j.parentId == i.id);
        if(filtered.length > 0) {
          i.children = parentList.filter(j => j.parentId == i.id);
        }
        if(taskId && taskId === i.id) {
          // thisTask = i;
          i.isDisabled = true;
          if(taskName) {
            i.label = taskName;
          }
        }
      });
      
      this.optionTask.splice(0, this.optionTask.length, ...parentList.filter(i => i.parentId == null))
    },
    skillBadgeRemove: function(index) {
      this.skills.splice(index,1)
    },
    skillBadgeClick(id) {
      const selected = this.skills.find(i => i.uuId === id);
      const edit = this.skillLevelEdit;
      edit.uuId = selected.uuId;
      edit.name = selected.name;
      edit.level = selected.level;
      edit.data = selected;
      this.state.skillLevelEditShow = true;
    },
    imageSelectorToggle() {
      this.state.selectingImage = true;
      this.state.fileSelectorShow = true;
    },
    imageRemove() {
      this.task.avatarRef = "00000000-0000-0000-0000-000000000000";
    },
    async fileAccessUpdate(uuId) {
      const result = {
        hasError: false,
        msg: null
      }
      const form = new FormData();
      form.append('uuId', uuId);
      form.append('accessLevel', 'Public');
      const self = this;
      await fileService.update(form)
      .catch(() => {
        result.hasError = true;
        result.msg = self.$i18n.t('avatar_banner.error.failed_to_set', [self.$i18n.t('avatar_banner.field.avatar')]);
      });
      return result;
    },
    skillSelectorToggle() {
      this.state.skillSelectorShow = true;
    },
    skillSelectorOk({ details }) {
      const newSkills = details.map(i => { return { uuId: i.uuId, name: i.name, level: null, color: i.color, show: false }});
      this.toConfirmSkills.splice(0, this.toConfirmSkills.length, ...newSkills);
      for(let i = this.toConfirmSkills.length-1; i > -1; i--) {
        this.toConfirmSkills[i].toBeShown = true;
      }
      this.toConfirmSkills[this.toConfirmSkills.length - 1].toBeShown = false;
      this.toConfirmSkills[this.toConfirmSkills.length - 1].show = true;
    },
    skillLevelOk({ id, name, level, oldId, customFields, customData }) {
      const oldIdIndex = oldId? this.skills.findIndex(i => i.uuId === oldId) : -1;
      let selected = null;
      if(oldIdIndex != -1) {
        selected= this.skills[oldIdIndex];
      } else {
        selected = this.skills.find(i => i.uuId === id);
      }
      selected.uuId = id;
      selected.name = name;
      selected.level = level;
      for (const field of customFields) {
        selected[field.name] = customData[field.name];
      }
    },
    toConfirmSkillOk({ id, name, level, oldId, applyToAll, customFields, customData }) {
      const oldIdIndex = oldId? this.toConfirmSkills.findIndex(i => i.uuId === oldId) : -1;
      let selected = null;
      if(oldIdIndex != -1) {
        selected = this.toConfirmSkills[oldIdIndex];
        selected.uuId = id;
        selected.name = name;
      } else {
        selected = this.toConfirmSkills.find(i => i.uuId === id);
      }

      selected.level = level;
      selected.customFields = customFields;
      selected.customData = customData;
      
      if (selected.color) {
        this.task.color = selected.color;
      }
      
      const toBeShown = this.toConfirmSkills.filter(i => i.toBeShown);
      if(toBeShown.length === 0) {
        this.toConfirmSkillCommit();
      }
      else if (applyToAll) {
        for (const entry of toBeShown) {
          entry.toBeShown = false;
          entry.level = level;
          entry.customFields = customFields;
          entry.customData = customData;
        }
        this.toConfirmSkillCommit();
      }
      else {
        toBeShown[toBeShown.length - 1].toBeShown = false;
        toBeShown[toBeShown.length - 1].show = true;
      }
    },
    toConfirmSkillCancel() {
      this.toConfirmSkillCommit();
    },
    toConfirmSkillCommit() {
      console.log('toConfirmSkillCommit logic1 this.toConfirmSkills', this.toConfirmSkills) //eslint-disable-line no-console
      //Set all show state to false. To close/hide remaining modals if any.
      for(let i = 0, len = this.toConfirmSkills.length; i < len; i ++) {
        this.toConfirmSkills[i].show = false;
      }
      const confirmedSkills = this.toConfirmSkills.filter(i => i.level && i.level.length > 0);
      const ids = confirmedSkills.map(i => i.uuId);
      const currentIds = this.skills.map(i => i.uuId);
      const duplicatedIds = currentIds.filter(i => ids.includes(i));
      const newSkills = confirmedSkills.filter(i => !currentIds.includes(i.uuId));

      let skill = null;
      for(let i = 0, len = this.skills.length; i < len; i++) {
        skill = this.skills[i];
        if(duplicatedIds.includes(skill.uuId)) {
          const confirmed = confirmedSkills.find(i => i.uuId === skill.uuId);
          skill.level = confirmed.level;
          for (const field of confirmed.customFields) {
            skill[field.name] = confirmed.customData[field.name];
          }
        }
      }
      
      newSkills.forEach(i => {
        if (this.task.name === null) {
          this.$refs.typeahead.inputValue = i.name;
          this.task.name = i.name;
        }
        
        const skill = {uuId: i.uuId, name: i.name, level: i.level};
        
        for (const field of i.customFields) {
          skill[field.name] = i.customData[field.name];
        }
        this.skills.push( skill );
      });
    },
    staffBadgeRemove: function(index) {
      this.staffs.splice(index,1)
    },
    staffBadgeClick(id) {
      const selected = this.staffs.find(i => i.uuId === id);
      const edit = this.staffUtilizationEdit;
      edit.uuId = selected.uuId;
      edit.name = selected.name;
      edit.utilization = selected.utilization;
      edit.unit = selected.quantity;
      edit.genericStaff = selected.genericStaff;
      edit.data = selected;
      this.state.staffUtilizationEditShow = true;
    },
    staffSelectorToggle() {
      this.state.staffSelectorShow = true;
    },
    async staffSelectorOk({ details }) {
      const newStaffs = details.map(i => { 
        return { 
          uuId: i.uuId
          , name: i.name
          , utilization: null
          , show: false
          , duration: i.duration
          , genericStaff: i.genericStaff 
        }
      });
      this.toConfirmStaffs.splice(0, this.toConfirmStaffs.length, ...newStaffs);
      for(let i = this.toConfirmStaffs.length-1; i > -1; i--) {
        this.toConfirmStaffs[i].toBeShown = true;
      }
      this.toConfirmStaffs[this.toConfirmStaffs.length - 1].toBeShown = false;
      this.toConfirmStaffs[this.toConfirmStaffs.length - 1].show = true;
      
      if (typeof details[0].rebates === 'undefined') {
        details[0].rebates = await staffService.list({ holder: details[0].uuId })
        .then(response => {
          return response.data.length > 0 ? response.data[0].rebates : [];
        });
      }
      
      if (details[0].rebates &&
          details[0].rebates.length > 0) {
        this.rebatesToApply = [];
        for (const det of details) {
          for (const r of det.rebates) {
            if (this.rebates.findIndex(reb => reb.uuId === r.uuId) === -1) {
              this.rebatesToApply.push(r);
            } 
          }
        }
      }
    },
    staffUtilizationOk({ id, name, utilization, unit, oldId, genericStaff, customFields, customData }) {
      const oldIdIndex = oldId? this.staffs.findIndex(i => i.uuId === oldId) : -1;
      let selected = null;
      if(oldIdIndex != -1) {
        selected= this.staffs[oldIdIndex];
      } else {
        selected = this.staffs.find(i => i.uuId === id);
      }
      if(selected != null) {
        selected.uuId = id;
        selected.name = name;
        selected.utilization = utilization;
        selected.quantity = unit;
        for (const field of customFields) {
          selected[field.name] = customData[field.name];
        }
      } else {
        const staff = {
          uuId: id,
          name: name,
          utilization: utilization,
          quantity: unit,
          genericStaff: !!genericStaff
        };
        
        for (const field of customFields) {
          staff[field.name] = customData[field.name];
        }
        this.staffs.push(staff);
      }
    },
    toConfirmStaffOk({ id, name, utilization, unit, oldId, applyToAll, genericStaff, customFields, customData }) {
      const oldIdIndex = oldId? this.toConfirmStaffs.findIndex(i => i.uuId === oldId) : -1;
      let selected = null;
      if(oldIdIndex != -1) {
        selected= this.toConfirmStaffs[oldIdIndex];
        selected.uuId = id;
        selected.name = name;
      } else {
        selected = this.toConfirmStaffs.find(i => i.uuId === id);
      }
      
      selected.utilization = utilization;
      selected.quantity = unit;
      selected.customFields = customFields;
      selected.customData = customData;
      
      if(genericStaff != undefined) {
        selected.genericStaff = genericStaff;
      }
      const toBeShown = this.toConfirmStaffs.filter(i => i.toBeShown);
      if(toBeShown.length === 0) {
        this.toConfirmStaffCommit();
      }
      else if (applyToAll) {
        for (const entry of toBeShown) {
          entry.toBeShown = false;
          entry.utilization = utilization;
          entry.quantity = unit;
          entry.customFields = customFields;
          entry.customData = customData;
        }
        this.toConfirmStaffCommit();
      }
      else {
        toBeShown[toBeShown.length - 1].toBeShown = false;
        toBeShown[toBeShown.length - 1].show = true;
      }
    },
    toConfirmStaffCancel() {
      this.toConfirmStaffCommit();
    },
    toConfirmStaffCommit() {
      //Set all show state to false. To close/hide remaining modals if any.
      for(let i = 0, len = this.toConfirmStaffs.length; i < len; i ++) {
        this.toConfirmStaffs[i].show = false;
      }
      const confirmedStaffs = this.toConfirmStaffs.filter(i => i.utilization != null);
      const ids = confirmedStaffs.map(i => i.uuId);
      const currentIds = this.staffs.map(i => i.uuId);
      const duplicatedIds = currentIds.filter(i => ids.includes(i));
      const newStaffs = confirmedStaffs.filter(i => !currentIds.includes(i.uuId));
      
      let staff = null;
      for(let i = 0, len = this.staffs.length; i < len; i++) {
        staff = this.staffs[i];
        if(duplicatedIds.includes(staff.uuId)) {
          const found = confirmedStaffs.find(i => i.uuId === staff.uuId);
          if (found) {
            staff.utilization = found.utilization;
            staff.quantity = found.quantity;
            for (const field of found.customFields) {
              staff[field.name] = found.customData[field.name];
            }
          }
        }
      }

      let duration = 0;
      newStaffs.forEach(i => {
        if (i.duration > duration) {
          duration = i.duration;
        }
        
        const staff = {uuId: i.uuId, name: i.name, utilization: i.utilization, quantity: i.quantity, genericStaff: !!i.genericStaff};
        for (const field of i.customFields) {
          staff[field.name] = i.customData[field.name];
        }
        this.staffs.push( staff );
      });
      
      if (duration !== 0) {
        this.duration.value = `${Math.round(duration / 8 / 60)}D`;
        this.initDurationCalculation({});
      }
      
      if (this.rebatesToApply &&
          this.rebatesToApply.length > 0) {
        this.promptApplyRebates = true;  
      }
    },
    staffWorkEffortToggle() {
      if (this.canView(this.permissionName, ['STAFF.duration', 'STAFF.durationAUM'])) {
        this.state.staffWorkEffortShow = true;
      }
    },
    staffWorkEffortOk(workEfforts) {
      for(const workEffort of workEfforts) {
        const index = this.staffs.findIndex(i => i.uuId === workEffort.uuId);
        if(index > -1) {
          this.$set(this.staffs[index], 'duration', workEffort.duration);
          this.$set(this.staffs[index], 'durationAUM', workEffort.durationAUM);
        }
      }
    },
    async updateStaffs(taskId) {
      const service = this.isTemplate? templateTaskLinkStaffService : taskLinkStaffService;
      const result = {
        hasError: false,
        errorCodes: []
      }
      const staffs = cloneDeep(this.staffs);
      const originStaffs = this.original.staffs;
      const toAdd = [];
      const toUpdate = [];
      const unchangedIds = [];
      for(let i = 0, len = staffs.length; i < len; i++) {
        const staff = staffs[i];
        const index = originStaffs.findIndex(j => j.uuId === staff.uuId);
        if(index == -1) {
          toAdd.push(staff);
        } else {
          const originStaff = originStaffs[index];
          if(compareKeys(staff, originStaff)) {
            toUpdate.push(staff);
          } else {
            unchangedIds.push(staff.uuId);
          }
        }
      }

      const toAddIds = toAdd.map(i => i.uuId);
      const toUpdateIds = toUpdate.map(i => i.uuId);
      const toRemove = originStaffs.filter(i => !toAddIds.includes(i.uuId) && !toUpdateIds.includes(i.uuId) && !unchangedIds.includes(i.uuId));
      
      //To add new staffs if there is any.
      if(toAdd.length > 0) {
        let { needUpdate=[], hasError=false, errorCode=null } = await service.create(taskId, toAdd)
        .then(response => {
          if(207 == response.status) {
            const list = response.data[response.data.jobCase];
            const toUpdateIds = list.filter(i => i.clue === 'Already_have_edge').map(i => i.args[0]);
            const failIds = list.filter(i => i.clue !== 'Already_have_edge' && i.clue !== 'OK').map(i => i.args[0]);
            return { 
              needUpdate: toAdd.filter(i => toUpdateIds.includes(i.uuId)), 
              hasError: failIds.length > 0,
              errorCode: 207 };
          }
          return {}
        })
        .catch(e => {
          if(e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            const toUpdateIds = list.filter(i => i.clue === 'Already_have_edge').map(i => i.args[0]);
            return { 
              needUpdate: toAdd.filter(i => toUpdateIds.includes(i.uuId)), 
              hasError: toAdd.filter(i => !toUpdateIds.includes(i.uuId)).length > 0,
              errorCode: 422 };
          } else {
            return { 
              hasError: true, 
              errorCode: e != null && e.response != null? e.response.status : null }
          }
        });
        if(hasError) {
          result.hasError = true;
          result.errorCodes.push(errorCode);
        }
        //Collect those staffs require Update (instead of add) and add them to toUpdate list.
        toUpdate.splice(toUpdate.length, 0, ...needUpdate);
      }

      //To update existing staffs if there is any.
      if(toUpdate.length > 0) {
        let{ needAdd=[], hasError=false, errorCode=null } = await service.update(taskId, toUpdate)
        .then(response => {
          if(207 == response.status) {
            const list = response.data[response.data.jobCase];
            const toAddIds = list.filter(i => i.clue === 'Unknown_relation').map(i => i.args[0]);
            const failIds = list.filter(i => i.clue !== 'Unknown_relation' && i.clue !== 'OK').map(i => i.args[0]);
            return { 
              needAdd: toUpdate.filter(i => toAddIds.includes(i.uuId)), 
              hasError: failIds.length > 0,
              errorCode: 207
            };
          }
          return {}
        })
        .catch(e => {
          if(e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            const toAddIds = list.filter(i => i.clue === 'Unknown_relation').map(i => i.args[0]);
            return { 
              needAdd: toUpdate.filter(i => toAddIds.includes(i.uuId)), 
              hasError: toUpdate.filter(i => !toAddIds.includes(i.uuId)).length,
              errorCode: 422
            };
          } else {
            return { hasError: true, errorCode: e != null && e.response != null? e.response.status : null }
          }
        });
        if(hasError) {
          result.hasError = true;
          result.errorCodes.push(errorCode);
        }
        

        //To add staffs which require Add operation (instead of Update) if there is any.
        if(needAdd.length > 0) {
          let { hasError=false, errorCode=null } = await service.create(taskId, needAdd)
          .then(response => {
            if(207 == response.status) {
              const list = response.data[response.data.jobCase];
              const failIds = list.filter(i => i.clue !== 'OK').map(i => i.args[0]);
              return { hasError: failIds.length > 0, errorCode: 207 };
            }
            return {}
          })
          .catch(e => {
            if(e.response && 422 == e.response.status) {
              const list = e.response.data[e.response.data.jobCase];
              const toUpdateIds = list.filter(i => i.clue === 'Already_have_edge').map(i => i.args[0]);
              return { 
                needUpdate: toAdd.filter(i => toUpdateIds.includes(i.uuId)), 
                hasError: toAdd.filter(i => !toUpdateIds.includes(i.uuId)).length > 0,
                errorCode: 422
              };
            } else {
              return { hasError: true, errorCode: e != null && e.response != null? e.response.status : null }
            }
          });
          if(hasError) {
            result.hasError = true;
            result.errorCodes.push(errorCode);
          }
        }
      }

      //Try remove staffs which user wants to remove if there is any
      if(toRemove.length > 0) {
        const clues = ['OK', 'Unknown_relation'];
        let { hasError=false, errorCode=null } = await service.remove(taskId, toRemove)
        .then(response => {
          if(207 == response.status) {
            const list = response.data[response.data.jobCase];
            const failIds = list.filter(i => !clues.includes(i.clue)).map(i => i.args[0]);
            return { hasError: toRemove.filter(i => failIds.includes(i.uuId)).length > 0, errorCode: 207 };
          }
          return {};
        })
        .catch(e => {
          if(e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            const failIds = list.filter(i => !clues.includes(i.clue)).map(i => i.args[0]);
            return { hasError: toRemove.filter(i => failIds.includes(i.uuId)) > 0, errorCode: 422 };
          } else {
            return { hasError: true, errorCode: e != null && e.response != null? e.response.status : null }
          }
        });
        if(hasError) {
          result.hasError = true;
          result.errorCodes.push(errorCode);
        }
      }

      return result;
    },
    resourceBadgeRemove: function(index) {
      this.resources.splice(index,1)
    },
    resourceBadgeClick(id) {
      const selected = this.resources.find(i => i.uuId === id);
      const edit = this.resourceUnitEdit;
      edit.uuId = selected.uuId;
      edit.name = selected.name;
      edit.unit = selected.quantity;
      edit.utilization = selected.utilization;
      edit.data = selected;
      this.state.resourceUnitEditShow = true;
    },
    resourceSelectorToggle() {
      this.state.resourceSelectorShow = true;
    },
    resourceSelectorOk({ details }) {
      const newResources = details.map(i => { return { uuId: i.uuId, name: i.name, unit: null, show: false }});
      this.toConfirmResources.splice(0, this.toConfirmResources.length, ...newResources);
      for(let i = this.toConfirmResources.length-1; i > -1; i--) {
        this.toConfirmResources[i].toBeShown = true;
      }
      this.toConfirmResources[this.toConfirmResources.length - 1].toBeShown = false;
      this.toConfirmResources[this.toConfirmResources.length - 1].show = true;
    },
    resourceUnitOk({ id, name, unit, utilization, oldId, customFields, customData }) {
      const oldIdIndex = oldId? this.resources.findIndex(i => i.uuId === oldId) : -1;
      let selected = null;
      if(oldIdIndex != -1) {
        selected = this.resources[oldIdIndex];
      } else {
        selected = this.resources.find(i => i.uuId === id);
      }
      if (selected != null) {
        selected.uuId = id;
        selected.name = name;
        selected.quantity = unit;
        selected.utilization = utilization;
        for (const field of customFields) {
          selected[field.name] = customData[field.name];
        }
      }
      else {
        const res = {uuId: id, name: name, quantity: unit, utilization: utilization}
        for (const field of customFields) {
          res[field.name] = customData[field.name];
        }
        this.resources.push( res );
      }
    },
    toConfirmResourceOk({ id, name, unit, utilization, oldId, applyToAll, customFields, customData }) {
      const oldIdIndex = oldId? this.toConfirmResources.findIndex(i => i.uuId === oldId) : -1;
      let selected = null;
      if(oldIdIndex != -1) {
        selected = this.toConfirmResources[oldIdIndex];
        selected.uuId = id;
        selected.name = name;
      } else {
        selected = this.toConfirmResources.find(i => i.uuId === id);
      }

      selected.unit = unit;
      selected.utilization = utilization;
      selected.customFields = customFields;
      selected.customData = customData;
      const toBeShown = this.toConfirmResources.filter(i => i.toBeShown);
      if(toBeShown.length === 0) {
        this.toConfirmResourceCommit();
      }
      else if (applyToAll) {
        for (const entry of toBeShown) {
          entry.toBeShown = false;
          entry.unit = unit;
          entry.utilization = utilization;
          entry.customFields = customFields;
          entry.customData = customData;
        }
        this.toConfirmResourceCommit();
      }
      else {
        toBeShown[toBeShown.length - 1].toBeShown = false;
        toBeShown[toBeShown.length - 1].show = true;
      }
    },
    toConfirmResourceCancel() {
      this.toConfirmResourceCommit();
    },
    toConfirmResourceCommit() {
      //Set all show state to false. To close/hide remaining modals if any.
      for(let i = 0, len = this.toConfirmResources.length; i < len; i ++) {
        this.toConfirmResources[i].show = false;
      }
      const confirmedResources = this.toConfirmResources.filter(i => i.unit && i.unit > 0);
      const ids = confirmedResources.map(i => i.uuId);
      const currentIds = this.resources.map(i => i.uuId);
      const duplicatedIds = currentIds.filter(i => ids.includes(i));
      const newResources = confirmedResources.filter(i => !currentIds.includes(i.uuId));

      let resource = null;
      for(let i = 0, len = this.resources.length; i < len; i++) {
        resource = this.resources[i];
        if(duplicatedIds.includes(resource.uuId)) {
          const confirmed = confirmedResources.find(i => i.uuId === resource.uuId);
          resource.quantity = confirmed.unit;
          resource.utilization = confirmed.utilization;
          
          for (const field of confirmed.customFields) {
            resource[field.name] = confirmed.customData[field.name];
          }
        }
      }

      newResources.forEach(i => {
        const res = {uuId: i.uuId, name: i.name, quantity: i.unit, utilization: i.utilization};
      
        for (const field of i.customFields) {
          res[field.name] = i.customData[field.name];
        }
        this.resources.push( res );
      });
    },
    fileBadgeRemove: function(index) {
      this.files.splice(index,1);
    },
    fileBadgeClick(item) {
      // file download
      this.downloadProgressShow = true;
      forceFileDownload([{ uuId: item.uuId, name: labelFilename(item.name, item.type), type: item.type }], this);
    },
    fileSelectorToggle() {
      this.state.selectingImage = false;
      this.state.fileSelectorShow = true;
    },
    fileSelectorOk(details) {
      if (this.state.selectingImage) {
        const imageFile = details[0];
        this.fileAccessUpdate(imageFile.uuId);
        this.task.avatarRef = imageFile.uuId;
      }
      else {
        const currentIds = this.files.map(i => i.uuId);
        const newFiles = details.filter(i => !currentIds.includes(i.uuId));
  
        for(const c of newFiles) {
          this.files.push( {uuId: c.uuId, name: c.name, type: c.type} );
        }
      }
    },
    downloadCancel() {
      if(this.downloadCancelTokenSource) {
        this.downloadCancelled = true;
        this.downloadCancelTokenSource.cancel();
      }
    },
    labelFilename(name, type) {
      return labelFilename(name, type);
    },
    preStaffSelectorToggle() {
      if(this.skillsChanged || this.durationChanged()) {
        this.state.alertTaskChangedShow = true;
      } else {
        this.staffSelectorToggle();
      }
    },
    rebateSelectorToggle() {
      this.rebateEdit.uuId = null; //reset value
      this.state.rebateSelectorShow = true;
    },
    rebateSelectorOk({details}) {
      const rebates = JSON.parse(JSON.stringify(this.rebates));
      
      if (details.length > 0) {
        const newRebatesToReplacePreselected = [];
        for (const r of details) {
          const foundIndex = rebates.findIndex(i => i.uuId == r.uuId);
          if (foundIndex > -1) {
            const rebate = rebates[foundIndex];
            rebate.uuId = r.uuId;
            rebate.name = r.name;
            rebate.rebate = r.rebate;
            newRebatesToReplacePreselected.push(rebate);
            rebates.splice(foundIndex, 1);
          } else {
            newRebatesToReplacePreselected.push({ uuId: r.uuId, name: r.name, rebate: r.rebate })
          }
        }

        if (this.rebateEdit.uuId != null) {
          const foundIndex = rebates.findIndex(i => i.uuId == this.rebateEdit.uuId);
          if (foundIndex != -1) {
            rebates.splice(foundIndex, 1, ...newRebatesToReplacePreselected);
          } else {
            rebates.push(...newRebatesToReplacePreselected);
          }
        } else {
          rebates.push(...newRebatesToReplacePreselected);
        }
      } else if (this.rebateEdit.uuId != null) {
        //When no selection is made, remove the preselected one from the existing rebates
        rebates.splice(rebates.findIndex(j => j.uuId === this.rebateEdit.uuId), 1);
      }
      this.rebates.splice(0, this.rebates.length, ...rebates);

      this.rebateEdit = { uuId: null };
    },
    rebateBadgeRemove: function(index) {
      this.rebates.splice(index,1)
    },
    rebateBadgeClick(id) {
      const selected = this.rebates.find(i => i.uuId === id);
      const edit = this.rebateEdit;
      edit.uuId = selected.uuId;
      edit.name = selected.name;
      edit.rebate = selected.rebate;
      this.state.rebateSelectorShow = true;
    },
    validateCurrencyCode() {
      if(this.task.fixedCost != null && !this.task.currencyCode) {
        this.errors.add({
          field: `task.currencyCode`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('task.field.currencyCode')])
        });
        return false;
      } else {
        return true;
      }
    },
    validateConstraintDate() {
      if(this.constraint.type != CONSTRAINT_TYPES.ASAP && 
          this.constraint.type != CONSTRAINT_TYPES.ALAP && 
          (this.constraint.date == null || this.constraint.date.trim().length == 0)) {
        this.errors.add({
          field: `constraint.date`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('task.field.constraintTime')])
        });
        return false;
      } else {
        return true;
      }
    },
    async updateStage(taskId) {
      const service = taskLinkStageService;
      const result = {
        hasError: false,
        errorCodes: []
      }

      // If already linked to a stage and we picked a new one, remove the old link first
      if (this.original.stage && this.stage != this.original.stage) {
        let { hasError=false, errorCode=null } = await service.remove(taskId, {uuId: this.original.stage})
        .then(response => {
          if(207 == response.status) {
            return { hasError: true, errorCode: 207 }
          }
          return {}
        })
        .catch(e => {
          return { hasError: true, errorCode: e != null && e.response != null? e.response.status : null }
        });
        
        if(hasError) {
          result.hasError = true;
          result.errorCodes.push(errorCode);
        }
      }

      // If the chosen stage differs from the old one, we need to create a new link
      // Null stage is allowed (stage is not mandatory). We just skip making a link in this case.
      if (!result.hasError && this.original.stage != this.stage && this.stage != null) {
        let { hasError=false, errorCode=null } = await service.create(taskId, {uuId: this.stage})
        .then(response => {
          if(207 == response.status) {
            return { hasError: true, errorCode: 207 }
          }
          return {}
        })
        .catch(e => {
          return { hasError: true, errorCode: e != null && e.response != null? e.response.status : null }
        });
        if(hasError) {
          result.hasError = true;
          result.errorCodes.push(errorCode);
        }
      }
      
      if (!result.hasError) {    
        this.original.stage = this.stage;
      }
      return result; 
    },
    handleImgError(/* evt */) {
      this.task.avatarRef = null; // avatar image could not be loaded
    },
    constraintTypeChanged(value) {
      if('As_late_as_possible' === value || 'As_soon_as_possible' === value) {
        this.$set(this.constraint, 'date', null);
      }
    },
    toggleLockDuration() {
      this.task.lockDuration = !this.task.lockDuration;
      this.initDurationCalculation({});
    },
    durationCalculationOk({ startDateStr, startTimeStr, closeDateStr, closeTimeStr, durationDisplay, constraintType, constraintDateStr, taskAutoScheduleMode=null }) {

      //Disable event before updating duration related properties.
      this.toggleDateTimeCalcEvent(false);

      if (this.duration.startDate != startDateStr) {
        this.duration.startDate = startDateStr;
      }
      if (this.duration.startTime != startTimeStr) {
        this.duration.startTime = startTimeStr;
      }
      
      if (this.duration.closeDate != closeDateStr) {
        this.duration.closeDate = closeDateStr;
      }
      if (this.duration.closeTime != closeTimeStr) {
        this.duration.closeTime = closeTimeStr;
      }
      if (this.duration.value != durationDisplay) {
        this.duration.value = durationDisplay;
      }
      
      if (this.constraint.type != constraintType) {
        this.constraint.type = constraintType
      }

      if (this.constraint.date != constraintDateStr) {
        this.constraint.date = constraintDateStr;
      }
      
      this.prevStartDateStr = startDateStr;
      this.prevStartTimeStr = startTimeStr;
      this.prevCloseDateStr = closeDateStr;
      this.prevCloseTimeStr = closeTimeStr;
      this.prevConstraintType = constraintType;
      this.prevConstraintDateStr = constraintDateStr;

      if (this.task.autoScheduling != taskAutoScheduleMode && taskAutoScheduleMode != null) {
        this.task.autoScheduling = taskAutoScheduleMode;
      }

      //Use timeout to wait for 100ms before re-enabling the event to prevent the unintended event firing.
      setTimeout(() => {
        this.toggleDateTimeCalcEvent(true);
      }, 100)
            
      this.signalDurationCalculationComplete();
    },
    durationCalculationCancel({ trigger, oldDateStr, oldTimeStr, oldConstraintType, oldConstraintDateStr }) {
      //Reset to previous value
      if (trigger == TRIGGERS.START_DATE || trigger == TRIGGERS.START_TIME) {
        this.duration.startDate = oldDateStr;
        this.duration.startTime = oldTimeStr;
        this.prevStartDateStr = oldDateStr;
        this.prevStartTimeStr = oldTimeStr;
      } else if (trigger == TRIGGERS.CLOSE_DATE || trigger == TRIGGERS.CLOSE_TIME) {
        this.duration.closeDate = oldDateStr;
        this.duration.closeTime = oldTimeStr;
        this.prevCloseDateStr = oldDateStr;
        this.prevCloseTimeStr = oldTimeStr;
      } else if (trigger == TRIGGERS.CONSTRAINT_TYPE || trigger == TRIGGERS.CONSTRAINT_DATE) {
        this.constraint.type = oldConstraintType;
        this.constraint.date = oldConstraintDateStr;
      } else if (trigger == TRIGGERS.TASK_SCHEDULE_MODE) {
        //Do nothing.
      }
      //Trigger 'duration' is not possible to emit 'cancel' event.
      
      this.signalDurationCalculationComplete();
    },
    async durationCalculationCalendarChange({ toAddExceptions, toUpdateExceptions, skipOutOfProjectDateCheck }) {
      //NOTE: When isTemplate ( or defaultActionForNonWorkPrompt == 'move' ) is true, this event should not be emittted. Otherwise, there is logic flaw in TaskDateTimeDurationCalculation component.
      
      //1. Call calendar service api to add or update the exceptions
      //2. Update the calendar object
      //3. Call calcDateTimeDuration()
      //4. Reload latest calendar from backend for future usage.

      let hasError = false;
      const errorMsg = this.$t(`calendar.error.failed_to_update_calendar`);
      const calendar = this.calendar;
      const locationId = this.calendarType?.holderId ?? null;
      
      // Defensive code: Theorectically durationCalculationCalendarChange() will only be call when calendarType.type is 'staff'. 
      // When it is not staff, abort operation.
      if (this.calendarType.type != 'staff') {
        return;
      }

      if (toUpdateExceptions != null && toUpdateExceptions.length > 0) {
        const _toUpdateExceptions = cloneDeep(toUpdateExceptions);
        _toUpdateExceptions.forEach(i => {
          delete i.calendar;
        });
        await calendarService.update(_toUpdateExceptions, locationId)
        .then(response => {
          if (response == null || 207 == response.status) {
            hasError = true;
            this.alertError = true;
            this.alertMsg = errorMsg;
            return;
          }

          //Update the calendar object with the change
          if (calendar.Leave == null) {
            calendar.Leave = [];
          }
          for (let i = 0, len = _toUpdateExceptions.length; i < len; i++) {
            const curException = _toUpdateExceptions[i];
            //When exception type is Working, add it to calendar.Working
            if (curException.type == 'Working') {
              if (calendar.Working == null) {
                calendar.Working = [];
              }
              calendar.Working.push(cloneDeep(curException));
            }

            //Remove old leave exception if exception type is 'Working'. Otherwise, update the old leave exception.
            const idx = calendar.Leave.findIndex(j => j.uuId === curException.uuId);
            if (idx != -1) {
              if (curException.type == 'Working') {
                calendar.Leave.splice(idx, 1);
              } else {
                calendar.Leave[idx] = cloneDeep(curException);
              }
            }
          }
        })
        .catch(() => {
          hasError = true;
          this.alertError = true;
          this.alertMsg = errorMsg;
        });
      }

      //Stop proceed further when failed to update calendar exception.
      if (hasError) {
        return;
      }

      if (toAddExceptions != null && toAddExceptions.length > 0) {
        await calendarService.create(toAddExceptions, locationId)
        .then(response => {
          if (response == null || 207 == response.status) {
            hasError = true;
            this.alertError = true;
            this.alertMsg = errorMsg;
          } 
          else { //
            //Fill the uuId to exception and add the newly created exception to calendar object
            const list = response.data[response.data.jobCase];
            for (let i = 0, len = list.length; i < len; i++) {
              const curItem = list[i];
              if (curItem && curItem.uuId != null) {
                const curException =  toAddExceptions[i];
                curException.uuId = curItem.uuId;
                if (calendar[curException.type] == null) {
                  calendar[curException.type] = [];
                }
                calendar[curException.type].push(cloneDeep(curException));
              } else {
                hasError = true;
                this.alertError = true;
                this.alertMsg = errorMsg;
                break;
              }
            }
          }
        })
        .catch(() => {
          hasError = true;
          this.alertError = true;
          this.alertMsg = errorMsg;
        });
      }

      if (!hasError) {
        this.initDurationCalculation({ useExistingValues: true, skipOutOfProjectDateCheck })
      }
    },
    dateTimeInputHandler: debounce(function(newValue, trigger) {
      let payload = {
        trigger: trigger != null? trigger : TRIGGERS.duration,
        resizeMode: false
      }
      if (TRIGGERS.START_DATE == trigger || TRIGGERS.START_TIME == trigger) {
        payload.oldDateStr = this.prevStartDateStr;
        payload.oldTimeStr = this.prevStartTimeStr;
        if (this.project?.scheduleMode === 'ALAP') {
          payload.resizeMode = true;
        }
      } else if (TRIGGERS.CLOSE_DATE == trigger || TRIGGERS.CLOSE_TIME == trigger) {
        payload.oldDateStr = this.prevCloseDateStr;
        payload.oldTimeStr = this.prevCloseTimeStr;
        if (this.project?.scheduleMode !== 'ALAP') {
          payload.resizeMode = true;
        }
      } else if (TRIGGERS.CONSTRAINT_TYPE == trigger || TRIGGERS.CONSTRAINT_DATE == trigger) {
        payload.oldConstraintType = this.prevConstraintType;
        payload.oldConstraintDateStr = this.prevConstraintDateStr;
      } else {
        payload.oldDateStr = null;
        payload.oldTimeStr = null;
      }
      this.initDurationCalculation(payload);

      //Update oldValue;
      if (TRIGGERS.START_DATE == trigger) {
        this.prevStartDateStr = newValue;
      } else if (TRIGGERS.START_TIME == trigger) {
         this.prevStartTimeStr = newValue;
      } else if (TRIGGERS.CLOSE_DATE == trigger) {
        this.prevCloseDateStr = newValue;
      } else if (TRIGGERS.CLOSE_TIME == trigger) {
        this.prevCloseTimeStr = newValue;
      } else if (TRIGGERS.CONSTRAINT_TYPE == trigger) {
        this.prevConstraintType = newValue;
      } else if (TRIGGERS.CONSTRAINT_DATE == trigger) {
        this.prevConstraintDateStr = newValue;
      }
    }, 300),
    async initDurationCalculation({ trigger=TRIGGERS.DURATION, useExistingValues=false, oldDateStr=null, oldTimeStr=null
                                    , oldConstraintType=null, oldConstraintDateStr=null, skipOutOfProjectDateCheck=null,
                                    resizeMode=false } = {}) {
      if (useExistingValues !== true) {
        this.durationCalculation.trigger = trigger;
        this.durationCalculation.startDateStr = this.duration.startDate;
        this.durationCalculation.startTimeStr = this.duration.startTime;
        this.durationCalculation.closeDateStr = this.duration.closeDate;
        this.durationCalculation.closeTimeStr = this.duration.closeTime;
        this.durationCalculation.durationDisplay = this.duration.value;
        this.durationCalculation.resizeMode = resizeMode;
        
        if (this.staffs.length > 0) {
          this.calendarType.holderId = this.staffs[0].uuId;
          this.calendarType.type = 'staff';
          this.durationCalculation.enableManualScheduleSuggestion = false;
          this.durationCalculation.defaultActionForNonWorkPrompt = null;
          this.calendar = await staffService.calendar(this.staffs[0].uuId)
          .then((response) => {
            // combine the calendar lists into single lists for each day
            const data = response.data[response.data.jobCase];
            return transformCalendar(processCalendar(data));
          })
          .catch((e) => {
            self.httpAjaxError(e);
            return null;
          });
          
        } else if (this.projectCalendar != null) {
          this.calendarType.holderId = this.project.locationId;
          this.calendarType.type = 'project-location';
          this.calendar = this.projectCalendar;
          this.durationCalculation.enableManualScheduleSuggestion = true;
          this.durationCalculation.defaultActionForNonWorkPrompt = 'move';
        } else if (this.systemCalendar != null) {
          this.calendarType.holderId = null;
          this.calendarType.type = 'system';
          this.calendar = this.systemCalendar;
          this.durationCalculation.enableManualScheduleSuggestion = true;
          this.durationCalculation.defaultActionForNonWorkPrompt = 'move';
        }
        
        //defensive code: fallback to default calendar
        if (this.calendar == null) {
          this.calendarType.holderId == null;
          this.calendarType.type = 'system';
          this.calendar = cloneDeep(DEFAULT_CALENDAR);
          this.durationCalculation.enableManualScheduleSuggestion = true;
          this.durationCalculation.defaultActionForNonWorkPrompt = 'move';
        }
        
        this.durationCalculation.calendar = this.calendar;
        this.durationCalculation.projScheduleFromStart = this.project.scheduleMode == 'ASAP';
        this.durationCalculation.taskAutoScheduleMode = this.task.autoScheduling;
        this.durationCalculation.constraintType = this.constraint.type;
        this.durationCalculation.constraintDateStr = this.constraint.date;
        this.durationCalculation.lockDuration = this.task.lockDuration;
        this.durationCalculation.oldDateStr = oldDateStr;
        this.durationCalculation.oldTimeStr = oldTimeStr;
        this.durationCalculation.oldConstraintType = oldConstraintType;
        this.durationCalculation.oldConstraintDateStr = oldConstraintDateStr;
      }
      //Used when calendarChange event is triggered. Pass along the 'session' skipOutOfProjectDateCheck state before resuming the session.
      if (skipOutOfProjectDateCheck != null) {
        this.durationCalculation.skipOutOfProjectDateCheck = skipOutOfProjectDateCheck;
      }
      this.state.durationCalculationShow = true; //start the calculation.
    },
    constructDateTimeStr(dateStr, timeStr) {
      if (dateStr != null && dateStr.trim().length > 0) {
        let _timeStr = null;
        if (timeStr && timeStr.trim().length > 0) {
          _timeStr = timeStr.length == 5? timeStr + ':00' : timeStr;
        }
        return `${dateStr}${_timeStr != null? 'T'+_timeStr : ''}`;
      }
      return null;
    },
    cancelModal() {
      this.$validator.pause();
      this.$emit('update:show', false)
    },
    handleOkBtnClicked() {
      this.submittedBy='ok';
      //Fixed #626
      if (this.isDurationCalculationInProgress != true) {
        this.preOk();
      }
    },
    handleApplyBtnClicked(callback=null) {
      this.submittedBy='apply';
      //Fixed #626
      if (this.isDurationCalculationInProgress == true) {
        this.btnApplyCallback = callback;
      } else {
        this.preOk(true, callback);
      }
    },
    signalDurationCalculationComplete() {
      this.isDurationCalculationInProgress = false;
      this.resumeSubmitActionIfNecessary();
    },
    resumeSubmitActionIfNecessary() { //Fixed #626
      if (this.submittedBy == null) {
        return;
      }

      if (this.submittedBy == 'apply') {
        this.preOk(true, this.btnApplyCallback);
      } else {
        this.preOk();
      }
    },
    addNote() {
      this.note = {
        text: null,
        identifier: null
      }
      this.state.noteShow = true;
    },
    editNote(id) {
      const found = this.notes.find(i => i.uuId == id);
      if (found != null) {
        this.note = cloneDeep(found);
        this.state.noteShow = true;
      } else {
        this.alertMsg = this.$t('unable_to_open_detail', ['entityType.NOTE']);
      }
    },
    removeNote(id) {
      const index = this.notes.findIndex(i => i.uuId == id);
      if (index != -1) {
        this.notes.splice(index, 1);
      }
    },
    toAddNote(payload) {
      payload.uuId = `NEW_NOTE_${strRandom(5)}`;
      this.notes.unshift(payload);
    },
    toUpdateNote(payload) {
      const found = this.notes.find(i => i.uuId == payload.uuId);
      if (found != null) {
        for (const key of Object.keys(payload)) {
          found[key] = payload[key];
        }
      }
    },
    removeUnchangedTaskProperties(data) {
      //Remove those properties whose value is not changed in provided data against original task.
      //Assuming all properties are string type.
      //Property with data type other than string needs dedicated comparison logic.
      const originalTask = this.original.task;
      const keys = Object.keys(data).filter(i => i != 'uuId');
      let hasChanged = false;
      for (const key of keys) {
        if (key == 'constraintType' || key == 'constraintTime') { //Backend needs both constraint type and time even when only one of them is changed.
          if (originalTask.constraintType === data.constraintType && originalTask.constraintTime === data.constraintTime) {
            delete data[key];
            continue;
          }
        } else if (originalTask[key] === data[key]) {
          delete data[key];
          continue;
        }
        if (!hasChanged) {
          hasChanged = true;
        }
      }
      return hasChanged;
    },
    async getStaffUsageDetails() {
      const self = this;
      if (this.duration.startDate == null || this.duration.closeDate == null ||
          this.duration.closeDate === '3000-01-01') {
        return
      }
      await staffService.usage({ 
          start: 0, 
          limit: -1, 
          begin: this.duration.startDate, 
          until: this.duration.closeDate, 
          holder: this.id
      }, this.id, null)
      .then(response => {
        const redactedFields = response.data.redacted != null? response.data.redacted : [];
        if (redactedFields.length > 0) {
          return;
        }
        self.staffUsage = {};
        const data = response.data[response.data.jobCase];  
        const entityList = response.data['entityList'];
        const baseCalendar = entityList['00000000-0000-0000-0000-000000000000'] ? processSystemCalendar(entityList['00000000-0000-0000-0000-000000000000'].calendarList) : null;
        
        for (let j = 0; j < data.length; j++) {
          // prepare calendar lists
          const locationUuId = data[j].locationList.length > 0 ? data[j].locationList[0].uuId : null;
          const locationCalendar = locationUuId !== null ? entityList[locationUuId].calendarList : null;
          const calendarList = data[j].calendarList;
          const calendars = [ calendarList, locationCalendar, baseCalendar ];
          // populate the tasks from the dictionary
          for (const task of data[j].taskList) {
            for(var k in entityList[task.uuId]) task[k]=entityList[task.uuId][k];
          }
          data[j].resourceAllocationList = calculateStaffUsage(data[j], moment(this.duration.startDate), moment(this.duration.closeDate), 'month', calendars);
        }
        
        const taskStart = new Date(self.duration.startDate).getTime();
        const taskEnd = new Date(self.duration.endDate).getTime();
        for (const d of data) {
          for (const keyusage of Object.keys(d.resourceAllocationList)) {
            const usage = d.resourceAllocationList[keyusage];
            if (usage.c) {
              self.staffUsage[d.name] = self.$t('staff.error.overallocated');
            }
            else if (usage.a === -1) {
              const task = usage.t.filter(t => t.tu === this.id)[0];
              if (task) {
                self.staffUsage[d.name] = !task.w ? self.$t('staff.not_employed') : ((task.w / (task.duration / 60)) * 100) <= 90 ? self.$t('staff.partial_available') : null;
              }
            }
            else if (!usage.a) {
              self.staffUsage[d.name] = self.$t('staff.not_available');
            }
            else if (d.begin > taskEnd ||
                     d.until < taskStart) {
              self.staffUsage[d.name] = self.$t('staff.not_employed');    
            }
            else if (usage.t) {
              const task = usage.t.filter(t => t.tu === this.id)[0];
              const partial = typeof task !== 'undefined' && typeof task.w !== 'undefined' && ((task.w / (task.duration / 60)) * 100) <= 90 ? self.$t('staff.partial_available') : null;
              if (!self.staffUsage[d.name]) {
                self.staffUsage[d.name] = partial;
              }
            }
          }
        }
        // data.objectList[7].resourceAllocationList[0].c - overallocated
        // data.objectList[7].resourceAllocationList[0].a == -1 not working
      });
    },
    tagsModified({tags}) {
      this.tags = JSON.parse(JSON.stringify(tags));

    },
    durationValueChanged($event) {
      this.isDurationCalculationInProgress=true;
      this.dateTimeInputHandler($event, 'duration');
    },
    toggleDateTimeCalcEvent(enable=false) {
      if (enable) {
        if (this.dateTimeCalcChangeTrigger != 'change') {
          this.dateTimeCalcChangeTrigger = 'change';
        }
        if (this.dateTimeCalcInputTrigger != 'input') {
          this.dateTimeCalcInputTrigger = 'input';
        }
      } else {
        if (this.dateTimeCalcChangeTrigger != null) {
          this.dateTimeCalcChangeTrigger = null;
        }
        if (this.dateTimeCalcInputTrigger != null) {
          this.dateTimeCalcInputTrigger = null;
        }
      }
    },
    checkProjectRedactedProps(redactedProperties) {
      if (redactedProperties.length > 0) {
        const K_PROJECT = this.isTemplate? 'PROJECT_TEMPLATE' : 'PROJECT';
        const mandatoryProps = [
          `${K_PROJECT}.name`
          , `${K_PROJECT}.durationAUM`
          , `${K_PROJECT}.durationUnit`
          , `${K_PROJECT}.autoScheduling`
          , `${K_PROJECT}.scheduleMode`
          , `${K_PROJECT}.scheduleStartDate`
          , `${K_PROJECT}.scheduleCloseDate`
          , `${K_PROJECT}.scheduleStart`
          , `${K_PROJECT}.scheduleFinish`
          // , 'STAGE_LIST'
          , 'LOCATION'
          , 'COMPANY'
        ]
        for (const rProp of redactedProperties) {
          if (mandatoryProps.includes(rProp)) {
            this.isAccessDenied = true;
            break;
          }
        }
      }
    },
    createLayoutProfile() {
      if (this.layoutProfile != null) {
        delete this.layoutProfile.uuId;
      } else {
        this.layoutProfile = {}
      }
      return layoutProfileService.create([this.layoutProfile], this.projectId, this.$store.state.authentication.user.uuId).then((response) => {
        const data = response.data[response.data.jobCase];
        this.layoutProfile.uuId = data[0].uuId;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    updateLayoutProfile({ successCallback=null, failCallback=null } = {}) {
      if (!Object.prototype.hasOwnProperty.call(this.layoutProfile, 'uuId')) {
        // Dataviews are triggering the watchers when opening the
        // relevant tab and trying to save. Ignore those since nothing
        // has loaded yet.
        return;
      }
      layoutProfileService.update([this.layoutProfile], this.projectId, this.$store.state.authentication.user.uuId)
      .then(() => { 
        if (successCallback != null) {
          successCallback();
        }
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
        if (failCallback != null) {
          failCallback();
        }
      });
    },
    onFixedDurationChange() {
      if (this.task.fixedDuration &&
        // eslint-disable-next-line
      !/^\d+[\.,D,m,h,W,M,Y]+$/.test(this.task.fixedDuration)) {
        this.task.fixedDuration = `${this.task.fixedDuration}D`;
      }
    },
    applyRebates() {
      this.rebates.push(...this.rebatesToApply);
      this.rebatesToApply = null;
      this.promptApplyRebates = false;
    },
    splitTask() {
      this.state.splitTaskModalShow = true;
    },
    splitTaskSuccess(splits) {
      this.duration.startDate = splits[0].startDate;
      this.duration.startTime = splits[0].startTime;
      this.duration.closeDate = splits[0].closeDate;
      this.duration.closeTime = splits[0].closeTime;
      this.duration.value = splits[0].value;
      
      // clone the activity using the split dates
      for (let i = 1; i < splits.length; i++) {
        const data = {};
        let sDate = splits[i].startDate != null && splits[i].startDate != ''? splits[i].startDate : null;
        if (sDate != null) {
          const sDateTime = moment.utc(sDate, 'YYYY-MM-DD');
          let sTime = splits[i].startTime;
          if (sTime == null || sTime.trim().length == 0) {
            sTime = getEarliestOrLatestWorkHour(this.calendar, moment.utc(sDate, 'YYYY-MM-DD'), { isLatest: false, ignoreLeave: false });
          }
          if (sTime != null) {
            const token = sTime.split(':');
            sDateTime.hour(token[0]).minute(token[1]);
          }
          data.startTime = sDateTime.valueOf();
        } else {
          data.startTime = null;
        }
  
        let cDate = splits[i].closeDate != null && splits[i].closeDate != ''? splits[i].closeDate : null;
        if (cDate != null) {
           const cDateTime = moment.utc(cDate, 'YYYY-MM-DD');
          let cTime = splits[i].closeTime;
          if (cTime == null || cTime.trim().length == 0) {
            cTime = getEarliestOrLatestWorkHour(this.calendar, moment.utc(cDate, 'YYYY-MM-DD'), { isLatest: true, ignoreLeave: false });
          }
          if (cTime != null) {
            const token = cTime.split(':');
            cDateTime.hour(token[0]).minute(token[1]);
          }
          data.closeTime = cDateTime.valueOf();
        } else {
          data.closeTime = null;
        }
  
        const { value: dValue } = convertDisplayToDuration(splits[i].value, this.durationConversionOpts);
        data.duration = dValue;
        
        if (this.task.parent !== 'ROOT') {
          data.parent = this.task.parent;
        }
        
        taskService.clone(
          data, 
          this.projectId,
          this.id
        )
        .then((response) => {
          const uuId = response.data.jobClue.uuId;
          taskService.updateParentnOrder([{
            uuId: uuId, parent: data.parent
          }], this.id, true)
          .then(() => {
            return true;
          })
          .catch(e => {
            console.error(e); // eslint-disable-line no-console
            return false;
          });
        });
      }
      this.hasSplit = true;
    },
    allowViewFunc(fieldName) {
      return this.canView(this.permissionName, [fieldName]) 
              && ((!this.exists && this.canAdd(this.permissionName, [fieldName]) || this.exists));
    },
    getDurationConversionOpts() {
      return this.$store.dispatch('data/configSchedule').then(value => {
        this.durationConversionOpts = extractDurationConversionOpts(value);
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    }
    //End of methods
  }
}
</script>

<style lang="scss" scoped>

.cost-block {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

.cost-left {
  margin-right: 15px;
}

.cost-right {
  margin-left: 15px;
}

.image-preview-container {
  width: 100%;
  border: 1px var(--form-control-border) solid;
  padding: 5px;
  min-height: 36px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.image-preview {
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 300px;
}

.actual-cost-alert {
  color: var(--status-alt-red);
  position: absolute;
  right: 11px;
  top: 8px;
}
.fixed-duration-alert,
.work-effort-alert {
  color: var(--status-alt-red);
}
.colorRed {
  color: var(--status-alt-red) !important;
}

.rebate-total {
  text-align: right;
  float: right;
  display: inline-block;
}

@media (min-width: 1200px) {
  .remove-padding {
    padding-left: 0;
  }
}
</style>

<style lang="scss">
a.list-group-item {
  border: none !important;
  color: var(--form-control);
  background-color: var(--form-control-bg);
}

.actual-duration-form-control-fix {
  flex: 1 1 auto;
  width: 1%;

  &:not(:last-child) {
    input.form-control {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}

@media screen and (max-width: 991px) {
  .identifier-margin {
    margin-left: 15px;
  }
}

// @media screen and (max-width: 1200px) {
//   .color-margin {
//     margin-left: 15px;
//   }
// }
</style>
